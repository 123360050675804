import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import CustomDialog from "../../components/CustomDialog";
import {createNotaryAction, getLoadCitiesAction} from "../../api/settings";
import {AppState} from "../../store";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import {AddEditNotaryDTO} from "../../api/dtos";
import {setCreateNotaryTaskStatus} from "../../features/settings/settingsSlice";
import {TextFieldVariants} from "@mui/material/TextField/TextField";
import {Autocomplete} from "@mui/lab";

interface CreateNotaryDialogProps {
    selectedCityId?: number;
    handleClose: () => void;
    handleCreate: () => void;
}

const fieldVariant = "outlined";

export const CreateNotaryDialog: React.FC<CreateNotaryDialogProps> = ({
                                                                          handleClose,
                                                                          selectedCityId,
                                                                          handleCreate
                                                                      }) => {
    const dispatch: any = useDispatch();
    const createNotaryTaskStatus = useSelector((state: AppState) => state.settings.createNotaryTaskStatus);
    const allCitiesLookup = useSelector((state: AppState) => state.settings.allCitiesLookup);
    const [formData, setFormData] = useState<AddEditNotaryDTO>({name: "", cityId: selectedCityId || 0});
    const [fieldErrors, setFieldErrors] = useState<FieldErrors | null>(null);

    useEffect(() => {
        if (!selectedCityId) {
            dispatch(getLoadCitiesAction());
        }
        return () => {
            dispatch(setCreateNotaryTaskStatus(null));
        };
    }, [dispatch]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
        setFormData({...formData, [event.target.name as string]: event.target.value});
    };

    const handleCityChange = (event: any, value: any) => {
        setFormData({...formData, cityId: value?.id || 0});
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const result = await dispatch(createNotaryAction(formData));
            if (result.type === "settings/createNotary/fulfilled") {
                handleCreate();
            } else if (result.type === "settings/createNotary/rejected") {
                setFieldErrors(result.payload?.fieldErrors || null);
            }
        } catch (error: any) {
            setFieldErrors(error.fieldErrors || null);
        }
    };

    return (
        <CustomDialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                component: "form",
                onSubmit: handleSubmit,
            }}>
            <DialogTitle>Noter Ekle</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="name"
                        label="Noter Adı"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.name}
                        onChange={handleChange}
                        error={!!fieldErrors?.name}
                        helperText={fieldErrors?.name}/>
                </FormControl>
                {!selectedCityId && (
                    <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                        <Autocomplete
                            id="cityId"
                            options={allCitiesLookup || []}
                            getOptionLabel={(option: any) => option.value}
                            onChange={handleCityChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Şehirler"
                                    variant={fieldVariant as TextFieldVariants}
                                    required
                                    error={!!fieldErrors?.cityId}
                                    helperText={fieldErrors?.cityId}/>
                            )}/>
                    </FormControl>
                )}

                {createNotaryTaskStatus?.type === AsyncTaskStatusType.Error && createNotaryTaskStatus.errorMessage && (
                    <ErrorText type="form" message={createNotaryTaskStatus.errorMessage}/>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    İptal
                </Button>
                <Button type="submit" color="primary">
                    Ekle
                </Button>
            </DialogActions>
        </CustomDialog>
    );
};
