import axios, {AxiosResponse} from "axios";
import {EndPoints} from "./EndPoints";
import {AddTaskDTO, CaseFolderDTO, CreateFolderDTO, TaskPriority, TaskStatus} from "./dtos";
import {getAppToken} from "../shared/utils";
import {AsyncTaskStatusType, ResponseDTO} from "../shared/dtos";
import {AppState} from "../store";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    setAddFileToTaskStatus,
    setAllPaginatedTasks,
    setCreateFolderTaskStatus,
    setCreateTaskTaskStatus,
    setDeleteFolderTaskStatus,
    setDeleteTaskTaskStatus,
    setEditTaskAssigneesTaskStatus,
    setEditTaskTaskStatus,
    setFolderFilesAndFolders,
    setGetAllPaginatedTasksTaskStatus,
    setLoadFolderFilesAndFoldersTaskStatus,
    setLoadTaskStatus,
    setTask,
    setUpdateTaskStatusTaskStatus
} from "../features/task/taskSlice";
import {ErrorMessages, Headers} from "./constants";

export const createTaskAction = createAsyncThunk(
    'task/createTask',
    async (dto: AddTaskDTO, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setCreateTaskTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.post(EndPoints.task, dto, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.CONTENT_TYPE]: Headers.APPLICATION_JSON,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, errorDetails, fieldErrors, resultValue} = response.data;
            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setCreateTaskTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setCreateTaskTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                    fieldErrors
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setCreateTaskTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const getAllPaginatedTasksAction = createAsyncThunk(
    'task/getAllTasks',
    async ({
               legalCaseIdFilter,
               contractIdFilter,
               userIdFilter,
               textFilter,
               statusFilter,
               priorityFilter,
               companyIdFilter,
               page
           }: {
        legalCaseIdFilter: number | null;
        contractIdFilter: number | null;
        userIdFilter: number | null;
        textFilter: string | null;
        statusFilter: TaskStatus | null;
        priorityFilter: TaskPriority | null;
        companyIdFilter: number | null;
        page: number;
    }, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setGetAllPaginatedTasksTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const params = new URLSearchParams({page: page.toString()});
            if (legalCaseIdFilter != null) params.append("legalCaseIdFilter", legalCaseIdFilter.toString());
            if (contractIdFilter != null) params.append("contractIdFilter", contractIdFilter.toString());
            if (userIdFilter != null) params.append("userIdFilter", userIdFilter.toString());
            if (textFilter != null && textFilter !== "") params.append("textFilter", textFilter);
            if (statusFilter != null) params.append("statusFilter", statusFilter);
            if (priorityFilter != null) params.append("priorityFilter", priorityFilter);
            if (companyIdFilter != null) params.append("companyIdFilter", companyIdFilter.toString());

            const response = await axios.get(EndPoints.task + `/tasks-by-filter?${params.toString()}`, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.CONTENT_TYPE]: Headers.APPLICATION_JSON,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, errorDetails, resultValue} = response.data;
            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setGetAllPaginatedTasksTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setAllPaginatedTasks(resultValue));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setGetAllPaginatedTasksTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setGetAllPaginatedTasksTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const updateTaskStatusAction = createAsyncThunk(
    'task/updateTaskStatus',
    async ({
               taskId,
               newStatus,
               waitReason
           }: {
        taskId: number;
        newStatus: string;
        waitReason: string | null;
    }, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setUpdateTaskStatusTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.put(`${EndPoints.task}/${taskId}/update-status`, {newStatus, waitReason},
                {
                    headers: {
                        [Headers.AUTHORIZATION]: `Bearer ${token}`,
                        [Headers.CONTENT_TYPE]: Headers.APPLICATION_JSON,
                        [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                    }
                }
            );

            const {code, message, errorDetails, fieldErrors} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setUpdateTaskStatusTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setUpdateTaskStatusTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                    fieldErrors
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setUpdateTaskStatusTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const deleteTaskAction = createAsyncThunk(
    'tasks/deleteTask',
    async (id: number, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setDeleteTaskTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.delete(`${EndPoints.task}/${id}`, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                },
            });

            const {code, message, errorDetails, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setDeleteTaskTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setDeleteTaskTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setDeleteTaskTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage,
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const editTaskAction = createAsyncThunk(
    'task/editTask',
    async ({
               taskId,
               title,
               description,
               priority,
               dueDate
           }: {
        taskId: number;
        title: string;
        description: string;
        priority: TaskPriority;
        dueDate: number | null;
    }, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setEditTaskTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.put(`${EndPoints.task}/${taskId}`, {
                title,
                description,
                priority,
                dueDate
            }, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.CONTENT_TYPE]: Headers.APPLICATION_JSON,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, errorDetails, fieldErrors} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setEditTaskTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setEditTaskTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                    fieldErrors
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setEditTaskTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const editTaskAssigneesAction = createAsyncThunk(
    'task/editTaskAssignees',
    async ({
               taskId,
               assigneeIds
           }: {
        taskId: number;
        assigneeIds: number[];
    }, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setEditTaskAssigneesTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.put(`${EndPoints.task}/${taskId}/assignees`, {
                assigneeIds: assigneeIds
            }, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.CONTENT_TYPE]: Headers.APPLICATION_JSON,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, errorDetails, fieldErrors} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setEditTaskAssigneesTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setEditTaskAssigneesTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                    fieldErrors
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setEditTaskAssigneesTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const addFileToTaskAction = createAsyncThunk(
    'task/addFileToTask',
    async ({taskId, dto}: { taskId: number, dto: FormData }, {
        dispatch,
        getState,
        rejectWithValue,
        fulfillWithValue
    }) => {
        dispatch(setAddFileToTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.post(`${EndPoints.task}/${taskId}/upload-file`, dto, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setAddFileToTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(resultValue);
            } else if (code === ResponseDTO.CodeEnum.DuplicateObject) {
                dispatch(setAddFileToTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: 'Aynı İsimde Dosya Var'
                }));
                return rejectWithValue('Aynı İsimde Dosya Var');
            } else {
                dispatch(setAddFileToTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setAddFileToTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const createFolderForTaskAction = createAsyncThunk(
    'task/createFolderForTask',
    async ({taskId, dto}: { taskId: number; dto: CreateFolderDTO }, {
        dispatch,
        getState,
        rejectWithValue,
        fulfillWithValue
    }) => {
        dispatch(setCreateFolderTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.put(`${EndPoints.task}/${taskId}/create-folder`, dto, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                },
            });

            const {code, message, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setCreateFolderTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setCreateFolderTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setCreateFolderTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage,
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const deleteFolderFromTaskAction = createAsyncThunk(
    'task/deleteFolderFromTask',
    async ({taskId, folderId}: { taskId: number; folderId: number }, {
        dispatch,
        getState,
        rejectWithValue,
        fulfillWithValue
    }) => {
        dispatch(setDeleteFolderTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.put(`${EndPoints.task}/${taskId}/delete-folder/${folderId}`, null, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                },
            });

            const {code, message, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setDeleteFolderTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(resultValue);
            } else if (code === ResponseDTO.CodeEnum.InvalidObjectState) {
                dispatch(setDeleteFolderTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: 'Klasörün içinde dosya var!'
                }));
                return rejectWithValue("Klasörün içinde dosya var!");
            } else {
                dispatch(setDeleteFolderTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setDeleteFolderTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage,
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const getTaskFolderFilesAndFoldersAction = createAsyncThunk(
    'task/getTaskFolderFilesAndFolders',
    async ({taskId, folderId}: { taskId: number; folderId?: number }, {
        dispatch,
        getState,
        rejectWithValue,
        fulfillWithValue
    }) => {
        dispatch(setLoadFolderFilesAndFoldersTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.get(`${EndPoints.task}/${taskId}/files-and-folders`, {
                params: {parentFolderId: folderId},
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                },
            });
            const {code, message, resultValue, errorDetails} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setFolderFilesAndFolders(resultValue));
                dispatch(setLoadFolderFilesAndFoldersTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setLoadFolderFilesAndFoldersTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                }));
                return rejectWithValue(message);
            }
            return fulfillWithValue(response.data);
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setLoadFolderFilesAndFoldersTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage,
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const moveTaskFileToFolderAction = createAsyncThunk(
    'task/moveTaskFileToFolder',
    async ({taskId, folderId, fileId}: { taskId: number; folderId: number | null; fileId: number }, {
        dispatch,
        getState,
        rejectWithValue,
        fulfillWithValue
    }) => {
        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response: AxiosResponse<any, any> = await axios.put(
                `${EndPoints.task}/${taskId}/move-to-folder`,
                {fileId, folderId},
                {
                    headers: {
                        [Headers.AUTHORIZATION]: `Bearer ${token}`,
                        [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                    },
                }
            );

            const {code, message, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                return fulfillWithValue(resultValue);
            } else {
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            return rejectWithValue(errorMessage);
        }
    }
);

export const downloadTaskFile = createAsyncThunk(
    'task/downloadTaskFile',
    async ({taskId, file}: { taskId: number, file: CaseFolderDTO }, {dispatch, getState, rejectWithValue}) => {
        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const downloadUrl = `${EndPoints.task}/${taskId}/download-file/${file.id}?access_token=${token}`;

            const response = await axios.get(downloadUrl, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                },
                responseType: 'blob'
            });

            const contentType = response.headers['content-type'] || 'application/octet-stream';
            const blob = new Blob([response.data], {type: contentType});
            const url = URL.createObjectURL(blob);

            const supportedTypes = [
                'application/pdf',
                'image/jpeg',
                'image/png'
            ];

            if (supportedTypes.includes(contentType)) {
                window.open(url, '_blank');
            } else {
                const a = document.createElement('a');
                a.href = url;
                a.download = file.name;
                a.click();
                a.remove();
            }

            URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Dosya indirirken hata oluştu:', error);
        }
    }
);

export const getTaskAction = createAsyncThunk(
    'task/getTask',
    async (id: number, {dispatch, getState, rejectWithValue, fulfillWithValue}: any) => {
        dispatch(setLoadTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.get(`${EndPoints.task}/${id}`, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, resultValue, errorDetails} = response.data as ResponseDTO;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setLoadTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setTask(resultValue));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setLoadTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setLoadTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);
