import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, Link, Pagination, Table, TableBody, TableHead, TableRow, Typography} from "@mui/material";
import {useNavigate, useSearchParams} from "react-router-dom";
import {AppState} from "../store";
import UserLayout from "../components/UserLayout";
import {searchTaskAction} from "../api/search";
import {formatDate, NO_DATA} from "../shared/utils";
import {setSearchResults} from "../features/search/searchSlice";
import TableCell from "@mui/material/TableCell";
import {getPresentableCaseType, getPresentableLegalCaseStatus, SearchResultDTO} from "../api/dtos";

export default function SearchResult() {
    const dispatch: any = useDispatch();
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get("page") || "1");
    const searchText = searchParams.get("searchText") || "";
    const searchResults = useSelector((state: AppState) => state.search.searchResults);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setSearchResults(null));

        if (searchText.length > 2) {
            dispatch(searchTaskAction({searchText, pageSize: 20, pageNumber: page}));
        } else {
            dispatch(setSearchResults(null));
        }
    }, [searchText, page, dispatch]);

    const handleChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
        const params = new URLSearchParams(searchParams);
        params.set("page", value.toString());
        navigate(`?${params.toString()}`);
    };

    return (
        <UserLayout>
            <div id="sidebar">
                <Typography variant="h4" component="h1" gutterBottom>
                    ARAMA SONUÇLARI
                </Typography>
            </div>
            <Box>
                <Box sx={{overflowX: "auto"}}>
                    <Table sx={{minWidth: 800}} aria-label="search-results-table">
                        <TableHead>
                            <TableRow>
                                <TableCell>DAVA ADI</TableCell>
                                <TableCell>DAVACI</TableCell>
                                <TableCell>DAVALI</TableCell>
                                <TableCell>DAVA DOSYA NUMARASI</TableCell>
                                <TableCell>DOSYA ÖZEL NUMARASI</TableCell>
                                <TableCell>DAVA TİPİ</TableCell>
                                <TableCell>DAVA TARİHİ</TableCell>
                                <TableCell>DAVA DURUMU</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {searchResults?.entity.map((result: SearchResultDTO) => (
                                <TableRow key={result.id}>
                                    <TableCell>
                                        <Link
                                            component="button"
                                            onClick={() => navigate(`/hukuk-davalari/${result.id}/ayrintilar`)}
                                            underline="hover">
                                            {result.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{result.prosecutors.map(p => p.company?.value).join(', ')}</TableCell>
                                    <TableCell>{result.defendants.map(d => d.company?.value).join(', ')}</TableCell>
                                    <TableCell>{result.fileNumber}</TableCell>
                                    <TableCell>{result.corporateFileNumber}</TableCell>
                                    <TableCell>{getPresentableCaseType(result.type)}</TableCell>
                                    <TableCell>{result.caseDate ? formatDate(Number(result.caseDate)) : NO_DATA}</TableCell>
                                    <TableCell>{getPresentableLegalCaseStatus(result.status)}</TableCell>
                                </TableRow>
                            ))}
                            {searchResults?.entity.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={8} style={{textAlign: "center"}}>
                                        Sonuç Bulunamadı
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Box>
                {searchResults?.totalNumberPages != null && searchResults.totalNumberPages > 1 && (
                    <Box sx={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                        <Pagination
                            page={page}
                            count={searchResults.totalNumberPages}
                            onChange={handleChangePage}/>
                    </Box>
                )}
            </Box>
        </UserLayout>
    );
}
