import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AsyncTaskStatus} from "../../shared/dtos";
import {ContractDTO, FolderFilesAndFoldersDTO, PaginatedEntityList} from "../../api/dtos";

export interface ContractState {
    allPaginatedContracts: PaginatedEntityList<ContractDTO> | null;
    getAllPaginatedContractsStatus: AsyncTaskStatus | null;
    createContractTaskStatus: AsyncTaskStatus | null;
    updateContractStatusTaskStatus: AsyncTaskStatus | null;
    deleteContractTaskStatus: AsyncTaskStatus | null;
    editContractTaskStatus: AsyncTaskStatus | null;
    addFileToContractTaskStatus: AsyncTaskStatus | null;
    loadFolderFilesAndFoldersTaskStatus: AsyncTaskStatus | null;
    folderFilesAndFolders: FolderFilesAndFoldersDTO | null;
    moveFileToFolderTaskStatus: AsyncTaskStatus | null;
    loadContractTaskStatus: AsyncTaskStatus | null;
    contract: ContractDTO | null;
    createFolderContractStatus: AsyncTaskStatus | null;
    deleteFolderContractStatus: AsyncTaskStatus | null;
}

const initialState: ContractState = {
    allPaginatedContracts: null,
    getAllPaginatedContractsStatus: null,
    createContractTaskStatus: null,
    updateContractStatusTaskStatus: null,
    deleteContractTaskStatus: null,
    editContractTaskStatus: null,
    addFileToContractTaskStatus: null,
    loadFolderFilesAndFoldersTaskStatus: null,
    folderFilesAndFolders: null,
    moveFileToFolderTaskStatus: null,
    loadContractTaskStatus: null,
    contract: null,
    createFolderContractStatus: null,
    deleteFolderContractStatus: null
};

const contractSlice = createSlice({
    name: 'contract',
    initialState,
    reducers: {
        setUpdateContractStatusTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.updateContractStatusTaskStatus = action.payload;
        },
        setCreateContractTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.createContractTaskStatus = action.payload;
        },
        setGetAllPaginatedContractsTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.getAllPaginatedContractsStatus = action.payload;
        },
        setAllPaginatedContracts: (state, action: PayloadAction<PaginatedEntityList<ContractDTO> | null>) => {
            state.allPaginatedContracts = action.payload;
        },
        setDeleteContractTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.deleteContractTaskStatus = action.payload;
        },
        setEditContractTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.editContractTaskStatus = action.payload;
        },
        setAddFileToContractTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.addFileToContractTaskStatus = action.payload;
        },
        setLoadFolderFilesAndFoldersTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadFolderFilesAndFoldersTaskStatus = action.payload;
        },
        setFolderFilesAndFolders: (state, action: PayloadAction<FolderFilesAndFoldersDTO | null>) => {
            state.folderFilesAndFolders = action.payload;
        },
        setMoveFileToFolderTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.moveFileToFolderTaskStatus = action.payload;
        },
        setLoadContractTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadContractTaskStatus = action.payload;
        },
        setContract: (state, action: PayloadAction<ContractDTO | null>) => {
            state.contract = action.payload;
        },
        setCreateFolderContractStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.createFolderContractStatus = action.payload;
        },
        setDeleteFolderContractStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.deleteFolderContractStatus = action.payload;
        },
        setLoadContractStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadContractTaskStatus = action.payload;
        }
    }
});

export const {
    setCreateContractTaskStatus,
    setUpdateContractStatusTaskStatus,
    setGetAllPaginatedContractsTaskStatus,
    setAllPaginatedContracts,
    setDeleteContractTaskStatus,
    setEditContractTaskStatus,
    setAddFileToContractTaskStatus,
    setLoadFolderFilesAndFoldersTaskStatus,
    setFolderFilesAndFolders,
    setMoveFileToFolderTaskStatus,
    setLoadContractTaskStatus,
    setContract,
    setCreateFolderContractStatus,
    setDeleteFolderContractStatus,
    setLoadContractStatus
} = contractSlice.actions;

export default contractSlice.reducer;
