import React, {useEffect, useState} from "react";
import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {createFolderForContractAction} from "../../api/contract";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import CustomDialog from "../../components/CustomDialog";
import {ContractDTO} from "../../api/dtos";
import {setCreateFolderContractStatus} from "../../features/contracts/contractSlice";

interface AddFolderToContractDialogProps {
    onClose: () => void;
    contract: ContractDTO;
    onFolderAdded: () => void;
    folderId?: number;
}

const fieldVariant = "outlined";

interface AddCreateFolderFormData {
    name: string;
}

export const AddFolderToContractDialog: React.FC<AddFolderToContractDialogProps> = ({
                                                                                        onClose,
                                                                                        contract,
                                                                                        onFolderAdded,
                                                                                        folderId
                                                                                    }) => {
    const dispatch: any = useDispatch();

    const defaultFormData: AddCreateFolderFormData = {name: ""};

    const [formData, setFormData] = useState<AddCreateFolderFormData>(defaultFormData);

    const handleChangeFormData = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const dto = {
            name: formData.name,
            parentFolderId: folderId || null,
        };

        try {
            const result = await dispatch(
                createFolderForContractAction({
                    contractId: contract.id,
                    dto,
                })
            );

            if (result.type === "contract/createFolderForContract/fulfilled") {
                onFolderAdded();
                onClose();
            }
        } catch (error) {
            console.error("Klasör eklerken bir hata oluştu:", error);
        }
    };

    const createFolderContractStatus = useSelector(
        (state: AppState) => state.contract.createFolderContractStatus
    );

    const [errors, setErrors] = useState<FieldErrors>({});

    useEffect(() => {
        if (createFolderContractStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            createFolderContractStatus?.fieldErrors.forEach((it) => (updatedErrors[it.field] = it.message));
            setErrors(updatedErrors);
        }
    }, [createFolderContractStatus]);

    useEffect(() => {
        return () => {
            dispatch(setCreateFolderContractStatus(null));
        }
    }, []);

    return (
        <CustomDialog
            open={true}
            onClose={onClose}
            PaperProps={{
                component: "form",
                onSubmit: handleSubmit,
            }}>
            <DialogTitle>Sözleşmeye Klasör Ekle</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        name="name"
                        label="Klasör Adı"
                        type="text"
                        fullWidth
                        variant={fieldVariant}
                        onChange={handleChangeFormData}
                        required/>
                    {errors.name && <ErrorText message={errors.name}/>}
                </FormControl>
                {createFolderContractStatus?.type === AsyncTaskStatusType.Error &&
                    createFolderContractStatus.errorMessage && (
                        <ErrorText type="form" message={createFolderContractStatus.errorMessage}/>
                    )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>İptal</Button>
                <Button type="submit" disabled={!formData.name}>
                    Ekle
                </Button>
            </DialogActions>
        </CustomDialog>
    );
};
