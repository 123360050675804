import React, {useEffect} from 'react';
import {Button, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../../store';
import {AsyncTaskStatusType} from '../../shared/dtos';
import ErrorText from '../../components/ErrorText';
import CustomDialog from '../../components/CustomDialog';
import {deleteFolderFromContractAction} from '../../api/contract';
import {setDeleteFolderContractStatus} from '../../features/contracts/contractSlice';

interface DeleteContractFolderDialogProps {
    handleClose: () => void;
    handleDelete: () => void;
    contractId: number;
    folderId: number;
    folderName: string;
}

export const DeleteContractFolderDialog: React.FC<DeleteContractFolderDialogProps> = ({
                                                                                          handleClose,
                                                                                          handleDelete,
                                                                                          contractId,
                                                                                          folderId,
                                                                                          folderName,
                                                                                      }) => {
    const deleteFolderContractStatus = useSelector((state: AppState) => state.contract.deleteFolderContractStatus);
    const dispatch: any = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(setDeleteFolderContractStatus(null));
        };
    }, [dispatch]);

    const handleDeleteClick = async () => {
        try {
            const result = await dispatch(deleteFolderFromContractAction({contractId, folderId}));
            if (result.type === 'contract/deleteFolderFromContract/fulfilled') {
                handleDelete();
                handleClose();
            }
        } catch (error) {
            console.error('Klasör silme işleminde bir hata oluştu:', error);
        }
    };

    return (
        <CustomDialog open={true} onClose={handleClose}>
            <DialogTitle>Klasörü Sil ({folderName})</DialogTitle>
            <DialogContent>
                Bu klasörü silmek istediğinizden emin misiniz?
                {deleteFolderContractStatus?.type === AsyncTaskStatusType.Error &&
                    deleteFolderContractStatus.errorMessage && (
                        <ErrorText type="form" message={deleteFolderContractStatus.errorMessage}/>
                    )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button onClick={handleDeleteClick} color="error">
                    Sil
                </Button>
            </DialogActions>
        </CustomDialog>
    );
};
