import {Grid, Paper, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {getLegalCaseAction} from "../../api/legalCase";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {CaseType, getPresentableLegalCaseStatus, UserInLegalCaseDTO} from "../../api/dtos";
import {LitigantType} from "../../api/litigantType";
import {setLegalCase, setLoadLegalCaseTaskStatus} from "../../features/legal-case/legalCaseSlice";
import {formatDate, NO_DATA} from "../../shared/utils";

export interface LegalCaseDetailsProps {
    id: number;
}

const LitigantList: React.FC<{ label: string; litigants: any[] }> = ({label, litigants}) => (
    <Grid item xs={6} container spacing={1} alignItems="center">
        <Grid item>
            <Typography variant="subtitle1" fontWeight="bold" display="inline">
                {label}:
            </Typography>
        </Grid>
        <Grid item>
            <Typography variant="body1" display="inline">
                {litigants?.map((litigant) => {
                    switch (litigant.type as LitigantType) {
                        case LitigantType.Company:
                            return litigant.company?.value;
                        case LitigantType.Client:
                            return litigant.client?.value;
                        default:
                            return "Bilinmeyen";
                    }
                }).join(', ') || NO_DATA}
            </Typography>
        </Grid>
    </Grid>
);

const CaseDetailsRow: React.FC<{ label: string; value: string | number }> = ({label, value}) => (
    <Grid item xs={6} container spacing={1} alignItems="center">
        <Grid item>
            <Typography variant="subtitle1" fontWeight="bold" display="inline">
                {label}:
            </Typography>
        </Grid>
        <Grid item>
            <Typography variant="body1" display="inline">
                {value}
            </Typography>
        </Grid>
    </Grid>
);

const ResponsibleList: React.FC<{ responsibles: UserInLegalCaseDTO[] }> = ({responsibles}) => (
    <Grid item xs={6} container spacing={1} alignItems="center">
        <Grid item>
            <Typography variant="subtitle1" fontWeight="bold">
                SORUMLULAR:
            </Typography>
        </Grid>
        <Grid item>
            <Typography variant="body1" display="inline">
                {responsibles.length > 0
                    ? responsibles.map((responsible) => `${responsible.firstName} ${responsible.lastName}`).join(', ') || NO_DATA
                    : NO_DATA}
            </Typography>
        </Grid>
    </Grid>
);

const NotesSection: React.FC<{ notes: string | null }> = ({notes}) => (
    <Grid item xs={6} container spacing={1} alignItems="center">
        <Grid item>
            <Typography variant="subtitle1" fontWeight="bold">
                NOTLAR:
            </Typography>
        </Grid>
        <Grid item>
            <Typography variant="body1" display="inline">
                {notes}
            </Typography>
        </Grid>
    </Grid>
);

export const LegalCaseDetails: React.FC<LegalCaseDetailsProps> = ({id}) => {
    const dispatch: any = useDispatch();
    const legalCase = useSelector((state: AppState) => state.legalCase.legalCase);

    useEffect(() => {
        dispatch(getLegalCaseAction(id));
        return () => {
            dispatch(setLegalCase(null));
            dispatch(setLoadLegalCaseTaskStatus(null));
        };
    }, [id, dispatch]);

    if (!legalCase) {
        return null;
    }

    const {
        name,
        fileNumber,
        corporateFileNumber,
        court,
        caseDate,
        status,
        prosecutors,
        defendants,
        responsibles,
        notes,
        type: caseType,
        committee,
        notary
    } = legalCase;

    let title = "DAVALAR";
    let prosecutorsLabel = "DAVACI";
    let defendantsLabel = "DAVALI";
    let courtLabel = "MAHKEME";
    let fileNumberLabel = "DOSYA NUMARASI";
    let caseDateLabel = "DAVA TARİHİ";
    let courtHouseLabel = "ADLİYE";
    let courtHouseVisible = true;
    let courtVisible = true;

    switch (caseType) {
        case CaseType.Criminal:
            title = "CEZA DAVALARI";
            prosecutorsLabel = "MÜŞTEKİ/KATILAN";
            defendantsLabel = "SANIK";
            courtHouseVisible = true;
            courtLabel = "MAHKEME";
            fileNumberLabel = "DOSYA NUMARASI";
            caseDateLabel = "ŞİKAYET TARİHİ";
            break;
        case CaseType.Executive:
            title = "İCRA DAVALARI";
            prosecutorsLabel = "ALACAKLI";
            defendantsLabel = "BORÇLU";
            courtHouseVisible = true;
            courtLabel = "İCRA DAİRESİ";
            fileNumberLabel = "DOSYA NUMARASI";
            caseDateLabel = "TAKİP TARİHİ";
            break;
        case CaseType.Investigation:
            title = "SORUŞTURMALAR";
            prosecutorsLabel = "MÜŞTEKİ";
            defendantsLabel = "ŞÜPHELİ";
            courtHouseVisible = true;
            courtLabel = "SAVCILIK";
            fileNumberLabel = "SORUŞTURMA NUMARASI";
            caseDateLabel = "ŞİKAYET TARİHİ";
            break;
        case CaseType.ConsumerArbitrationCommittee:
            title = "TÜKETİCİ HAKEM HEYETİ DAVALARI";
            prosecutorsLabel = "DAVACI";
            defendantsLabel = "DAVALI";
            courtHouseVisible = false;
            courtVisible = false;
            fileNumberLabel = "TÜKETİCİ HAKEM HEYETİ NUMARASI";
            caseDateLabel = "ŞİKAYET TARİHİ";
            break;
        case CaseType.PinkSlip:
            title = "İHTARNAMELER";
            prosecutorsLabel = "DAVACI";
            defendantsLabel = "DAVALI";
            courtHouseVisible = false;
            courtVisible = false;
            fileNumberLabel = "İHTARNAME NUMARASI";
            caseDateLabel = "ŞİKAYET TARİHİ";
            break;
        case CaseType.ExecuteFollowUp:
            title = "İCRA TAKİPLERİ";
            prosecutorsLabel = "DAVACILAR";
            defendantsLabel = "DAVALI";
            courtHouseVisible = false;
            courtVisible = false;
            fileNumberLabel = "İCRA TAKİP NUMARASI";
            caseDateLabel = "ŞİKAYET TARİHİ";
            break;
        default:
            title = "DAVALAR";
            prosecutorsLabel = "DAVACI";
            defendantsLabel = "DAVALI";
            courtHouseVisible = true;
            courtVisible = true;
            fileNumberLabel = "DOSYA NUMARASI";
            caseDateLabel = "DAVA TARİHİ";
            break;
    }

    return (
        <Paper elevation={3} sx={{p: 3}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" fontWeight="bold">
                        {title}
                    </Typography>
                </Grid>
                <CaseDetailsRow label="KONU" value={name || NO_DATA}/>
                <LitigantList label={prosecutorsLabel} litigants={prosecutors}/>
                <LitigantList label={defendantsLabel} litigants={defendants}/>
                <CaseDetailsRow label={fileNumberLabel} value={fileNumber || NO_DATA}/>
                <CaseDetailsRow label="DOSYA ÖZEL NUMARASI" value={corporateFileNumber || NO_DATA}/>
                {caseType === CaseType.ConsumerArbitrationCommittee && (
                    <CaseDetailsRow label="HEYET" value={committee?.name || NO_DATA}/>
                )}
                {caseType === CaseType.PinkSlip && (
                    <CaseDetailsRow label="NOTER" value={notary?.name || NO_DATA}/>
                )}
                {caseType === CaseType.ExecuteFollowUp && (
                    <CaseDetailsRow label="İCRA DAİRESİ" value={court?.name || NO_DATA}/>
                )}
                {courtHouseVisible && (
                    <CaseDetailsRow label={courtHouseLabel} value={court?.courtHouse?.name || NO_DATA}/>
                )}
                {courtVisible && (
                    <CaseDetailsRow label={courtLabel} value={court?.name || NO_DATA}/>
                )}
                <CaseDetailsRow
                    label={caseDateLabel}
                    value={caseDate ? formatDate(caseDate) : NO_DATA}/>
                <ResponsibleList responsibles={responsibles}/>
                <NotesSection notes={notes}/>
                <CaseDetailsRow
                    label="Durumu"
                    value={getPresentableLegalCaseStatus(status)}/>
            </Grid>
        </Paper>
    );
};