import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Box, Tab, Tabs} from '@mui/material';
import UserLayout from '../../components/UserLayout';
import {TaskDetails} from './TaskDetails';
import {TaskFiles} from './TaskFiles';

enum TabRoutes {
    Details = 'ayrintilar',
    Files = 'dosyalar',
}

const tabIndexMap: Map<TabRoutes, number> = new Map([
    [TabRoutes.Details, 0],
    [TabRoutes.Files, 1],
]);

const tabLabelMap: Map<number, TabRoutes> = new Map([
    [0, TabRoutes.Details],
    [1, TabRoutes.Files],
]);

const TaskDetailsTabs = () => {
    const {id, tab} = useParams<{ id: string, tab: string }>();
    const [tabIndex, setTabIndex] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    const pathSegments = location.pathname.split('/').filter(Boolean);

    useEffect(() => {
        const initialIndex = tabIndexMap.get(tab as TabRoutes) ?? 0;
        setTabIndex(initialIndex);
    }, [tab]);

    useEffect(() => {
        if (!tab) {
            navigate(`/${pathSegments[0]}/${id}/${TabRoutes.Details}`, {replace: true});
        }
    }, [id, tab, navigate, pathSegments]);

    const handleTabChange = (_: any, newIndex: number) => {
        setTabIndex(newIndex);
        const route = tabLabelMap.get(newIndex);
        if (route) {
            navigate(`/${pathSegments[0]}/${id}/${route}`);
        }
    };

    const taskId = Number(id!);

    return (
        <UserLayout>
            <h1>GÖREV DETAYLARI</h1>
            <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Görev Detay Sekmeleri">
                <Tab label="Ayrıntılar"/>
                <Tab label="Dosyalar"/>
            </Tabs>
            <Box sx={{pt: 2}}>
                {tabIndex === 0 && <TaskDetails id={taskId}/>}
                {tabIndex === 1 && <TaskFiles id={taskId}/>}
            </Box>
        </UserLayout>
    );
};

export default TaskDetailsTabs;
