import React, {useEffect, useState} from "react";
import {Box, Button, Pagination, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {getAllPaginatedTasksAction} from "../api/task";
import {AppState} from "../store";
import {getPresentableTaskPriority, getPresentableTaskStatus, TaskDTO, TaskPriority, TaskStatus} from "../api/dtos";
import {setLoadUsersTaskStatus} from "../features/settings/settingsSlice";
import UserLayout from "../components/UserLayout";
import TaskAction from "../actions/TaskAction";
import * as XLSX from "xlsx-js-style";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {robotoRegularBase64} from '../shared/pdf-fonts';
import {ChangeStatusDialog} from "./create-status/changeStatusDialog";
import {DeleteTaskDialog} from "./delete-task/deleteTaskDialog";
import {EditTaskDialog} from "./edit-task/editTaskDialog";
import {AsyncTaskStatusType} from "../shared/dtos";
import {AddFileToTaskDialog} from "./add-file-to-task/add-file-to-task";
import {EditTaskAssigneesDialog} from "./edit-task/editTaskAssigneesDialog";
import {formatDate, NO_DATA} from "../shared/utils";

export const MyTasks = () => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const allPaginatedTasks = useSelector((state: AppState) => state.task.allPaginatedTasks);
    const currentUser = useSelector((state: AppState) => state.auth.userWithToken?.user);
    const page = Number(searchParams.get('page') || "1");
    const [priorityFilter, setPriorityFilter] = useState<TaskPriority | null>(null);
    const [statusFilter, setStatusFilter] = useState<TaskStatus | null>(null);
    const [changeStatusDialogOpen, setChangeStatusDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteTaskDialogOpen, setDeleteTaskDialogOpen] = useState(false);
    const [activeTask, setActiveTask] = useState<TaskDTO | null>(null);
    const getAllPaginatedTasksStatus = useSelector((state: AppState) => state.task.getAllPaginatedTasksStatus)
    const [editAssigneesDialogOpen, setEditAssigneesDialogOpen] = useState(false);

    const handleDeleteTaskDialogOpen = () => setDeleteTaskDialogOpen(true);
    const handleDeleteTaskDialogClose = () => setDeleteTaskDialogOpen(false);

    const handleChangeStatusDialogOpen = () => setChangeStatusDialogOpen(true);
    const handleChangeStatusDialogClose = () => setChangeStatusDialogOpen(false);

    const handleEditDialogOpen = (task: TaskDTO) => {
        setActiveTask(task);
        setEditDialogOpen(true);
    };

    const handleEditDialogClose = () => {
        setActiveTask(null);
        setEditDialogOpen(false);
    };

    const handleEditAssigneesDialogOpen = (task: TaskDTO) => {
        setActiveTask(task);
        setEditAssigneesDialogOpen(true);
    };

    const handleEditAssigneesDialogClose = () => {
        setActiveTask(null);
        setEditAssigneesDialogOpen(false);
    };

    useEffect(() => {
        if (currentUser?.id) {
            dispatch(getAllPaginatedTasksAction({
                legalCaseIdFilter: null,
                contractIdFilter: null,
                userIdFilter: currentUser.id,
                companyIdFilter: currentUser?.associatedCompany?.id || null,
                textFilter: "",
                priorityFilter,
                statusFilter,
                page
            }));

            return () => {
                dispatch(setLoadUsersTaskStatus(null));
            };
        }
    }, [dispatch, page, priorityFilter, statusFilter, currentUser?.id]);

    const handleChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', value.toString());
        navigate(`?${searchParams.toString()}`);
    };

    const reloadFirstPage = () => {
        if (page === 1) {
            dispatch(getAllPaginatedTasksAction({
                legalCaseIdFilter: null,
                contractIdFilter: null,
                userIdFilter: currentUser?.id || null,
                companyIdFilter: currentUser?.associatedCompany?.id || null,
                textFilter: "",
                priorityFilter,
                statusFilter,
                page
            }));
        } else {
            searchParams.set("page", "1");
            navigate(`?${searchParams.toString()}`);
        }
    }

    const handleEditTask = () => {
        handleEditDialogClose();
        reloadFirstPage();
    };

    const handleChangeStatus = () => {
        setActiveTask(null);
        handleChangeStatusDialogClose();
        reloadFirstPage();
    };

    const handleDelete = () => {
        setActiveTask(null);
        handleDeleteTaskDialogClose();
        reloadFirstPage();
    }

    const openDelete = (task: TaskDTO) => {
        setActiveTask(task);
        handleDeleteTaskDialogOpen();
    };

    const openChangeStatus = (task: TaskDTO) => {
        setActiveTask(task);
        handleChangeStatusDialogOpen();
    };

    const [addFileDialogOpen, setAddFileDialogOpen] = useState(false);

    const handleAddFileDialogOpen = () => setAddFileDialogOpen(true);
    const handleAddFileDialogClose = () => setAddFileDialogOpen(false);

    const openAddFile = (task: TaskDTO) => {
        setActiveTask(task);
        handleAddFileDialogOpen();
    };

    const handleFileAdded = () => {
        setActiveTask(null);
        handleAddFileDialogClose();
    };

    const exportToExcel = () => {
        const headers = [['GÖREV ADI', 'AÇIKLAMA', 'DURUMU', 'ATAYAN KİŞİ', "ATANAN KULLANICILAR", "ATANMA TARİHİ", "HEDEFLENEN TARİH", 'ÖNCELİK']];
        const worksheet = XLSX.utils.json_to_sheet(
            (allPaginatedTasks?.entity || []).map((task) => ({
                "GÖREV ADI": task.title,
                "AÇIKLAMA": task.description,
                "DURUMU": getPresentableTaskStatus(task.status),
                "ATAYAN KİŞİ": task.createdBy ? `${task.createdBy.firstName} ${task.createdBy.lastName}` : '-',
                "ATANAN KULLANICILAR": task.assignees.length > 0
                    ? task.assignees.map((user) => user.firstName + " " + user.lastName).join(', ')
                    : 'Atama Yok',
                "ATANMA TARİHİ": formatDate(task.createdAt),
                "HEDEFLENEN TARİH": task.dueDate ? formatDate(task.dueDate) : NO_DATA,
                "ÖNCELİK": getPresentableTaskPriority(task.priority),
            }))
        );

        const workbook = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(worksheet, headers);

        const headerRow = headers[0];
        const columnWidths = headerRow.map(header => ({wch: header.length + 5}));
        worksheet['!cols'] = columnWidths;
        headerRow.forEach((header, index) => {
            const cellRef = XLSX.utils.encode_cell({r: 0, c: index});
            if (worksheet[cellRef]) {
                worksheet[cellRef].s = {
                    font: {bold: true,},
                    fill: {
                        patternType: "solid",
                        fgColor: {rgb: "D3D3D3"}
                    }
                };
            }
        });

        XLSX.utils.book_append_sheet(workbook, worksheet, "Görevlerim");
        XLSX.writeFile(workbook, "görevlerim.xlsx");
    };

    const exportToPDF = () => {
        const doc = new jsPDF();
        doc.addFileToVFS('Roboto-Regular.ttf', robotoRegularBase64);
        doc.addFont('Roboto-Regular.ttf', 'Roboto', 'normal');
        doc.setFont('Roboto');

        doc.setFontSize(18);
        doc.text('GÖREVLERİM', 14, 20);

        const tableColumns = ['GÖREV ADI', 'AÇIKLAMA', 'DURUMU', 'ATAYAN KİŞİ', "ATANAN KULLANICILAR", "ATANMA TARİHİ", "HEDEFLENEN TARİH", 'ÖNCELİK'];

        const tableData = (allPaginatedTasks?.entity || []).map((task) => [
            task.title,
            task.description,
            getPresentableTaskStatus(task.status),
            task.createdBy ? `${task.createdBy.firstName} ${task.createdBy.lastName}` : '-',
            task.assignees.length > 0
                ? task.assignees.map((user) => user.firstName + " " + user.lastName).join(', ')
                : 'Atama Yok',
            formatDate(task.createdAt),
            task.dueDate ? formatDate(task.dueDate) : NO_DATA,
            getPresentableTaskPriority(task.priority),
        ]);

        autoTable(doc, {
            head: [tableColumns],
            body: tableData,
            startY: 30,
            margin: {horizontal: 10},
            styles: {
                font: 'Roboto',
                cellPadding: 3,
                fontSize: 8,
                valign: 'middle',
                halign: 'left'
            }
        });

        doc.save('gorevlerim.pdf');
    };

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>GÖREVLERİM</h1>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                    }}>
                    <Box sx={{display: 'flex', gap: 2}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={exportToExcel}
                            sx={{fontSize: '0.875rem', padding: '8px 16px'}}>
                            Excel'e Dışa Aktar
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={exportToPDF}
                            sx={{fontSize: '0.875rem', padding: '8px 16px'}}>
                            PDF'e Dışa Aktar
                        </Button>
                    </Box>
                </Box>
            </div>

            {editAssigneesDialogOpen && activeTask != null && (
                <EditTaskAssigneesDialog
                    taskId={activeTask.id}
                    currentAssigneeIds={activeTask.assignees.map(assignee => assignee.id)}
                    handleClose={handleEditAssigneesDialogClose}
                    handleEditTaskAssignees={handleEditTask}/>
            )}

            {editDialogOpen && activeTask != null && (
                <EditTaskDialog
                    task={activeTask}
                    handleClose={handleEditDialogClose}
                    handleEditTask={handleEditTask}/>
            )}

            {changeStatusDialogOpen && activeTask != null && (
                <ChangeStatusDialog
                    task={activeTask}
                    handleClose={handleChangeStatusDialogClose}
                    handleChangeStatus={handleChangeStatus}/>
            )}

            {deleteTaskDialogOpen && activeTask != null && (
                <DeleteTaskDialog
                    task={activeTask}
                    handleClose={handleDeleteTaskDialogClose}
                    handleDelete={handleDelete}/>
            )}

            {addFileDialogOpen && activeTask != null && (
                <AddFileToTaskDialog
                    task={activeTask}
                    onClose={handleAddFileDialogClose}
                    onFileAdded={handleFileAdded}/>
            )}

            <Box sx={{overflowX: 'auto'}}>
                <Table sx={{minWidth: 800}} aria-label="my tasks table">
                    <TableHead>
                        <TableRow>
                            <TableCell>GÖREV ADI</TableCell>
                            <TableCell>AÇIKLAMA</TableCell>
                            <TableCell>DURUMU</TableCell>
                            <TableCell>ATAYAN KİŞİ</TableCell>
                            <TableCell>ATANAN KULLANICILAR</TableCell>
                            <TableCell>ATANMA TARİHİ</TableCell>
                            <TableCell>HEDEFLENEN TARİH</TableCell>
                            <TableCell>ÖNCELİK</TableCell>
                            <TableCell>İŞLEMLER</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allPaginatedTasks?.entity.map((task) => (
                            <TableRow key={task.id}>
                                <TableCell>{task.title}</TableCell>
                                <TableCell>{task.description}</TableCell>
                                <TableCell>{getPresentableTaskStatus(task.status)}</TableCell>
                                <TableCell>{task.createdBy ? `${task.createdBy.firstName} ${task.createdBy.lastName}` : '-'}</TableCell>
                                <TableCell>
                                    {task.assignees.length > 0
                                        ? task.assignees.map((user) => user.firstName + " " + user.lastName).join(', ')
                                        : 'Atama Yok'}
                                </TableCell>
                                <TableCell>{
                                    task.createdAt
                                        ? formatDate(task.createdAt)
                                        : NO_DATA
                                }</TableCell>
                                <TableCell>{
                                    task.dueDate
                                        ? formatDate(task.dueDate)
                                        : NO_DATA
                                }</TableCell>
                                <TableCell>{getPresentableTaskPriority(task.priority)}</TableCell>
                                <TableCell>
                                    <TaskAction
                                        onEditTask={handleEditDialogOpen}
                                        task={task}
                                        onChangeStatus={openChangeStatus}
                                        onDelete={openDelete}
                                        onEditAssignees={handleEditAssigneesDialogOpen}
                                        onAddFile={openAddFile}/>
                                </TableCell>
                            </TableRow>
                        ))}
                        {getAllPaginatedTasksStatus?.type !== AsyncTaskStatusType.Loading && allPaginatedTasks?.numberOfElements === 0 && (
                            <TableRow>
                                <TableCell colSpan={9} style={{textAlign: "center"}}>
                                    Görevlerim Bulunamadı
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>
            {allPaginatedTasks?.totalNumberPages != null && allPaginatedTasks.totalNumberPages > 1 && (
                <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                    <Pagination
                        page={page}
                        count={allPaginatedTasks.totalNumberPages}
                        defaultPage={1}
                        onChange={handleChangePage}/>
                </div>
            )}
        </UserLayout>
    );
};
