import React, {MouseEvent, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DotsVertical from "mdi-material-ui/DotsVertical";
import {AddCircle, Delete, Edit} from "@mui/icons-material";
import {ContractDTO, UserPermission} from "../api/dtos";
import {useSelector} from "react-redux";
import {AppState} from "../store";
import Tooltip from '@mui/material/Tooltip';
import {adminOrHasPermission} from "../shared/utils";

interface ContractActionProps {
    contract: ContractDTO;
    onDelete: (contract: ContractDTO) => void;
    onEdit: (contract: ContractDTO) => void;
    onAddFile: (contract: ContractDTO) => void;
    onAddTask: (contract: ContractDTO) => void;
}

const ContractAction: React.FC<ContractActionProps> = ({
                                                           contract,
                                                           onDelete,
                                                           onEdit,
                                                           onAddFile,
                                                           onAddTask,
                                                       }) => {
    const user = useSelector((state: AppState) => state.auth.userWithToken?.user);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onMenuItemClick = (action: () => void) => {
        handleClose();
        action();
    };

    const deleteContract = () => onMenuItemClick(() => onDelete(contract));
    const editContract = () => onMenuItemClick(() => onEdit(contract));
    const addFile = () => onMenuItemClick(() => onAddFile(contract));
    const addTask = () => onMenuItemClick(() => onAddTask(contract));

    const contractEdit = adminOrHasPermission(user, UserPermission.CONTRACT_EDIT);
    const contractDelete = adminOrHasPermission(user, UserPermission.CONTRACT_DELETE);
    const contractFileAdd = adminOrHasPermission(user, UserPermission.CONTRACT_FILE_ADD);
    const contractTaskAdd = adminOrHasPermission(user, UserPermission.TASK_ADD);
    const hasAtLeastOneAction = contractEdit || contractDelete || contractFileAdd || contractTaskAdd;

    return hasAtLeastOneAction
        ? (
            <>
                <IconButton
                    aria-controls={open ? 'contract-action-menu' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    size='small'
                    aria-label='settings'
                    className='card-more-options'
                    sx={{color: 'text.secondary'}}>
                    <DotsVertical/>
                </IconButton>
                <Menu
                    id="contract-action-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}>
                    {contractEdit && (
                        <MenuItem onClick={editContract}>
                            <IconButton size='small' aria-label='edit' className='card-more-options'
                                        sx={{color: 'text.secondary'}}>
                                <Edit/>
                            </IconButton>
                            Düzenle
                        </MenuItem>
                    )}
                    {contractDelete && (
                        <MenuItem onClick={deleteContract}>
                            <IconButton size='small' aria-label='delete' className='card-more-options'
                                        sx={{color: 'text.secondary'}}>
                                <Delete/>
                            </IconButton>
                            Sil
                        </MenuItem>
                    )}
                    {contractFileAdd && (
                        <MenuItem onClick={addFile}>
                            <IconButton size='small' aria-label='add file' className='card-more-options'
                                        sx={{color: 'text.secondary'}}>
                                <AddCircle/>
                            </IconButton>
                            Dosya Ekle
                        </MenuItem>
                    )}
                    {contractTaskAdd && (
                        <MenuItem onClick={addTask}>
                            <IconButton size='small' aria-label='add task' className='card-more-options'
                                        sx={{color: 'text.secondary'}}>
                                <AddCircle/>
                            </IconButton>
                            Görev Ekle
                        </MenuItem>
                    )}
                </Menu>
            </>
        ) : (
            <Tooltip title="Bu işlem için yetkiniz yok!">
                <IconButton
                    size="small"
                    aria-label="settings"
                    className="card-more-options"
                    sx={{color: 'action.disabled'}}>
                    <DotsVertical/>
                </IconButton>
            </Tooltip>
        );
};

export default ContractAction;
