import {useDispatch, useSelector} from "react-redux";
import {getAllCompaniesByNameAction} from "../api/settings";
import UserLayout from "../components/UserLayout";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import {Button, Table, TableBody, TableHead, TableRow} from "@mui/material";
import {CreateCompanyDialog} from "./create-company/createCompanyDialog";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import * as XLSX from "xlsx-js-style";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {robotoRegularBase64} from '../shared/pdf-fonts';
import {CompanyType, getPresentableCompanyType} from "../api/dtos";
import {AppState} from "../store";
import {Pagination} from "@mui/lab";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import TableCell from "@mui/material/TableCell";

export default function Branches() {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get('page') || "1");
    const [createCompanyDialogOpen, setCreateCompanyDialogOpen] = useState(false);
    const companies = useSelector((state: AppState) => state.settings.allPaginatedCompanies);

    useEffect(() => {
        dispatch(getAllCompaniesByNameAction({typeFilter: CompanyType.Branch, page}));
    }, [dispatch, page]);

    const handleCreateCompanyDialogOpen = () => setCreateCompanyDialogOpen(true);
    const handleCreateCompanyDialogClose = () => setCreateCompanyDialogOpen(false);

    const handleCreate = () => {
        dispatch(getAllCompaniesByNameAction({typeFilter: CompanyType.Branch, page}));
    }

    const exportToExcel = () => {
        if (companies) {
            const headers = [[
                "Şirket Adı", "Tür"
            ]];
            const worksheet = XLSX.utils.json_to_sheet(
                companies.entity.map((company) => ({
                    "Şirket Adı": company.name,
                    "Tür": getPresentableCompanyType(company.type as CompanyType),
                }))
            );

            const workbook = XLSX.utils.book_new();
            XLSX.utils.sheet_add_aoa(worksheet, headers);

            const headerRow = headers[0];
            const columnWidths = headerRow.map(header => ({wch: header.length + 5}));
            worksheet['!cols'] = columnWidths;
            headerRow.forEach((_, index) => {
                const cellRef = XLSX.utils.encode_cell({r: 0, c: index});
                if (worksheet[cellRef]) {
                    worksheet[cellRef].s = {
                        font: {bold: true},
                        fill: {
                            patternType: "solid",
                            fgColor: {rgb: "D3D3D3"}
                        }
                    };
                }
            });

            XLSX.utils.book_append_sheet(workbook, worksheet, "Şirket Listesi");
            XLSX.writeFile(workbook, "sirket_listesi.xlsx");
        }
    };

    const handleChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', value.toString());
        navigate(`?${searchParams.toString()}`);
    };

    const exportToPDF = () => {
        if (companies) {
            const doc = new jsPDF();
            doc.addFileToVFS('Roboto-Regular.ttf', robotoRegularBase64);
            doc.addFont('Roboto-Regular.ttf', 'Roboto', 'normal');
            doc.setFont('Roboto');

            doc.setFontSize(18);
            doc.text('Şubeler Listesi', 14, 20);

            const tableData = companies.entity.map((company) => [
                company.name,
                getPresentableCompanyType(company.type as CompanyType),
            ]);

            const tableColumns = ['Şirket Adı', 'Tür'];

            autoTable(doc, {
                head: [tableColumns],
                body: tableData,
                startY: 30,
                margin: {horizontal: 10},
                styles: {
                    font: 'Roboto',
                    cellPadding: 3,
                    fontSize: 8,
                    valign: 'middle',
                    halign: 'left'
                }
            });

            doc.save('subeler_listesi.pdf');
        }
    };

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>FENERBAHÇE ŞUBELER</h1>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                    }}>
                    <Box sx={{display: 'flex', gap: 2}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={exportToExcel}
                            sx={{fontSize: '0.875rem', padding: '8px 16px'}}>
                            Excel'e Dışa Aktar
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={exportToPDF}
                            sx={{fontSize: '0.875rem', padding: '8px 16px'}}>
                            PDF'e Dışa Aktar
                        </Button>
                    </Box>
                    <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        sx={{marginLeft: "auto", marginBottom: 5}}
                        onClick={handleCreateCompanyDialogOpen}>
                        <AddIcon/>
                    </Fab>
                </Box>
            </div>
            <Box sx={{overflowX: 'auto'}}>
                <Table sx={{minWidth: 800}} aria-label='company table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>ŞİRKET ADI</TableCell>
                            <TableCell>TÜR</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {companies?.entity.map((company: any) => (
                            <TableRow key={company.id}>
                                <TableCell>{company.name}</TableCell>
                                <TableCell>{getPresentableCompanyType(company.type as CompanyType)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Box>
            {companies?.totalNumberPages != null && companies.totalNumberPages > 1 && (
                <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                    <Pagination
                        page={page}
                        count={companies.totalNumberPages}
                        defaultPage={1}
                        onChange={handleChangePage}/>
                </div>
            )}
            {createCompanyDialogOpen && (
                <CreateCompanyDialog companyType={CompanyType.Branch} handleClose={handleCreateCompanyDialogClose}
                                     handleCreate={handleCreate}/>
            )}
        </UserLayout>
    );
}
