import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {AsyncTaskStatusType} from "../shared/dtos";
import Box from "@mui/material/Box";
import {CircularProgress} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import {AppState} from "../store";
import {
    CASE_PERMISSIONS,
    CONTRACT_PERMISSIONS,
    SETTINGS_PERMISSIONS,
    TASK_PERMISSIONS,
    USER_PERMISSIONS,
    UserRole
} from "../api/dtos";
import {adminOrHasPermission} from "../shared/utils";

export const PrivateRoutes = () => {
    const location = useLocation();
    const theme = useTheme();
    const {userWithToken} = useSelector((state: AppState) => state.auth);
    const user = userWithToken?.user;
    const {loadUserDataTaskStatus} = useSelector((state: AppState) => state.settings);
    if (!user) {
        return <Navigate to="/logout" replace state={{from: location}}/>;
    }
    const hasCasePermissions = adminOrHasPermission(user, CASE_PERMISSIONS) || false;
    const hasTaskPermissions = adminOrHasPermission(user, TASK_PERMISSIONS) || false;
    const hasContractPermissions = adminOrHasPermission(user, CONTRACT_PERMISSIONS) || false;
    const hasUserPermissions = adminOrHasPermission(user, USER_PERMISSIONS) || false;
    const isAdminOrManager = user && [UserRole.Admin, UserRole.Manager].includes(user.role) || false;
    const hasSettingsPermissions = adminOrHasPermission(user, SETTINGS_PERMISSIONS) || false;

    const allowableRoutes = [];
    if (hasCasePermissions) {
        allowableRoutes.push("/");
        allowableRoutes.push("/tum-dosyalar");
        allowableRoutes.push("/hukuk-davalari");
        allowableRoutes.push("/hukuk-davalari/:id/:tab");
        allowableRoutes.push("/sorusturmalar");
        allowableRoutes.push("/sorusturmalar/:id/:tab");
        allowableRoutes.push("/ceza-davalari");
        allowableRoutes.push("/ceza-davalari/:id/:tab");
        allowableRoutes.push("/icra-davalari");
        allowableRoutes.push("/icra-davalari/:id/:tab");
        allowableRoutes.push("/tuketici-hakem-heyeti-davalari");
        allowableRoutes.push("/ihtarnameler");
        allowableRoutes.push("/icra-takipleri");
        allowableRoutes.push("/icra-takipleri/:id/:tab");
        allowableRoutes.push("/ihtarnameler/:id/:tab");
        allowableRoutes.push("/tuketici-hakem-heyeti-davalari/:id/:tab");
        allowableRoutes.push("/arama-sonuclari");
    }

    if (isAdminOrManager) {
        allowableRoutes.push("/gosterge-paneli/kullanici-istatistikleri");
        allowableRoutes.push("/gosterge-paneli/dava-durumlari");
        allowableRoutes.push("/arama-sonuclari");
    }

    if (hasTaskPermissions) {
        allowableRoutes.push("/gorevler");
        allowableRoutes.push("/gorevler/:id/:tab");
        allowableRoutes.push("/gorevlerim");
    }
    if (hasContractPermissions) {
        allowableRoutes.push("/sozlesmeler");
        allowableRoutes.push("/sozlesmeler/:id/:tab");
    }
    if (hasUserPermissions) {
        allowableRoutes.push("/kullanicilar");
    }
    if (hasSettingsPermissions) {
        allowableRoutes.push("/kullanicilar");
        allowableRoutes.push("/mail-ayarlari");
        allowableRoutes.push("/fenerbahce-subeler");
        allowableRoutes.push("/fenerbahce-istirakler");
        allowableRoutes.push("/karsi-taraf");
        allowableRoutes.push("/adliyeler");
        allowableRoutes.push("/mahkemeler");
        allowableRoutes.push("/noterler");
        allowableRoutes.push("/tuketici-hakem-heyeti");
        allowableRoutes.push("/sehirler");
        allowableRoutes.push("/log");
    }

    allowableRoutes.push("/profile");
    allowableRoutes.push("/bildirimler");

    const dynamicRouteRegex = new RegExp(`^(${allowableRoutes.map(route => {
            return route.replace(/:[a-zA-Z0-9_]+/g, '([^/]+)');
        }).join("|")})(\\/[^/?#]+)?$`);

    const cleanPathname = location.pathname.split('?')[0].split('#')[0];

    if (
        allowableRoutes.includes(cleanPathname) ||
        dynamicRouteRegex.test(cleanPathname)
    ) {
        // TODO: it is left empty for now
    } else {
        return (<Navigate to={allowableRoutes[0] || "/profile"}/>);
    }

    if (loadUserDataTaskStatus?.type === AsyncTaskStatusType.Loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh'
                }}>
                <Card
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 3,
                        boxShadow: 3,
                        backgroundColor: theme.palette.primary.main
                    }}>
                    <CardContent sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <CircularProgress color="secondary"/>
                        <Typography variant="h6" sx={{marginTop: 2, color: theme.palette.secondary.main}}>
                            Yükleniyor...
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        );
    }
    return <Outlet/>;
}
