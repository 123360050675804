import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Tab, Tabs} from '@mui/material';
import UserLayout from '../../components/UserLayout';
import Box from "@mui/material/Box";
import {ContractDetails} from './ContractDetails';
import {ContractFiles} from "./ContractFiles";
import {ContractTask} from "./ContractTask";

enum TabRoutes {
    Details = 'ayrintilar',
    Files = 'dosyalar',
    Tasks = 'gorevler',
}

const tabIndexMap: Map<TabRoutes, number> = new Map([
    [TabRoutes.Details, 0],
    [TabRoutes.Files, 1],
    [TabRoutes.Tasks, 2],
]);

const tabLabelMap: Map<number, TabRoutes> = new Map([
    [0, TabRoutes.Details],
    [1, TabRoutes.Files],
    [2, TabRoutes.Tasks],
]);

const ContractDetailsTabs = () => {
    const {id, tab} = useParams<{ id: string, tab: string }>();
    const [tabIndex, setTabIndex] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    const pathSegments = location.pathname.split('/').filter(Boolean);

    useEffect(() => {
        const initialIndex = tabIndexMap.get(tab as TabRoutes) ?? 0;
        setTabIndex(initialIndex);
    }, [tab]);

    useEffect(() => {
        if (!tab) {
            navigate(`/${pathSegments[0]}/${id}/${TabRoutes.Details}`, {replace: true});
        }
    }, [id, tab, navigate, pathSegments]);

    const handleTabChange = (_: any, newIndex: number) => {
        setTabIndex(newIndex);
        const route = tabLabelMap.get(newIndex);
        if (route) {
            navigate(`/${pathSegments[0]}/${id}/${route}`);
        }
    };

    const contractId = Number(id!);

    return (
        <UserLayout>
            <h1>SÖZLEŞME DETAYLARI</h1>
            <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Sözleşme Detay Sekmeleri">
                <Tab label="Ayrıntılar"/>
                <Tab label="Dosyalar"/>
                <Tab label="Görevler"/>
            </Tabs>
            <Box sx={{pt: 2}}>
                {tabIndex === 0 && <ContractDetails id={contractId}/>}
                {tabIndex === 1 && <ContractFiles id={contractId}/>}
                {tabIndex === 2 && <ContractTask id={contractId}/>}
            </Box>
        </UserLayout>
    );
};

export default ContractDetailsTabs;
