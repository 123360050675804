import {Grid, Paper, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {getPresentableTaskPriority, getPresentableTaskStatus, TaskDTO, UserInTaskDTO} from "../../api/dtos";
import {getTaskAction} from "../../api/task";
import {setLoadTaskStatus, setTask} from "../../features/task/taskSlice";
import {formatDate, NO_DATA} from "../../shared/utils";

export interface TaskDetailsProps {
    id: number;
}

const TaskDetailsRow: React.FC<{ label: string; value: string | number }> = ({label, value}) => (
    <Grid item xs={6} container spacing={1} alignItems="center">
        <Grid item>
            <Typography variant="subtitle1" fontWeight="bold" display="inline">
                {label}:
            </Typography>
        </Grid>
        <Grid item>
            <Typography variant="body1" display="inline">
                {value}
            </Typography>
        </Grid>
    </Grid>
);

const AssigneeList: React.FC<{ assignees: UserInTaskDTO[] }> = ({assignees}) => (
    <Grid item xs={6} container spacing={1} alignItems="center">
        <Grid item>
            <Typography variant="subtitle1" fontWeight="bold">
                ATANAN KULLANICILAR:
            </Typography>
        </Grid>
        <Grid item>
            <Typography variant="body1" display="inline">
                {assignees.length > 0
                    ? assignees.map((assignee) => `${assignee.firstName} ${assignee.lastName}`).join(', ') || NO_DATA
                    : NO_DATA}
            </Typography>
        </Grid>
    </Grid>
);

export const TaskDetails: React.FC<TaskDetailsProps> = ({id}) => {
    const dispatch: any = useDispatch();
    const task = useSelector((state: AppState) => state.task.task);

    useEffect(() => {
        dispatch(getTaskAction(id));
        return () => {
            dispatch(setTask(null));
            dispatch(setLoadTaskStatus(null));
        };
    }, [id, dispatch]);

    if (!task) {
        return null;
    }

    const {
        title,
        description,
        assignees,
        status,
        legalCase,
        createdAt,
        dueDate,
        priority
    } = task as TaskDTO;

    return (
        <Paper elevation={3} sx={{p: 3}}>
            <Grid container spacing={2}>
                <TaskDetailsRow label="GÖREV ADI" value={title || NO_DATA}/>
                <TaskDetailsRow label="AÇIKLAMA" value={description || NO_DATA}/>
                <AssigneeList assignees={assignees}/>
                <TaskDetailsRow label="DURUMU" value={getPresentableTaskStatus(status)}/>
                <TaskDetailsRow label="İLGİLİ DAVA" value={legalCase?.name || NO_DATA}/>
                <TaskDetailsRow label="ATANMA TARİHİ"
                                value={createdAt ? formatDate(createdAt) : NO_DATA}/>
                <TaskDetailsRow label="HEDEFLENEN TARİH"
                                value={dueDate ? formatDate(dueDate) : NO_DATA}/>
                <TaskDetailsRow label="ÖNCELİK" value={getPresentableTaskPriority(priority)}/>
            </Grid>
        </Paper>
    );
};
