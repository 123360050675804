import {refreshTokenAction} from "../api/auth";
import {AppState} from "../store";
import {unwrapResult} from "@reduxjs/toolkit";
import {useLocation, useParams} from "react-router-dom";
import {useMemo} from "react";
import {StorageKeys} from "../api/constants";
import {UserDTO, UserPermission, UserRole} from "../api/dtos";
import moment from "moment";

export const expiresIn = 3600;

export const getAppToken = async (dispatch: any, getState: () => AppState) => {
    const state = getState();
    const token = state.auth.userWithToken?.token;
    const expiry = state.auth.userWithToken?.tokenExpiryIn;

    if (!token || !expiry) {
        return null;
    }
    if (Date.now() > Number(expiry)) {
        return unwrapResult(await dispatch(refreshTokenAction()));
    }
    return state.auth.userWithToken?.token;
}

export const getAppUserId = () => localStorage.getItem(StorageKeys.USER_ID) || sessionStorage.getItem(StorageKeys.USER_ID);

export const useHandleURLQueries = (path: string | undefined) => {
    const location = useLocation();
    const params = useParams();

    return useMemo(() => {
        if (Object.keys(params).length && path) {
            const arr = Object.keys(params);

            return (location.pathname.startsWith(path) || location.pathname.startsWith(params[arr[0]] as any)) && path !== '/';
        }

        return false;
    }, [location.pathname, params, path]);
};

export interface Option {
    label: string;
    value: number;
}

export const adminOrHasPermission = (user: UserDTO | null | undefined, requiredPermissions?: UserPermission[] | UserPermission) => {
    return user && ([UserRole.Admin, UserRole.Manager].includes(user.role) ||
        (requiredPermissions && user.permissions.some((it) => Array.isArray(requiredPermissions) ? requiredPermissions.includes(it) : requiredPermissions === it)));
}

export const formatDate = (timestamp: number | null | undefined, format: string = 'DD MMMM YYYY') => {
    return timestamp ? moment.unix(timestamp).format(format) : "-";
}

export const NO_DATA = "-"
