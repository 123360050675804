import {createAsyncThunk} from "@reduxjs/toolkit";
import {getAppToken} from "../shared/utils";
import {AppState} from "../store";
import axios from "axios";
import {EndPoints} from "./EndPoints";
import {ErrorMessages, Headers} from './constants';
import {setSearchResults, setSearchTaskStatus} from "../features/search/searchSlice";
import {AsyncTaskStatusType, ResponseDTO} from "../shared/dtos";

export const searchTaskAction = createAsyncThunk(
    'search/searchTask',
    async ({searchText, pageSize, pageNumber}: { searchText: string; pageSize: number; pageNumber: number }, {
        dispatch,
        getState,
        rejectWithValue,
        fulfillWithValue
    }) => {
        dispatch(setSearchTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const params = new URLSearchParams({
                searchText,
                pageSize: pageSize.toString(),
                pageNumber: pageNumber.toString()
            });

            const response = await axios.get(`${EndPoints.search}?${params.toString()}`, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, resultValue, errorDetails} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setSearchTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setSearchResults(resultValue));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setSearchTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setSearchTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage,
            }));
            return rejectWithValue(errorMessage);
        }
    }
);
