import React, {useEffect} from 'react';
import {Button, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../../store';
import {AsyncTaskStatusType} from '../../shared/dtos';
import ErrorText from '../../components/ErrorText';
import CustomDialog from '../../components/CustomDialog';
import {deleteFolderFromTaskAction} from '../../api/task';
import {setDeleteFolderTaskStatus} from '../../features/task/taskSlice';

interface DeleteTaskFolderDialogProps {
    handleClose: () => void;
    handleDelete: () => void;
    taskId: number;
    folderId: number;
    folderName: string;
}

export const DeleteTaskFolderDialog: React.FC<DeleteTaskFolderDialogProps> = ({
                                                                                  handleClose,
                                                                                  handleDelete,
                                                                                  taskId,
                                                                                  folderId,
                                                                                  folderName,
                                                                              }) => {
    const deleteFolderTaskStatus = useSelector((state: AppState) => state.task.deleteFolderTaskStatus);
    const dispatch: any = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(setDeleteFolderTaskStatus(null));
        };
    }, [dispatch]);

    const handleDeleteClick = async () => {
        try {
            const result = await dispatch(deleteFolderFromTaskAction({taskId, folderId}));
            if (result.type === 'task/deleteFolderFromTask/fulfilled') {
                handleDelete();
                handleClose();
            }
        } catch (error) {
            console.error('Klasör silme işleminde bir hata oluştu:', error);
        }
    };

    return (
        <CustomDialog open={true} onClose={handleClose}>
            <DialogTitle>Klasörü Sil ({folderName})</DialogTitle>
            <DialogContent>
                Bu klasörü silmek istediğinizden emin misiniz?
                {deleteFolderTaskStatus?.type === AsyncTaskStatusType.Error &&
                    deleteFolderTaskStatus.errorMessage && (
                        <ErrorText type="form" message={deleteFolderTaskStatus.errorMessage}/>
                    )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button onClick={handleDeleteClick} color="error">
                    Sil
                </Button>
            </DialogActions>
        </CustomDialog>
    );
};
