import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import UserLayout from "../components/UserLayout";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import {Pagination} from "@mui/lab";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import * as XLSX from "xlsx-js-style";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {robotoRegularBase64} from "../shared/pdf-fonts";
import {AppState} from "../store";
import {CreateCommitteeDialog} from "./create-committee/createCommitteeDialog";
import {getAllCommitteesAction, getAllPaginatedCommitteesAction} from "../api/settings";
import {AsyncTaskStatusType} from "../shared/dtos";

export default function Committee() {
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get("page") || "1");
    const filterByName = searchParams.get("filterByName") || "";
    const dispatch: any = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [createCommitteeDialogOpen, setCreateCommitteeDialogOpen] = useState(false);

    useEffect(() => {
        dispatch(getAllCommitteesAction());
        dispatch(getAllPaginatedCommitteesAction({filterByName, page}));
    }, [filterByName, page]);

    const committees = useSelector((state: AppState) => state.settings.allPaginatedCommittees);
    const getAllPaginatedCommitteesStatus = useSelector((state: AppState) => state.settings.getAllPaginatedCommitteesStatus);
    const allPaginatedCommittees = useSelector((state: AppState) => state.settings.allPaginatedCommittees);

    const handleChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("page", value.toString());
        navigate(`?${searchParams.toString()}`);
    };

    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("filterByName", event.target.value);
        searchParams.set("page", "1");
        navigate(`?${searchParams.toString()}`);
    };

    const handleCreateCommitteeDialogOpen = () => setCreateCommitteeDialogOpen(true);

    const handleCreateCommitteeDialogClose = () => {
        setCreateCommitteeDialogOpen(false);
        dispatch(getAllPaginatedCommitteesAction({filterByName, page}));
    };

    const exportToExcel = () => {
        if (committees?.entity) {
            const headers = [["Hakem Heyeti Adı", "Şehir"]];
            const worksheet = XLSX.utils.json_to_sheet(
                committees.entity.map((committee: any) => ({
                    "Hakem Heyeti Adı": committee.name,
                    "Şehir": committee.city.name,
                }))
            );

            const workbook = XLSX.utils.book_new();
            XLSX.utils.sheet_add_aoa(worksheet, headers, {origin: "A1"});

            const headerRow = headers[0];
            const columnWidths = headerRow.map(header => ({wch: header.length + 5}));
            worksheet['!cols'] = columnWidths;
            headerRow.forEach((_, index) => {
                const cellRef = XLSX.utils.encode_cell({r: 0, c: index});
                if (worksheet[cellRef]) {
                    worksheet[cellRef].s = {
                        font: {bold: true},
                        fill: {
                            patternType: "solid",
                            fgColor: {rgb: "D3D3D3"}
                        }
                    };
                }
            });

            XLSX.utils.book_append_sheet(workbook, worksheet, "Tüketici Hakem Heyeti Listesi");
            XLSX.writeFile(workbook, "tüketici_hakem_heyeti_listesi.xlsx");
        }
    };

    const exportToPDF = () => {
        if (committees?.entity) {
            const doc = new jsPDF();
            doc.addFileToVFS("Roboto-Regular.ttf", robotoRegularBase64);
            doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
            doc.setFont("Roboto");

            doc.setFontSize(18);
            doc.text("Tüketici Hakem Heyeti Listesi", 14, 20);

            const tableData = committees.entity.map((committee: any) => [
                committee.name,
                committee.city.name,
            ]);

            const tableColumns = ["Hakem Heyeti Adı", "Şehir"];

            autoTable(doc, {
                head: [tableColumns],
                body: tableData,
                startY: 30,
                margin: {horizontal: 10},
                styles: {
                    font: "Roboto",
                    cellPadding: 3,
                    fontSize: 8,
                    valign: "middle",
                    halign: "left",
                },
            });

            doc.save("tüketici_hakem_heyeti_listesi.pdf");
        }
    };

    const handleCreate = () => {
        navigate("");
        dispatch(getAllPaginatedCommitteesAction({filterByName, page}));
        handleCreateCommitteeDialogClose();
    }

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>TÜKETİCİ HAKEM HEYETİ</h1>
                <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2}}>
                    <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        sx={{marginLeft: "auto", marginBottom: 5}}
                        onClick={handleCreateCommitteeDialogOpen}>
                        <AddIcon/>
                    </Fab>
                </Box>
                <Box sx={{display: "flex", gap: 2, mb: 2}}>
                    <Button variant="contained" color="primary" onClick={exportToExcel}>
                        Excel'e Dışa Aktar
                    </Button>
                    <Button variant="contained" color="secondary" onClick={exportToPDF}>
                        PDF'e Dışa Aktar
                    </Button>
                </Box>
            </div>

            {createCommitteeDialogOpen && (
                <CreateCommitteeDialog handleClose={handleCreateCommitteeDialogClose}
                                       handleCreate={handleCreate}/>
            )}

            <Box sx={{overflowX: "auto"}}>
                <Table sx={{minWidth: 800}} aria-label="committees table">
                    <TableHead>
                        <TableRow>
                            <TableCell>KOMİTE ADI</TableCell>
                            <TableCell>ŞEHİR</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {committees?.entity.map((committee) => (
                            <TableRow key={committee.id}>
                                <TableCell>{committee.name}</TableCell>
                                <TableCell>{committee.city.name}</TableCell>
                            </TableRow>
                        ))}
                        {getAllPaginatedCommitteesStatus?.type !== AsyncTaskStatusType.Loading && allPaginatedCommittees?.numberOfElements === 0 && (
                            <TableRow>
                                <TableCell colSpan={9} style={{textAlign: "center"}}>
                                    Tüketici Hakem Heyetleri Bulunamadı
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>
            {committees?.totalNumberPages != null && committees.totalNumberPages > 1 && (
                <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                    <Pagination
                        page={page}
                        count={committees.totalNumberPages}
                        defaultPage={1}
                        onChange={handleChangePage}/>
                </div>
            )}
        </UserLayout>
    );
}