import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import CustomDialog from "../../components/CustomDialog";
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {tr} from "date-fns/locale";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
    AddContractDTO,
    CompanyType,
    ContractStatus,
    ContractType,
    getPresentableContractStatus,
    getPresenTableContractType
} from "../../api/dtos";
import {createContractAction} from "../../api/contract";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import {AppState} from "../../store";
import {getAllCompaniesAction} from "../../api/settings";
import TextField from "@mui/material/TextField";
import {Autocomplete} from "@mui/lab";

export interface CreateContractDialogProps {
    onClose: () => void;
    onContractAdded: () => void;
}

export const CreateContractDialog: React.FC<CreateContractDialogProps> = ({onClose, onContractAdded}) => {
    const dispatch: any = useDispatch();
    const createContractTaskStatus = useSelector((state: AppState) => state.contract.createContractTaskStatus);
    const allCompanies = useSelector((state: AppState) => state.settings.allCompanies);
    const user = useSelector((state: AppState) => state.auth.userWithToken);
    const associatedCompanyId = user?.user?.associatedCompany?.id || null;
    const [contractType, setContractType] = useState<ContractType | null>(null);
    const [contractStatus, setContractStatus] = useState<ContractStatus | null>(null);
    const [relatedCompanyId, setRelatedCompanyId] = useState<number | null>(associatedCompanyId);
    const [relatedSide, setRelatedSide] = useState<string>("");
    const [signDate, setSignDate] = useState<Date | null>(null);
    const [validUntil, setValidUntil] = useState<Date | null>(null);
    const [errors, setErrors] = useState<FieldErrors>({});
    const [isValidUntilDisabled, setIsValidUntilDisabled] = useState(true);
    const isSubmitDisabled = !contractType || !contractStatus || !relatedCompanyId || !signDate || !relatedSide;

    useEffect(() => {
        if (!associatedCompanyId) {
            dispatch(getAllCompaniesAction());
        }

    }, [dispatch]);

    useEffect(() => {
        setIsValidUntilDisabled(!signDate);
    }, [signDate]);

    useEffect(() => {
        if (signDate && validUntil && signDate <= validUntil) {
            setErrors((prevErrors) => {
                const {validUntil, ...rest} = prevErrors;
                return rest;
            });
        }
    }, [signDate, validUntil]);

    const handleSubmit = async () => {
        if (!signDate || !contractType || !contractStatus || !relatedCompanyId || !relatedSide) return;

        if (validUntil && signDate > validUntil) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                validUntil: "Geçerlilik tarihi imzalanma tarihinden önce olamaz."
            }));
            return;
        }

        const newContract: AddContractDTO = {
            type: contractType,
            status: contractStatus,
            relatedSide,
            signDate: signDate.toISOString(),
            validUntil: validUntil ? validUntil.toISOString() : undefined,
            relatedCompanyId
        };

        try {
            const result = await dispatch(createContractAction(newContract));
            if (result.type === "contract/createContract/fulfilled") {
                onContractAdded();
                onClose();
            }
        } catch (error) {
            console.error("Sözleşme eklerken hata oluştu:", error);
        }
    };

    useEffect(() => {
        if (createContractTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            createContractTaskStatus.fieldErrors.forEach(({field, message}) => {
                updatedErrors[field] = message;
            });
            setErrors(updatedErrors);
        }
    }, [createContractTaskStatus]);

    return (
        <CustomDialog
            open={true}
            onClose={onClose}>
            <DialogTitle>Sözleşme Ekle</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="contract-type-label">Sözleşme Türü <span>*</span></InputLabel>
                    <Select
                        labelId="contract-type-label"
                        id="contract-type"
                        name="contract-type"
                        value={contractType}
                        onChange={(e) => setContractType(e.target.value as ContractType)}
                        label="Sözleşme Türü *"
                        fullWidth
                        required>
                        <MenuItem value={ContractType.Sports}>
                            {getPresenTableContractType(ContractType.Sports)}
                        </MenuItem>
                        <MenuItem value={ContractType.Termination}>
                            {getPresenTableContractType(ContractType.Termination)}
                        </MenuItem>
                        <MenuItem value={ContractType.Sponsor}>
                            {getPresenTableContractType(ContractType.Sponsor)}
                        </MenuItem>
                    </Select>
                    {errors.type && <ErrorText message={errors.type}/>}
                </FormControl>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="contract-status-label">Sözleşme Durumu <span>*</span></InputLabel>
                    <Select
                        label="Sözleşme Durumu *"
                        labelId="contract-status-label"
                        value={contractStatus}
                        onChange={(e) => setContractStatus(e.target.value as ContractStatus)}
                        fullWidth
                        required>
                        <MenuItem value={ContractStatus.Signed}>
                            {getPresentableContractStatus(ContractStatus.Signed)}
                        </MenuItem>
                        <MenuItem value={ContractStatus.Expired}>
                            {getPresentableContractStatus(ContractStatus.Expired)}
                        </MenuItem>
                    </Select>
                    {errors.status && <ErrorText message={errors.status}/>}
                </FormControl>
                {!associatedCompanyId && (
                    <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                        <Autocomplete
                            id="related-company-id"
                            options={allCompanies || []}
                            groupBy={(option) =>
                                option.type === CompanyType.Branch ? "ŞUBELER" : "İŞTİRAKLER"}
                            getOptionLabel={(option) => option.name}
                            value={allCompanies?.find(company => company.id === relatedCompanyId) || null}
                            onChange={(event, newValue) => setRelatedCompanyId(newValue?.id || null)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="İlgili Şirket"
                                    required
                                    error={!!errors.relatedCompanyId}
                                    helperText={errors.relatedCompanyId}/>
                            )}/>
                    </FormControl>
                )}
                <TextField
                    label="İlgili Taraf"
                    value={relatedSide}
                    onChange={(e) => setRelatedSide(e.target.value)}
                    fullWidth
                    required
                    margin="dense"
                    variant="outlined"
                    error={!!errors.relatedSide}
                    helperText={errors.relatedSide}/>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
                        <DateTimePicker
                            label="İmzalanma Tarihi"
                            value={signDate}
                            onChange={(newDate) => setSignDate(newDate)}
                            maxDate={validUntil || undefined}
                            //@ts-ignore
                            renderInput={(params) => <TextField {...params} required fullWidth/>}/>
                    </LocalizationProvider>
                    {errors.signDate && <ErrorText message={errors.signDate}/>}
                </FormControl>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
                        <DateTimePicker
                            label="Geçerlilik Tarihi"
                            value={validUntil}
                            onChange={(newDate) => setValidUntil(newDate)}
                            disabled={isValidUntilDisabled}
                            minDate={signDate || undefined}
                            //@ts-ignore
                            renderInput={(params) => <TextField {...params} fullWidth/>}/>
                    </LocalizationProvider>
                    {errors.validUntil && <ErrorText message={errors.validUntil}/>}
                </FormControl>
                {createContractTaskStatus?.type === AsyncTaskStatusType.Error && createContractTaskStatus.errorMessage && (
                    <ErrorText type="form" message={createContractTaskStatus.errorMessage}/>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>İptal</Button>
                <Button onClick={handleSubmit} color="primary" disabled={isSubmitDisabled}>
                    Kaydet
                </Button>
            </DialogActions>
        </CustomDialog>
    );
};