import axios from "axios";
import {EndPoints} from "./EndPoints";
import {getAppToken} from "../shared/utils";
import {AsyncTaskStatusType, ResponseDTO} from "../shared/dtos";
import {AppState} from "../store";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    setAddFileToLegalCaseTaskStatus,
    setAddNoteToLegalCaseTaskStatus,
    setAllPaginatedCompanySpecificLegalCases,
    setAllPaginatedLegalCases,
    setCreateFolderTaskStatus,
    setCreateLegalCaseTaskStatus,
    setDeleteFolderTaskStatus,
    setFolderFilesAndFolders,
    setLegalCase,
    setLoadCompanyLegalCasesTaskStatus,
    setLoadFolderFilesAndFoldersTaskStatus,
    setLoadLegalCasesTaskStatus,
    setLoadLegalCaseTaskStatus,
    setLoadStatisticsTaskStatus,
    setStatistics,
    setUpdateLegalCaseStatusTaskStatus
} from "../features/legal-case/legalCaseSlice";
import {AddNoteToLegalCaseDTO, CaseFileInfoDTO, CaseType, CreateFolderDTO, LegalCaseStatus} from "./dtos";
import {setDeleteLegalCaseTaskStatus} from "../features/settings/settingsSlice";
import {ErrorMessages, Headers} from "./constants";

export const createLegalCaseAction = createAsyncThunk(
    'legalCase/createLegalCase',
    async (dto: FormData, {dispatch, getState, rejectWithValue, fulfillWithValue}: any) => {
        dispatch(setCreateLegalCaseTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.post(`${EndPoints.legalCase}`, dto, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                },
            });

            const {code, message} = response.data as ResponseDTO;
            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setCreateLegalCaseTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else if (code === ResponseDTO.CodeEnum.ValidationError) {
                dispatch(setCreateLegalCaseTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: 'Gerekli Alanları Doldurun!'
                }));
                return rejectWithValue("Gerekli Alanları Doldurun!");
            } else if (code === ResponseDTO.CodeEnum.DuplicateObject) {
                dispatch(setCreateLegalCaseTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: 'Aynı isimde dava var'
                }));
                return rejectWithValue("Aynı isimde dava var");
            } else {
                dispatch(setCreateLegalCaseTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setCreateLegalCaseTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const getLegalCasesAction = createAsyncThunk(
    'legalCase/getLegalCasesAction',
    async ({
               companyIdFilter = null,
               clientIdFilter = null,
               nameFilter = null,
               typeFilter = null,
               courtIdFilter = null,
               statusFilter = null,
               fileNumberFilter = null,
               corporateFileNumberFilter = null,
               responsibleIdFilter = null,
               courtHouseFilter = null,
               litigantFilter = null,
               page = 1
           }: {
        companyIdFilter?: number | null;
        clientIdFilter?: number | null;
        nameFilter?: string | null;
        typeFilter?: CaseType | null;
        courtIdFilter?: number | null;
        statusFilter?: LegalCaseStatus | null;
        fileNumberFilter?: string | null;
        corporateFileNumberFilter?: string | null;
        responsibleIdFilter?: number | null;
        courtHouseFilter?: string | null;
        litigantFilter?: string | null;
        page: number;
    }, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setLoadLegalCasesTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const params = new URLSearchParams({
                page: page.toString(),
                typeFilter: typeFilter || CaseType.Law
            });

            if (nameFilter) {
                params.append('nameFilter', nameFilter);
            }
            if (courtIdFilter != null) {
                params.append('courtIdFilter', courtIdFilter.toString());
            }
            if (statusFilter) {
                params.append('statusFilter', statusFilter);
            }
            if (fileNumberFilter) {
                params.append('fileNumberFilter', fileNumberFilter);
            }
            if (corporateFileNumberFilter) {
                params.append('corporateFileNumberFilter', corporateFileNumberFilter);
            }
            if (courtHouseFilter) {
                params.append('courtHouseFilter', courtHouseFilter);
            }
            if (companyIdFilter != null) {
                params.append('companyIdFilter', companyIdFilter.toString());
            }
            if (responsibleIdFilter != null) {
                params.append('responsibleIdFilter', responsibleIdFilter.toString());
            }
            if (litigantFilter != null) {
                params.append('litigantFilter', litigantFilter.toString());
            }

            const response = await axios.get(`${EndPoints.legalCase}/legal-cases-by-filter?${params.toString()}`, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.CONTENT_TYPE]: Headers.APPLICATION_JSON,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                },
            });

            const {code, message, errorDetails, resultValue} = response.data;
            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setLoadLegalCasesTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setAllPaginatedLegalCases(resultValue));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setLoadLegalCasesTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setLoadLegalCasesTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const getCompanyLegalCasesAction = createAsyncThunk(
    'legalCase/getCompanyLegalCasesAction',
    async ({
               companyIdFilter = null,
               typeFilter = null,
               responsibleIdFilter = null,
               page = 1
           }: {
        companyIdFilter: number | null;
        typeFilter?: CaseType | null;
        responsibleIdFilter?: number | null;
        page: number;
    }, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setLoadCompanyLegalCasesTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const params = new URLSearchParams({page: page.toString()});

            if (typeFilter != null) {
                params.append('typeFilter', typeFilter);
            }
            if (companyIdFilter != null) {
                params.append('companyIdFilter', companyIdFilter.toString());
            }
            if (responsibleIdFilter != null) {
                params.append('responsibleIdFilter', responsibleIdFilter.toString());
            }

            const response = await axios.get(`${EndPoints.legalCase}/legal-cases-by-filter?${params.toString()}`, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.CONTENT_TYPE]: Headers.APPLICATION_JSON,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                },
            });

            const {code, message, errorDetails, resultValue} = response.data;
            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setLoadCompanyLegalCasesTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setAllPaginatedCompanySpecificLegalCases(resultValue));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setLoadCompanyLegalCasesTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setLoadCompanyLegalCasesTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const deleteLegalCaseAction = createAsyncThunk(
    'legalCase/deleteLegalCase',
    async (caseId: number, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setDeleteLegalCaseTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.delete(`${EndPoints.legalCase}/${caseId}`, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                },
            });

            const {code, message} = response.data as ResponseDTO;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setDeleteLegalCaseTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setDeleteLegalCaseTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setDeleteLegalCaseTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage,
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const getLegalCaseAction = createAsyncThunk(
    'legalCase/getLegalCase',
    async (id: number, {dispatch, getState, rejectWithValue, fulfillWithValue}: any) => {
        dispatch(setLoadLegalCaseTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.get(`${EndPoints.legalCase}/${id}`, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, resultValue, errorDetails} = response.data as ResponseDTO;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setLoadLegalCaseTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setLegalCase(resultValue));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setLoadLegalCaseTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setLoadLegalCaseTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const addFileToLegalCaseAction = createAsyncThunk(
    'legalcase/addFileToLegalCase',
    async ({caseId, dto}: { caseId: number, dto: FormData }, {
        dispatch,
        getState,
        rejectWithValue,
        fulfillWithValue
    }) => {
        dispatch(setAddFileToLegalCaseTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.post(`${EndPoints.legalCase}/${caseId}/upload-file`, dto, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setAddFileToLegalCaseTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(resultValue);
            } else if (code === ResponseDTO.CodeEnum.DuplicateObject) {
                dispatch(setAddFileToLegalCaseTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: 'Aynı İsimde Dosya Var'
                }));
                return rejectWithValue('Aynı İsimde Dosya Var');
            } else {
                dispatch(setAddFileToLegalCaseTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setAddFileToLegalCaseTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const createFolderForLegalCaseAction = createAsyncThunk(
    'legalcase/createFolderForLegalCase',
    async ({caseId, dto}: { caseId: number; dto: CreateFolderDTO }, {
        dispatch,
        getState,
        rejectWithValue,
        fulfillWithValue
    }) => {
        dispatch(setCreateFolderTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.put(`${EndPoints.legalCase}/${caseId}/create-folder`, dto, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                },
            });

            const {code, message, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setCreateFolderTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setCreateFolderTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setCreateFolderTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage,
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const deleteFolderFromLegalCaseAction = createAsyncThunk(
    'legalcase/deleteFolderFromLegalCase',
    async ({caseId, folderId}: { caseId: number; folderId: number }, {
        dispatch,
        getState,
        rejectWithValue,
        fulfillWithValue
    }) => {
        dispatch(setDeleteFolderTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.put(`${EndPoints.legalCase}/${caseId}/delete-folder/${folderId}`, null, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                },
            });

            const {code, message, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setDeleteFolderTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(resultValue);
            } else if (code === ResponseDTO.CodeEnum.InvalidObjectState) {
                dispatch(setDeleteFolderTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: 'Klasörün içinde dosya var!'
                }));
                return rejectWithValue("Klasörün içinde dosya var!");
            } else {
                dispatch(setDeleteFolderTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setDeleteFolderTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage,
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const getLegalCaseFolderFilesAndFoldersAction = createAsyncThunk(
    'legalcase/getLegalCaseFolderFilesAndFolders',
    async ({caseId, folderId}: { caseId: number; folderId?: number }, {
        dispatch,
        getState,
        rejectWithValue,
        fulfillWithValue
    }) => {
        dispatch(setLoadFolderFilesAndFoldersTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.get(`${EndPoints.legalCase}/${caseId}/files-and-folders`, {
                params: {parentFolderId: folderId},
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                },
            });
            const {code, message, resultValue, errorDetails} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setFolderFilesAndFolders(resultValue));
                dispatch(setLoadFolderFilesAndFoldersTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setLoadFolderFilesAndFoldersTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                }));
                return rejectWithValue(message);
            }
            return fulfillWithValue(response.data);
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setLoadFolderFilesAndFoldersTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage,
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const moveLegalCaseFileToFolderAction = createAsyncThunk(
    'legalcase/moveLegalCaseFileToFolder',
    async ({caseId, folderId, fileId}: { caseId: number; folderId: number | null; fileId: number }, {
        dispatch,
        getState,
        rejectWithValue,
        fulfillWithValue
    }) => {
        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.put(
                `${EndPoints.legalCase}/${caseId}/move-to-folder`,
                {fileId, folderId},
                {
                    headers: {
                        [Headers.AUTHORIZATION]: `Bearer ${token}`,
                        [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                    },
                }
            );

            const {code, message, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                return fulfillWithValue(resultValue);
            } else {
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            return rejectWithValue(errorMessage);
        }
    }
);

export const addFileToFolderAction = createAsyncThunk(
    'legalcase/addFileToFolder',
    async ({caseId, folderId, fileId}: { caseId: number; folderId: number; fileId: number }, {
        dispatch,
        getState,
        rejectWithValue,
        fulfillWithValue
    }) => {
        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.put(
                `${EndPoints.legalCase}/${caseId}/folders/${folderId}/add-file`,
                {fileId},
                {
                    headers: {
                        [Headers.AUTHORIZATION]: `Bearer ${token}`,
                        [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                    },
                }
            );

            const {code, message, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(getLegalCaseFolderFilesAndFoldersAction({caseId, folderId}));
                return fulfillWithValue(resultValue);
            } else {
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            return rejectWithValue(errorMessage);
        }
    }
);

export const downloadLegalCaseFile = createAsyncThunk(
    'legalcase/downloadLegalCaseFile',
    async ({caseId, file}: { caseId: number, file: CaseFileInfoDTO }, {dispatch, getState, rejectWithValue}) => {
        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const downloadUrl = `${EndPoints.legalCase}/${caseId}/download-file/${file.id}?access_token=${token}`;

            const response = await axios.get(downloadUrl, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                },
                responseType: 'blob'
            });

            const contentType = response.headers['content-type'] || 'application/octet-stream';
            const blob = new Blob([response.data], {type: contentType});
            const url = URL.createObjectURL(blob);

            const supportedTypes = [
                'application/pdf',
                'image/jpeg',
                'image/png'
            ];

            if (supportedTypes.includes(contentType)) {
                window.open(url, '_blank');
            } else {
                const a = document.createElement('a');
                a.href = url;
                a.download = file.name;
                a.click();
                a.remove();
            }

            URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Dosya indirirken hata oluştu:', error);
        }
    }
);

export const getStatisticsAction = createAsyncThunk(
    'statistics/getStatistics',
    async (_, {dispatch, getState, rejectWithValue, fulfillWithValue}: any) => {
        dispatch(setLoadStatisticsTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.get(`${EndPoints.legalCase}/statistics`, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                },
            });

            const {code, message, resultValue, errorDetails} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setLoadStatisticsTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setStatistics(resultValue));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setLoadStatisticsTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setLoadStatisticsTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage,
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const updateLegalCaseStatusAction = createAsyncThunk(
    'legalCase/updateLegalCaseStatus',
    async ({legalCaseId, newStatus}: { legalCaseId: number; newStatus: string }, {
        dispatch,
        getState,
        rejectWithValue,
        fulfillWithValue
    }) => {
        dispatch(setUpdateLegalCaseStatusTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.put(`${EndPoints.legalCase}/${legalCaseId}/update-status`, {newStatus}, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.CONTENT_TYPE]: Headers.APPLICATION_JSON,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, errorDetails} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setUpdateLegalCaseStatusTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setUpdateLegalCaseStatusTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setUpdateLegalCaseStatusTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage,
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const addNoteToLegalCaseAction = createAsyncThunk(
    'legalCase/addNoteToLegalCaseAction',
    async ({id, dto}: { id: number; dto: AddNoteToLegalCaseDTO }, {
        dispatch,
        getState,
        rejectWithValue,
        fulfillWithValue
    }) => {
        dispatch(setAddNoteToLegalCaseTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.put(`${EndPoints.legalCase}/${id}/add-note`, dto, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.CONTENT_TYPE]: Headers.APPLICATION_JSON,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, errorDetails} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setAddNoteToLegalCaseTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setAddNoteToLegalCaseTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setAddNoteToLegalCaseTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage,
            }));
            return rejectWithValue(errorMessage);
        }
    }
);
