import {createAsyncThunk} from "@reduxjs/toolkit";
import {AddContractDTO, CaseFolderDTO, ContractStatus, ContractType, CreateFolderDTO} from "./dtos";
import {AsyncTaskStatusType, ResponseDTO} from "../shared/dtos";
import {getAppToken} from "../shared/utils";
import {AppState} from "../store";
import axios, {AxiosResponse} from "axios";
import {EndPoints} from "./EndPoints";
import {
    setAddFileToContractTaskStatus,
    setAllPaginatedContracts,
    setContract,
    setCreateContractTaskStatus,
    setCreateFolderContractStatus,
    setDeleteContractTaskStatus,
    setDeleteFolderContractStatus,
    setEditContractTaskStatus,
    setFolderFilesAndFolders,
    setGetAllPaginatedContractsTaskStatus,
    setLoadContractTaskStatus,
    setLoadFolderFilesAndFoldersTaskStatus,
    setMoveFileToFolderTaskStatus
} from "../features/contracts/contractSlice";
import {ErrorMessages, Headers} from './constants'

export const createContractAction = createAsyncThunk(
    'contract/createContract',
    async (dto: AddContractDTO, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setCreateContractTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.post(EndPoints.contract, dto, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.CONTENT_TYPE]: Headers.APPLICATION_JSON,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, fieldErrors, errorDetails} = response.data as ResponseDTO;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setCreateContractTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setCreateContractTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    fieldErrors: fieldErrors,
                    errorDetails,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setCreateContractTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const getAllPaginatedContractsAction = createAsyncThunk(
    'contract/getAllContracts',
    async ({relatedCompanyId, type, status, page, relatedSide}: {
        relatedCompanyId: number | null;
        type: ContractType | null;
        status: ContractStatus | null;
        page: number;
        relatedSide: string | null;
    }, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setGetAllPaginatedContractsTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const params = new URLSearchParams({page: page.toString()});
            if (relatedCompanyId != null) params.append("relatedCompanyId", relatedCompanyId.toString());
            if (type != null) params.append("type", type);
            if (status != null) params.append("status", status);
            if (relatedSide != null) params.append("relatedSide", relatedSide);

            const response = await axios.get(EndPoints.contract + `/contracts-by-filter?${params.toString()}`, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.CONTENT_TYPE]: Headers.APPLICATION_JSON,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, errorDetails, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setGetAllPaginatedContractsTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setAllPaginatedContracts(resultValue));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setGetAllPaginatedContractsTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setGetAllPaginatedContractsTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const deleteContractAction = createAsyncThunk(
    'contract/deleteContract',
    async (id: number, {dispatch, getState, rejectWithValue}) => {
        dispatch(setDeleteContractTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.delete(`${EndPoints.contract}/${id}`, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                },
            });

            const {code, message, errorDetails, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setDeleteContractTaskStatus({type: AsyncTaskStatusType.Success}));
                return resultValue;
            } else {
                dispatch(setDeleteContractTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setDeleteContractTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage,
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const editContractAction = createAsyncThunk(
    'contract/editContract',
    async ({id, type, relatedSide, signDate, validUntil}: {
        id: number;
        type: ContractType;
        relatedSide: string;
        signDate: number | null;
        validUntil: number | null;
    }, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setEditContractTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const payload = {type, relatedSide, signDate, validUntil};

            const response = await axios.put(`${EndPoints.contract}/${id}`, payload, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.CONTENT_TYPE]: Headers.APPLICATION_JSON,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, errorDetails, fieldErrors} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setEditContractTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setEditContractTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                    fieldErrors
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setEditContractTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const addFileToContractAction = createAsyncThunk(
    'contract/addFileToContract',
    async ({contractId, dto}: { contractId: number, dto: FormData }, {
        dispatch,
        getState,
        rejectWithValue,
        fulfillWithValue
    }) => {
        dispatch(setAddFileToContractTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.post(`${EndPoints.contract}/${contractId}/upload-file`, dto, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                }
            });

            const {code, message, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setAddFileToContractTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(resultValue);
            } else if (code === ResponseDTO.CodeEnum.DuplicateObject) {
                dispatch(setAddFileToContractTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: 'Aynı İsimde Dosya Var'
                }));
                return rejectWithValue('Aynı İsimde Dosya Var');
            } else {
                dispatch(setAddFileToContractTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setAddFileToContractTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const createFolderForContractAction = createAsyncThunk(
    'contract/createFolderForContract',
    async ({contractId, dto}: { contractId: number; dto: CreateFolderDTO }, {
        dispatch,
        getState,
        rejectWithValue,
        fulfillWithValue
    }) => {
        dispatch(setCreateFolderContractStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.put(`${EndPoints.contract}/${contractId}/create-folder`, dto, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                },
            });

            const {code, message, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setCreateFolderContractStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setCreateFolderContractStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setCreateFolderContractStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage,
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const deleteFolderFromContractAction = createAsyncThunk(
    'contract/deleteFolderFromContract',
    async ({contractId, folderId}: { contractId: number; folderId: number }, {
        dispatch,
        getState,
        rejectWithValue,
        fulfillWithValue
    }) => {
        dispatch(setDeleteFolderContractStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.put(`${EndPoints.contract}/${contractId}/delete-folder/${folderId}`, null, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                },
            });

            const {code, message, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setDeleteFolderContractStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(resultValue);
            } else if (code === ResponseDTO.CodeEnum.InvalidObjectState) {
                dispatch(setDeleteFolderContractStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: 'Klasörün içinde dosya var!'
                }));
                return rejectWithValue("Klasörün içinde dosya var!");
            } else {
                dispatch(setDeleteFolderContractStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setDeleteFolderContractStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage,
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const getContractFolderFilesAndFoldersAction = createAsyncThunk(
    'contract/getContractFolderFilesAndFolders',
    async ({contractId, folderId}: { contractId: number; folderId?: number }, {
        dispatch,
        getState,
        rejectWithValue,
        fulfillWithValue
    }) => {
        dispatch(setLoadFolderFilesAndFoldersTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response = await axios.get(`${EndPoints.contract}/${contractId}/files-and-folders`, {
                params: {parentFolderId: folderId},
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                },
            });
            const {code, message, resultValue, errorDetails} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setFolderFilesAndFolders(resultValue));
                dispatch(setLoadFolderFilesAndFoldersTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setLoadFolderFilesAndFoldersTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setLoadFolderFilesAndFoldersTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage,
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const moveContractFileToFolderAction = createAsyncThunk(
    'contract/moveContractFileToFolder',
    async ({contractId, folderId, fileId}: {
        contractId: number;
        folderId: number | null;
        fileId: number
    }, {
               dispatch,
               getState,
               rejectWithValue,
               fulfillWithValue
           }) => {
        dispatch(setMoveFileToFolderTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const response: AxiosResponse<any, any> = await axios.put(
                `${EndPoints.contract}/${contractId}/move-to-folder`,
                {fileId, folderId},
                {
                    headers: {
                        [Headers.AUTHORIZATION]: `Bearer ${token}`,
                        [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                    },
                }
            );

            const {code, message, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setMoveFileToFolderTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setMoveFileToFolderTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || ErrorMessages.UNKNOWN_ERROR;
            dispatch(setMoveFileToFolderTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const downloadContractFile = createAsyncThunk(
    'contract/downloadContractFile',
    async ({contractId, file}: { contractId: number, file: CaseFolderDTO }, {dispatch, getState, rejectWithValue}) => {
        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error(ErrorMessages.TOKEN_MISSING);
            }

            const downloadUrl = `${EndPoints.contract}/${contractId}/download-file/${file.id}?access_token=${token}`;

            const response = await axios.get(downloadUrl, {
                headers: {
                    [Headers.AUTHORIZATION]: `Bearer ${token}`,
                    [Headers.ACCEPT]: Headers.APPLICATION_JSON,
                },
                responseType: 'blob'
            });

            const contentType = response.headers['content-type'] || 'application/octet-stream';
            const blob = new Blob([response.data], {type: contentType});
            const url = URL.createObjectURL(blob);

            const supportedTypes = [
                'application/pdf',
                'image/jpeg',
                'image/png'
            ];

            if (supportedTypes.includes(contentType)) {
                window.open(url, '_blank');
            } else {
                const a = document.createElement('a');
                a.href = url;
                a.download = file.name;
                a.click();
                a.remove();
            }

            URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Dosya indirirken hata oluştu:', error);
        }
    }
);

export const getContractAction = createAsyncThunk(
    'contract/getContract',
    async (id: number, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setLoadContractTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error("Token is missing");
            }

            const response = await axios.get(`${EndPoints.contract}/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                }
            });

            const {code, message, resultValue, errorDetails} = response.data as ResponseDTO;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setLoadContractTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setContract(resultValue));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setLoadContractTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || "Unknown error";
            dispatch(setLoadContractTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);
