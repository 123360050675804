import React, {useEffect, useState} from "react";
import {
    Box,
    Fab,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {
    downloadLegalCaseFile,
    getLegalCaseAction,
    getLegalCaseFolderFilesAndFoldersAction,
    moveLegalCaseFileToFolderAction
} from "../../api/legalCase";
import {
    setFolderFilesAndFolders,
    setLegalCase,
    setLoadLegalCaseTaskStatus
} from "../../features/legal-case/legalCaseSlice";
import {CaseFileInfoDTO, CaseFolderDTO} from "../../api/dtos";
import AddIcon from "@mui/icons-material/Add";
import {AddFileToLegalCaseDialog} from "../add-file-to-legalcase/add-file-to-legalcase";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {DragHandler} from "./drag-handler";
import File from "mdi-material-ui/File";
import Folder from "mdi-material-ui/Folder";
import {AddFolderToLegalCaseDialog} from "../add-folder-to-legalcase/add-folder-to-legalcase";
import {CreateNewFolder} from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FolderAction from "../../actions/FolderAction";
import {DeleteFolderDialog} from "../delete-folder/deleteFolderDialog";

const handleDownloadCaseFile = (file: CaseFileInfoDTO, caseId: number, dispatch: any, e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(downloadLegalCaseFile({caseId, file}));
};

export interface LegalCaseFilesProps {
    id: number;
}

interface DocumentRowProps {
    file: CaseFileInfoDTO;
    caseId: number;
}

const DocumentRow: React.FC<DocumentRowProps> = ({caseId, file}) => {
    const dispatch: any = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [dragging, setDragging] = useState(false);
    const appendedNode = document.body;

    const handleDragStart = () => {
        setDragging(true);
    };

    const handleMouseUp = async (e: MouseEvent) => {
        const target = e.target as HTMLElement;
        const dropAreaElement = target.closest(".drop-area");
        if (dropAreaElement && dropAreaElement.hasAttribute("data-id") !== undefined) {
            const dataId = dropAreaElement.getAttribute("data-id");
            const folderId = dataId == null ? null : Number(dataId);

            const result = await dispatch(moveLegalCaseFileToFolderAction({
                caseId,
                folderId,
                fileId: file.id
            }));

            if (result.type === "legalcase/moveLegalCaseFileToFolder/fulfilled") {
                const params = new URLSearchParams(location.search);
                if (folderId) {
                    params.set("folderId", folderId.toString());
                } else {
                    params.delete("folderId");
                }

                navigate(`?${params.toString()}`);
            }
        }
        setDragging(false);
    };

    const innerDownloadCaseFile = (e: React.MouseEvent) => handleDownloadCaseFile(file, caseId, dispatch, e)

    return (
        <DragHandler
            dragging={dragging}
            active={true}
            onDragStart={handleDragStart}
            onMouseUp={handleMouseUp}
            appendedNode={appendedNode}>
            <div style={{display: "flex", alignItems: "center"}}>
                <span className="document"><File/></span>
                <a
                    href="#"
                    onClick={innerDownloadCaseFile}
                    style={{textDecoration: 'none', color: '#26a3ff'}}>
                    {file.name}
                </a>
            </div>
        </DragHandler>
    )
}

interface FolderInfo {
    folderId: number;
    folderName: string;
}

interface FolderRowProps {
    folderInfo: FolderInfo;
    caseId: number;
}

const FolderRow: React.FC<FolderRowProps> = ({folderInfo, caseId}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const goFolder = () => {
        dispatch(setFolderFilesAndFolders(null));
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('folderId', folderInfo.folderId.toString());
        navigate(`?${searchParams.toString()}`);
    }

    return (
        <div className="drop-area" data-id={folderInfo.folderId}>
            <div style={{display: "flex", alignItems: "center"}}>
                <span className="folder"><Folder/></span>
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        goFolder();
                    }}
                    style={{textDecoration: 'none', color: '#26a3ff'}}>
                    {folderInfo.folderName}
                </a>
            </div>
        </div>
    )
}

export const LegalCaseFiles: React.FC<LegalCaseFilesProps> = ({id}) => {
    const location = useLocation();
    const dispatch: any = useDispatch();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const folderId = searchParams.get('folderId') ? Number(searchParams.get('folderId')) : undefined;

    const legalCase = useSelector((state: AppState) => state.legalCase.legalCase);
    const folderFilesAndFolders = useSelector((state: AppState) => state.legalCase.folderFilesAndFolders);
    const [addFileDialogOpen, setAddFileDialogOpen] = useState(false);
    const [addFolderDialogOpen, setAddFolderDialogOpen] = useState(false);
    const [deleteFolderDialogOpen, setDeleteFolderDialogOpen] = useState(false);
    const [activeFolder, setActiveFolder] = useState<CaseFolderDTO | null>(null);

    const handleAddFileDialogOpen = () => setAddFileDialogOpen(true);
    const handleAddFileDialogClose = () => setAddFileDialogOpen(false);

    const handleAddFolderDialogOpen = () => setAddFolderDialogOpen(true);
    const handleAddFolderDialogClose = () => setAddFolderDialogOpen(false);

    useEffect(() => {
        dispatch(getLegalCaseAction(id));
        dispatch(getLegalCaseFolderFilesAndFoldersAction({caseId: id, folderId}));

        return () => {
            dispatch(setLegalCase(null));
            dispatch(setLoadLegalCaseTaskStatus(null));
            dispatch(setFolderFilesAndFolders(null));
        };
    }, [id, dispatch]);

    useEffect(() => {
        dispatch(getLegalCaseFolderFilesAndFoldersAction({caseId: id, folderId}));
    }, [folderId]);

    const handleFileAdd = () => dispatch(getLegalCaseFolderFilesAndFoldersAction({caseId: id, folderId}));
    const handleFolderAdd = () => dispatch(getLegalCaseFolderFilesAndFoldersAction({caseId: id, folderId}));

    const openAddFile = () => handleAddFileDialogOpen();

    const openAddFolder = () => handleAddFolderDialogOpen();

    const openDelete = (folder: CaseFolderDTO) => handleDeleteFolderDialogOpen(folder);

    if (!legalCase) {
        return null;
    }
    let folders = folderFilesAndFolders?.folders;
    let files = folderFilesAndFolders?.files;
    const folder = folderFilesAndFolders?.folder;

    files = files?.filter((it) => it.folder?.id === folderId);
    folders = folders?.filter((it) => it.parentFolderId === folderId)

    const goBack = () => {
        const searchParams = new URLSearchParams(location.search);
        if (folder?.parentFolderId) {
            searchParams.set('folderId', folder.parentFolderId.toString());
        } else {
            searchParams.delete('folderId');
        }
        navigate(`?${searchParams.toString()}`);
    }

    const handleDeleteFolderDialogOpen = (folder: CaseFolderDTO) => {
        setActiveFolder(folder);
        setDeleteFolderDialogOpen(true);
    };

    const handleDeleteFolderDialogClose = () => {
        setActiveFolder(null);
        setDeleteFolderDialogOpen(false);
    };

    const handleFolderDelete = () => {
        dispatch(getLegalCaseFolderFilesAndFoldersAction({caseId: id, folderId}));
        handleDeleteFolderDialogClose();
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 1,
                    m: 1,
                    borderRadius: 1,
                }}>
                {folder && (
                    <div className="drop-area" data-id={folder.parentFolderId || null}
                         style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <Fab
                            size="small"
                            color="default"
                            aria-label="back"
                            onClick={goBack}>
                            <ArrowBackIcon/>
                        </Fab>

                        <Typography variant="body1" sx={{ml: 5}}>
                            <strong>{folder.name}</strong>
                        </Typography>
                    </div>
                )}
                <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    sx={{marginLeft: "auto", marginBottom: 7}}
                    onClick={openAddFile}>
                    <AddIcon/>
                </Fab>
                <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    sx={{marginBottom: 7, marginLeft: 2}}
                    onClick={openAddFolder}>
                    <CreateNewFolder/>
                </Fab>
            </Box>

            {addFileDialogOpen && (
                <AddFileToLegalCaseDialog
                    legalCase={legalCase}
                    folderId={folderId}
                    onClose={handleAddFileDialogClose}
                    onFileAdded={handleFileAdd}/>
            )}

            {addFolderDialogOpen && (
                <AddFolderToLegalCaseDialog
                    legalCase={legalCase}
                    folderId={folderId}
                    onClose={handleAddFolderDialogClose}
                    onFolderAdded={handleFolderAdd}/>
            )}

            {deleteFolderDialogOpen && activeFolder && (
                <DeleteFolderDialog
                    handleClose={handleDeleteFolderDialogClose}
                    handleDelete={handleFolderDelete}
                    caseId={id}
                    folderId={activeFolder.id}
                    folderName={activeFolder.name}/>
            )}

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: "45%"}}>Dosya Adı</TableCell>
                            <TableCell style={{width: "45%"}}>Açıklama</TableCell>
                            <TableCell style={{width: "10%"}}>İşlemler</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {folders?.map((it) => (
                            <TableRow key={it.id}>
                                <TableCell>
                                    <FolderRow caseId={legalCase.id}
                                               folderInfo={{folderId: it.id, folderName: it.name}}/>
                                </TableCell>
                                <TableCell/>
                                <TableCell>
                                    <FolderAction
                                        folder={it}
                                        onDelete={openDelete}/>
                                </TableCell>
                            </TableRow>
                        ))}
                        {files?.map((it) => (
                            <TableRow key={it.id}>
                                <TableCell>
                                    <DocumentRow caseId={legalCase.id} file={it}/>
                                </TableCell>
                                <TableCell>{it.description}</TableCell>
                                <TableCell/>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
