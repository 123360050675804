import {useDispatch, useSelector} from "react-redux";
import UserLayout from "../components/UserLayout";
import Box from "@mui/material/Box";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Table, TableBody, TableHead, TableRow} from "@mui/material";
import {CreateLegalCaseDialog} from "./create-legal-case/createLegalCaseDialog";
import {AppState} from "../store";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {getLegalCasesAction} from "../api/legalCase";
import {Pagination} from "@mui/lab";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import {
    CaseType,
    getPresentableLegalCaseStatus,
    LegalCaseDTO,
    LegalCaseStatus,
    UserPermission,
    UserRole
} from "../api/dtos";
import moment from "moment";
import {setAllPaginatedLegalCases} from "../features/legal-case/legalCaseSlice";
import {LitigantType} from "../api/litigantType";
import TextField from "@mui/material/TextField";
import LegalCaseAction from "../actions/LegalCaseAction";
import {
    AddNoteLegalCasePopup,
    ChangeStatusLegalCasePopup,
    DeleteLegalCasePopup,
} from "./legal-case-actions/legal-case-actions";
import 'moment/locale/tr';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {AddFileToLegalCaseDialog} from "./add-file-to-legalcase/add-file-to-legalcase";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import * as XLSX from "xlsx-js-style";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {robotoRegularBase64} from '../shared/pdf-fonts';
import Button from "@mui/material/Button";
import {AsyncTaskStatusType} from "../shared/dtos";
import {CreateTaskDialog} from "./create-task/createTaskDialog";
import {adminOrHasPermission, formatDate, NO_DATA} from "../shared/utils";
import TableCell from "@mui/material/TableCell";
import {tr} from "date-fns/locale";

moment.locale('tr');

export const LegalCases = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let caseType = CaseType.Law;
    let title = "Hukuk Davaları";

    switch (location.pathname) {
        case '/sorusturmalar':
            caseType = CaseType.Investigation;
            title = "Soruşturmalar";
            break;
        case '/ceza-davalari':
            caseType = CaseType.Criminal;
            title = "Ceza Davaları";
            break;
        case '/icra-davalari':
            caseType = CaseType.Executive;
            title = "İcra Davaları";
            break;
        case '/tuketici-hakem-heyeti-davalari':
            caseType = CaseType.ConsumerArbitrationCommittee;
            title = "Tüketici Hakem Heyeti Davaları";
            break;
        case '/ihtarnameler':
            caseType = CaseType.PinkSlip;
            title = "İhtarnameler";
            break;
        case '/icra-takipleri':
            caseType = CaseType.ExecuteFollowUp;
            title = "İcra Takipleri";
            break;
        default:
            caseType = CaseType.Law;
            title = "Hukuk Davaları";
            break;
    }

    const [searchParams] = useSearchParams();

    const dispatch: any = useDispatch();
    const page = Number(searchParams.get('page') || "1");
    const [nameFilter, setNameFilter] = useState("");
    const [courtIdFilter, setCourtIdFilter] = useState<number | null>(null);
    const [fileNumberFilter, setFileNumberFilter] = useState("");
    const [corporateFileNumberFilter, setCorporateFileNumberFilter] = useState("");
    const [statusFilter, setStatusFilter] = useState<LegalCaseStatus | null>(null);
    const [courtHouseFilter, setCourtHouseFilter] = useState("");
    const [litigantFilter, setLitigantFilter] = useState("");
    const statusOptions = Object.values(LegalCaseStatus);
    const pageRef = useRef(page);
    const caseTypeRef = useRef<CaseType>(caseType);
    const nameFilterRef = useRef(nameFilter);
    const courtIdFilterRef = useRef(courtIdFilter);
    const fileNumberFilterRef = useRef(fileNumberFilter);
    const corporateFileNumberFilterRef = useRef(corporateFileNumberFilter);
    const statusFilterRef = useRef(statusFilter);
    const courtHouseFilterRef = useRef(courtHouseFilter);
    const litigantFilterRef = useRef(litigantFilter)
    const user = useSelector((state: AppState) => state.auth.userWithToken?.user);
    const loadLegalCasesTaskStatus = useSelector((state: AppState) => state.legalCase.loadLegalCasesTaskStatus)

    const loadLegalCases = useCallback(() => {
        const isAdminOrManager = user && [UserRole.Admin, UserRole.Manager].includes(user.role) || false;
        dispatch(getLegalCasesAction({
            nameFilter,
            typeFilter: caseType,
            courtIdFilter,
            statusFilter,
            fileNumberFilter,
            corporateFileNumberFilter,
            responsibleIdFilter: isAdminOrManager
                ? null
                : user?.id,
            litigantFilter,
            page
        }));
    }, [page, caseType, nameFilter, courtIdFilter, statusFilter, fileNumberFilter, corporateFileNumberFilter, courtHouseFilter, litigantFilter, dispatch]);

    useEffect(() => {
        loadLegalCases();
        return () => {
            dispatch(setAllPaginatedLegalCases(null));
        }
    }, []);

    useEffect(() => {
        if (caseTypeRef.current !== caseType) {
            dispatch(setAllPaginatedLegalCases(null));
            loadLegalCases();
        }
        caseTypeRef.current = caseType;
    }, [caseType]);

    useEffect(() => {
        if (pageRef.current !== page ||
            nameFilterRef.current !== nameFilter ||
            courtIdFilterRef.current !== courtIdFilter ||
            fileNumberFilterRef.current !== fileNumberFilter ||
            corporateFileNumberFilterRef.current !== corporateFileNumberFilter ||
            litigantFilterRef.current !== litigantFilter ||
            statusFilterRef.current !== statusFilter ||
            courtHouseFilterRef.current !== courtHouseFilter) {
            loadLegalCases();
            pageRef.current = page;
            nameFilterRef.current = nameFilter;
            courtIdFilterRef.current = courtIdFilter;
            fileNumberFilterRef.current = fileNumberFilter;
            statusFilterRef.current = statusFilter;
            courtHouseFilterRef.current = courtHouseFilter;
            litigantFilterRef.current = litigantFilter;
            corporateFileNumberFilterRef.current = corporateFileNumberFilter;
        }
    }, [page, nameFilter, courtIdFilter, fileNumberFilter, corporateFileNumberFilter, statusFilter, courtHouseFilter, litigantFilter]);

    useEffect(() => {
        setNameFilter("");
        setCourtIdFilter(null);
        setFileNumberFilter("");
        setStatusFilter(null);
        setCourtHouseFilter("");
        setLitigantFilter("")
    }, [location.pathname]);

    const [createLegalCaseDialogOpen, setCreateLegalCaseDialogOpen] = useState(false);
    const [addNoteDialogOpen, setAddNoteDialogOpen] = useState(false);
    const [addFileDialogOpen, setAddFileDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [changeStatusDialogOpen, setChangeStatusDialogOpen] = useState(false);
    const [addTaskDialogOpen, setAddTaskDialogOpen] = useState(false);
    const [activeLegalCase, setActiveLegalCase] = useState<LegalCaseDTO | null>(null);

    const allPaginatedLegalCases = useSelector((state: AppState) => state.legalCase.allPaginatedLegalCases);

    const handleCreateLegalCaseDialogOpen = () => setCreateLegalCaseDialogOpen(true);
    const handleCreateLegalCaseDialogClose = () => setCreateLegalCaseDialogOpen(false);

    const handleAddNoteDialogOpen = () => setAddNoteDialogOpen(true);
    const handleAddNoteDialogClose = () => setAddNoteDialogOpen(false);

    const handleAddFileDialogOpen = () => setAddFileDialogOpen(true);
    const handleAddFileDialogClose = () => setAddFileDialogOpen(false);

    const handleDeleteLegalCaseDialogOpen = () => setDeleteDialogOpen(true);
    const handleDeleteLegalCaseDialogClose = () => setDeleteDialogOpen(false);

    const handleChangeStatusDialogOpen = () => setChangeStatusDialogOpen(true);
    const handleChangeStatusDialogClose = () => setChangeStatusDialogOpen(false);

    const handleAddTaskDialogOpen = () => setAddTaskDialogOpen(true);
    const handleAddTaskDialogClose = () => setAddTaskDialogOpen(false);

    const handleChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', value.toString());
        navigate(`?${searchParams.toString()}`);
    };
    let filterLabel = "DAVACI/DAVALI"
    let prosecutorsLabel = "DAVACI";
    let reportedEntitiesVisible = true;
    let defendantsLabel = "DAVALI";
    let courtLabel = "MAHKEME";
    let fileNumberLabel = "DOSYA NUMARASI";
    let corporateFileNumberLabel = "DOSYA ÖZEL NUMARASI";
    let caseDateLabel = "DAVA TARİHİ";
    let courtHouseLabel = "ADLİYE";
    let courtHouseVisible = true;
    let committeeVisible = true;
    let notaryVisible = true;
    let investigationDateVisible = false;
    let investigationDateLabel = "SORUŞTURMA TARİHİ"
    let statementDateVisible = false;
    let statementDateLabel = "İFADE TARİHİ";
    let followTypeLabel = "TAKİP TÜRÜ";
    let followTypeVisible = false;
    let courtVisible = true;

    switch (caseType) {
        case CaseType.Criminal:// CEZA DAVALARI
            filterLabel = "MÜŞTEKİ/KATILAN/SANIK"
            prosecutorsLabel = "MÜŞTEKİ/KATILAN";
            reportedEntitiesVisible = false;
            defendantsLabel = "SANIK";
            courtHouseVisible = true;
            followTypeVisible = false;
            courtHouseLabel = "ADLİYE";
            courtLabel = "MAHKEME";
            fileNumberLabel = "DOSYA NUMARASI";
            caseDateLabel = "ŞİKAYET TARİHİ";
            statementDateVisible = false;
            investigationDateVisible = false;
            break;
        case CaseType.Executive:// İCRA DAVASI
            filterLabel = "ALACAKLI/BORÇLU"
            prosecutorsLabel = "ALACAKLI";
            reportedEntitiesVisible = false;
            courtHouseVisible = true;
            defendantsLabel = "BORÇLU";
            investigationDateVisible = false;
            followTypeVisible = false;
            statementDateVisible = false;
            courtLabel = "İCRA DAİRESİ";
            fileNumberLabel = "DOSYA NUMARASI";
            caseDateLabel = "TAKİP TARİHİ";
            courtVisible = true;
            break;
        case CaseType.Investigation:// SORUŞTURMALAR
            filterLabel = "MÜŞTEKİ/ŞÜPHELİ"
            prosecutorsLabel = "MÜŞTEKİ";
            reportedEntitiesVisible = false;
            courtHouseVisible = true;
            defendantsLabel = "ŞÜPHELİ";
            investigationDateVisible = false;
            followTypeVisible = false;
            courtLabel = "SAVCILIK";
            statementDateVisible = false;
            fileNumberLabel = "SORUŞTURMA NUMARASI";
            caseDateLabel = "ŞİKAYET TARİHİ";
            courtVisible = true;
            break;
        case CaseType.ConsumerArbitrationCommittee:
            filterLabel = "DAVACI/DAVALI"
            prosecutorsLabel = "DAVACI";
            reportedEntitiesVisible = false;
            courtHouseVisible = false;
            defendantsLabel = "DAVALI";
            investigationDateVisible = false;
            followTypeVisible = false;
            statementDateVisible = false;
            fileNumberLabel = "TÜKETİCİ HAKEM HEYETİ NUMARASI";
            caseDateLabel = "ŞİKAYET TARİHİ";
            courtVisible = false;
            break;
        case CaseType.PinkSlip:
            filterLabel = "DAVACI/DAVALI"
            prosecutorsLabel = "DAVACI";
            reportedEntitiesVisible = false;
            courtHouseVisible = false;
            defendantsLabel = "DAVALI";
            investigationDateVisible = false;
            followTypeVisible = false;
            statementDateVisible = false;
            fileNumberLabel = "İHTARNAME NUMARASI";
            caseDateLabel = "ŞİKAYET TARİHİ";
            courtVisible = false;
            break;
        case CaseType.ExecuteFollowUp:
            filterLabel = "DAVACI/DAVALI"
            prosecutorsLabel = "DAVACI";
            reportedEntitiesVisible = false;
            courtHouseVisible = false;
            defendantsLabel = "DAVALI";
            investigationDateVisible = false;
            followTypeVisible = false;
            statementDateVisible = false;
            fileNumberLabel = "İCRA TAKİP NUMARASI";
            caseDateLabel = "ŞİKAYET TARİHİ";
            courtVisible = false;
            break;
        default:
            prosecutorsLabel = "DAVACI";
            reportedEntitiesVisible = true;
            defendantsLabel = "DAVALI";
            courtHouseVisible = true;
            followTypeVisible = false;
            courtLabel = "MAHKEME";
            fileNumberLabel = "DOSYA NUMARASI";
            caseDateLabel = "DAVA TARİHİ";
            statementDateVisible = false;
            investigationDateVisible = false;
            break;
    }

    const reloadFirstPage = () => {
        if (page === 1) {
            const isAdminOrManager = user && [UserRole.Admin, UserRole.Manager].includes(user.role) || false;
            dispatch(getLegalCasesAction({
                nameFilter,
                typeFilter: caseType,
                courtIdFilter,
                statusFilter,
                fileNumberFilter,
                corporateFileNumberFilter,
                responsibleIdFilter: isAdminOrManager
                    ? null
                    : user?.id,
                litigantFilter,
                page
            }));
        } else {
            navigate("");
        }
    };

    const applyNameFilter = (e: { target: { value: any; }; }) => {
        const inputNameSearch = e.target.value;
        setNameFilter(inputNameSearch)
        navigate("?page=1");
    };

    const applyLitigantFilter = (e: { target: { value: string; }; }) => {
        const inputNameSearch = e.target.value;
        setLitigantFilter(inputNameSearch)
        navigate("?page=1");
    };

    const applyFileNumberFilter = (e: { target: { value: string; }; }) => {
        setFileNumberFilter(e.target.value);
        navigate("?page=1");
    };

    const applyCorporateFileNumber = (e: { target: { value: string; }; }) => {
        setCorporateFileNumberFilter(e.target.value);
        navigate("?page=1");
    };

    const applyStatusFilter = (e: { target: { value: string; }; }) => {
        const status = e.target.value === "" ? null : (e.target.value as LegalCaseStatus)
        setStatusFilter(status);
        navigate("?page=1");
    };

    /*const openAddNote = (legalCase: LegalCaseDTO) => {
        setActiveLegalCase(legalCase);
        setAddNoteDialogOpen(true);
    };*/

    const openAddFile = (legalCase: LegalCaseDTO) => {
        setActiveLegalCase(legalCase);
        setAddFileDialogOpen(true);
    };

    /*const openDelete = (legalCase: LegalCaseDTO) => {
         setActiveLegalCase(legalCase);
         setDeleteDialogOpen(true);
     };*/

    const openAddTask = (legalCase: LegalCaseDTO) => {
        setActiveLegalCase(legalCase);
        setAddTaskDialogOpen(true);
    };

    const openChangeStatus = (legalCase: LegalCaseDTO) => {
        setActiveLegalCase(legalCase);
        setChangeStatusDialogOpen(true);
    };

    const handleAdd = () => {
        setActiveLegalCase(null);
        handleCreateLegalCaseDialogClose();
    }

    const handleDelete = () => {
        setActiveLegalCase(null);
        handleDeleteLegalCaseDialogClose();
    }

    const handleAddNote = () => {
        setActiveLegalCase(null);
        handleAddNoteDialogClose();
    };

    const handleFileAdded = () => {
        setActiveLegalCase(null);
        handleAddFileDialogClose();
    }

    //TODO:tablodaki veri üzerinden doğrudan değişiklik yapıyoruz

    const handleChangeStatus = (legalCaseStatus: LegalCaseStatus) => {
        if (!allPaginatedLegalCases || !activeLegalCase) return;

        const updatedEntities = allPaginatedLegalCases.entity.map((legalCase) =>
            legalCase.id === activeLegalCase.id
                ? {...legalCase, status: legalCaseStatus}
                : legalCase
        );

        const updatedAllPaginatedLegalCases = {
            ...allPaginatedLegalCases,
            entity: updatedEntities,
        };

        dispatch(setAllPaginatedLegalCases(updatedAllPaginatedLegalCases));

        setNameFilter("");
        setCourtIdFilter(null);
        setFileNumberFilter("");
        setCorporateFileNumberFilter("");
        setStatusFilter(null);
        setCourtHouseFilter("");
        setLitigantFilter("");

        setActiveLegalCase(null);
        handleChangeStatusDialogClose();
    };

    const handleCreateTask = () => {
        setActiveLegalCase(null);
        handleAddTaskDialogClose();
        reloadFirstPage();
    }

    const handleClearStatus = () => setStatusFilter(null);

    const getCaseDetailUrl = (legalCase: LegalCaseDTO) => {
        switch (legalCase.type) {
            case CaseType.Law:
                return `/hukuk-davalari/${legalCase.id}/ayrintilar`;
            case CaseType.Investigation:
                return `/sorusturmalar/${legalCase.id}/ayrintilar`;
            case CaseType.Criminal :
                return `/ceza-davalari/${legalCase.id}/ayrintilar`;
            case CaseType.Executive:
                return `/icra-davalari/${legalCase.id}/ayrintilar`;
            case CaseType.ConsumerArbitrationCommittee:
                return `/tuketici-hakem-heyeti-davalari/${legalCase.id}/ayrintilar`;
            case CaseType.PinkSlip:
                return `/ihtarnameler/${legalCase.id}/ayrintilar`;
            case CaseType.ExecuteFollowUp:
                return `/icra-takipleri/${legalCase.id}/ayrintilar`;
            default:
                return `/hukuk-davalari/${legalCase.id}/ayrintilar`;
        }
    }

    const getColumnsForCaseType = (caseType: CaseType) => {
        switch (caseType) {
            case CaseType.Law:
                return ["KONU", "DAVACI", "DAVALI", "İHBAR OLUNANLAR", "DOSYA NUMARASI", "DOSYA ÖZEL NUMARASI", "ADLİYE", "MAHKEME", "DAVA TARİHİ", "DURUMU"];
            case CaseType.Investigation:
                return ["KONU", "MÜŞTEKİ", "ŞÜPHELİ", "SORUŞTURMA NUMARASI", "DOSYA ÖZEL NUMARASI", "ADLİYE", "SAVCILIK", "ŞİKAYET TARİHİ", "DURUMU"];
            case CaseType.Criminal:
                return ["KONU", "MÜŞTEKİ/KATILAN", "SANIK", "DOSYA NUMARASI", "DOSYA ÖZEL NUMARASI", "ADLİYE", "MAHKEME", "ŞİKAYET TARİHİ", "DURUMU"];
            case CaseType.Executive:
                return ["KONU", "ALACAKLI", "BORÇLU", "DOSYA NUMARASI", "DOSYA ÖZEL NUMARASI", "ADLİYE", "İCRA DAİRESİ", "TAKİP TARİHİ", "DURUMU"];
            case CaseType.ConsumerArbitrationCommittee:
                return ["KONU", "DAVACI", "DAVALI", "TÜKETİCİ HAKEM HEYETİ NUMARASI", "DOSYA ÖZEL NUMARASI", "HEYET", "ŞİKAYET TARİHİ", "DURUMU"];
            case CaseType.PinkSlip:
                return ["KONU", "DAVACI", "DAVALI", "İHTARNAME NUMARASI", "DOSYA ÖZEL NUMARASI", "NOTER", "ŞİKAYET TARİHİ", "DURUMU"];
            case CaseType.ExecuteFollowUp:
                return ["KONU", "DAVACILAR", "DAVALI", "İCRA TAKİP NUMARASI", "DOSYA ÖZEL NUMARASI", "İCRA DAİRESİ", "ŞİKAYET TARİHİ", "DURUMU"];
            default:
                return ["KONU", "DAVACI", "DAVALI", "DOSYA NUMARASI", "DOSYA ÖZEL NUMARASI", "ADLİYE", "MAHKEME", "DAVA TARİHİ", "DURUMU"];
        }
    };

    const exportToExcel = () => {
        const columns = getColumnsForCaseType(caseType);
        const headers = [columns];
        const worksheet = XLSX.utils.json_to_sheet(
            (allPaginatedLegalCases?.entity || []).map((legalCase) => {
                const rowData: any = {
                    "KONU": legalCase.name || NO_DATA,
                    "DAVACI": legalCase.prosecutors?.map((prosecutor) => {
                        switch (prosecutor.type as LitigantType) {
                            case LitigantType.Company:
                                return prosecutor.company?.value;
                            case LitigantType.Client:
                                return prosecutor.client?.value;
                        }
                    }).join(', '),
                    "DAVALI": legalCase.defendants?.map((defendant) => {
                        switch (defendant.type as LitigantType) {
                            case LitigantType.Company:
                                return defendant.company?.value;
                            case LitigantType.Client:
                                return defendant.client?.value;
                        }
                    }).join(', ')
                };

                if (caseType === CaseType.Law) {
                    rowData["İHBAR OLUNANLAR"] = legalCase.reportedEntities?.map((reportedEntity) => {
                        switch (reportedEntity.type as LitigantType) {
                            case LitigantType.Company:
                                return reportedEntity.company?.value;
                            case LitigantType.Client:
                                return reportedEntity.client?.value;
                        }
                    }).join(', ');
                }
                rowData["DOSYA NUMARASI"] = legalCase.fileNumber;
                rowData["DOSYA ÖZEL NUMARASI"] = legalCase.corporateFileNumber;

                if (caseType === CaseType.ConsumerArbitrationCommittee) {
                    rowData["HEYET"] = legalCase.committee?.name;
                } else if (caseType === CaseType.PinkSlip) {
                    rowData["NOTER"] = legalCase.notary?.name;
                } else if (caseType === CaseType.ExecuteFollowUp) {
                    rowData["İCRA DAİRESİ"] = legalCase.court?.name;
                } else {
                    rowData["ADLİYE"] = legalCase.court?.courtHouse.name;
                }

                if (![CaseType.ConsumerArbitrationCommittee, CaseType.PinkSlip, CaseType.ExecuteFollowUp].includes(caseType)) {
                    rowData["MAHKEME"] = legalCase.court?.name || NO_DATA;
                }

                switch (caseType) {
                    case CaseType.Law:
                        rowData["DAVA TARİHİ"] = legalCase.caseDate ? formatDate(legalCase.caseDate) : NO_DATA;
                        break;
                    case CaseType.PinkSlip:
                    case CaseType.ExecuteFollowUp:
                    case CaseType.ConsumerArbitrationCommittee:
                    case CaseType.Investigation:
                    case CaseType.Criminal:
                        rowData["ŞİKAYET TARİHİ"] = legalCase.caseDate ? formatDate(legalCase.caseDate) : NO_DATA;
                        break;
                    case CaseType.Executive:
                        rowData["TAKİP TARİHİ"] = legalCase.caseDate ? formatDate(legalCase.caseDate) : NO_DATA;
                        break;
                    default:
                        rowData["DAVA TARİHİ"] = legalCase.caseDate ? formatDate(legalCase.caseDate) : NO_DATA;
                        break;
                }

                rowData["DURUMU"] = getPresentableLegalCaseStatus(legalCase.status);

                return rowData;
            })
        );

        const workbook = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(worksheet, headers);

        const headerRow = headers[0];
        const columnWidths = headerRow.map(header => ({wch: header.length + 5}));
        worksheet['!cols'] = columnWidths;
        headerRow.forEach((header, index) => {
            const cellRef = XLSX.utils.encode_cell({r: 0, c: index});
            if (worksheet[cellRef]) {
                worksheet[cellRef].s = {
                    font: {bold: true},
                    fill: {
                        patternType: "solid",
                        fgColor: {rgb: "D3D3D3"}
                    }
                };
            }
        });

        XLSX.utils.book_append_sheet(workbook, worksheet, title);
        XLSX.writeFile(workbook, `${title.toLowerCase().replace(/\s/g, '_')}_listesi.xlsx`);
    };

    const exportToPDF = () => {
        const columns = getColumnsForCaseType(caseType);
        const doc = new jsPDF();

        doc.addFileToVFS('Roboto-Regular.ttf', robotoRegularBase64);
        doc.addFont('Roboto-Regular.ttf', 'Roboto', 'normal');
        doc.setFont('Roboto');

        doc.setFontSize(18);
        doc.text(title, 14, 20);

        const tableData = allPaginatedLegalCases?.entity?.map((legalCase) => {
            const rowData: any = [
                legalCase.name || NO_DATA,
                legalCase.prosecutors?.map((prosecutor) => {
                    switch (prosecutor.type as LitigantType) {
                        case LitigantType.Company:
                            return prosecutor.company?.value || NO_DATA;
                        case LitigantType.Client:
                            return prosecutor.client?.value || NO_DATA;
                    }
                }).join(', ') || NO_DATA,
                legalCase.defendants?.map((defendant) => {
                    switch (defendant.type as LitigantType) {
                        case LitigantType.Company:
                            return defendant.company?.value || NO_DATA;
                        case LitigantType.Client:
                            return defendant.client?.value || NO_DATA;
                    }
                }).join(', ') || NO_DATA,
            ];

            if (caseType === CaseType.Law) {
                rowData.push(
                    legalCase.reportedEntities?.map((reportedEntity) => {
                        switch (reportedEntity.type as LitigantType) {
                            case LitigantType.Company:
                                return reportedEntity.company?.value || NO_DATA;
                            case LitigantType.Client:
                                return reportedEntity.client?.value || NO_DATA;
                        }
                    }).join(', ') || NO_DATA);
            }

            rowData.push(
                legalCase.fileNumber || NO_DATA,
                legalCase.corporateFileNumber || NO_DATA
            )

            if (caseType === CaseType.ConsumerArbitrationCommittee) {
                rowData.push(legalCase.committee?.name);
            } else if (caseType === CaseType.PinkSlip) {
                rowData.push(legalCase.notary?.name);
            } else if (caseType === CaseType.ExecuteFollowUp) {
                rowData.push(legalCase.court?.name);
            } else {
                rowData.push(legalCase.court?.name || NO_DATA);
            }

            if (![CaseType.ConsumerArbitrationCommittee, CaseType.PinkSlip, CaseType.ExecuteFollowUp].includes(caseType)) {
                rowData.push(legalCase.court?.name || NO_DATA);
            }

            rowData.push(
                legalCase.caseDate ? formatDate(legalCase.caseDate) : NO_DATA,
                getPresentableLegalCaseStatus(legalCase.status)
            );

            return rowData;
        }) || [];

        autoTable(doc, {
            head: [columns],
            body: tableData,
            startY: 30,
            margin: {horizontal: 10},
            styles: {
                font: 'Roboto',
                cellPadding: 3,
                fontSize: 8,
                valign: 'middle',
                halign: 'left'
            },
        });

        doc.save(`${title.toLowerCase().replace(/\s/g, '_')}_listesi.pdf`);
    };

    const colSpan = 7 + [
        reportedEntitiesVisible,
        followTypeVisible,
        courtHouseVisible,
        courtVisible,
        investigationDateVisible,
        statementDateVisible,
        committeeVisible,
        notaryVisible
    ].filter(Boolean).length;

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>{title.toLocaleUpperCase("tr")}</h1>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                    }}>
                    <Box sx={{display: 'flex', gap: 2}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={exportToExcel}
                            sx={{fontSize: '0.875rem', padding: '8px 16px'}}>
                            Excel'e Dışa Aktar
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={exportToPDF}
                            sx={{fontSize: '0.875rem', padding: '8px 16px'}}>
                            PDF'e Dışa Aktar
                        </Button>
                    </Box>
                    {adminOrHasPermission(user, UserPermission.CASE_ADD) && (
                        <Fab
                            size="small"
                            color="primary"
                            aria-label="add"
                            sx={{marginLeft: "auto", marginBottom: 5}}
                            onClick={handleCreateLegalCaseDialogOpen}>
                            <AddIcon/>
                        </Fab>
                    )}
                </Box>
            </div>
            <Box sx={{overflowX: 'auto'}}>
                <Table sx={{minWidth: 800}} aria-label='criminal case table'>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: "11%"}}>
                                <TextField
                                    name="name"
                                    margin="dense"
                                    style={{minWidth: "210px"}}
                                    label="Konu"
                                    variant="outlined"
                                    size="small"
                                    value={nameFilter}
                                    onChange={applyNameFilter}/>
                            </TableCell>
                            <TableCell style={{width: "22%"}} colSpan={2}>
                                <TextField
                                    name="litigant"
                                    margin="dense"
                                    style={{minWidth: "420px"}}
                                    label={filterLabel}
                                    variant="outlined"
                                    size="small"
                                    value={litigantFilter}
                                    onChange={applyLitigantFilter}/>
                            </TableCell>
                            {reportedEntitiesVisible && (
                                <TableCell style={{width: "11%"}}/>
                            )}
                            {followTypeVisible && (
                                <TableCell style={{width: "11%"}}/>
                            )}
                            <TableCell style={{width: "11%"}}>
                                <TextField
                                    name="fileNumber"
                                    margin="dense"
                                    style={{minWidth: "220px"}}
                                    label={fileNumberLabel}
                                    variant="outlined"
                                    size="small"
                                    value={fileNumberFilter}
                                    onChange={applyFileNumberFilter}/>
                            </TableCell>
                            <TableCell style={{width: "11%"}}>
                                <TextField
                                    name="corporateFileNumber"
                                    margin="dense"
                                    style={{minWidth: "210px"}}
                                    label={corporateFileNumberLabel}
                                    variant="outlined"
                                    size="small"
                                    value={corporateFileNumberFilter}
                                    onChange={applyCorporateFileNumber}/>
                            </TableCell>
                            {courtHouseVisible && (
                                <TableCell style={{width: "11%"}}/>
                            )}
                            {courtVisible && (
                                <TableCell style={{width: "11%"}}/>
                            )}
                            {caseType === CaseType.ConsumerArbitrationCommittee && (
                                <TableCell style={{width: "11%"}}/>
                            )}
                            {caseType === CaseType.PinkSlip && (
                                <TableCell style={{width: "11%"}}/>
                            )}
                            {caseType === CaseType.ExecuteFollowUp && (
                                <TableCell style={{width: "11%"}}/>
                            )}
                            {investigationDateVisible && (
                                <TableCell style={{width: "11%"}}/>
                            )}
                            <TableCell style={{width: "11%"}}/>
                            {statementDateVisible && (
                                <TableCell style={{width: "11%"}}/>
                            )}
                            <TableCell>
                                <FormControl variant="outlined" size="small" fullWidth>
                                    <InputLabel id="status-label">Durumu</InputLabel>
                                    <Select
                                        style={{minWidth: "210px"}}
                                        labelId="status-label"
                                        label="Durumu"
                                        value={statusFilter || ""}
                                        onChange={applyStatusFilter}
                                        defaultValue=""
                                        endAdornment={
                                            statusFilter && (
                                                <InputAdornment position="end">
                                                    <IconButton onClick={handleClearStatus}>
                                                        <ClearIcon/>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }>
                                        {statusOptions.map((status) => (
                                            <MenuItem key={status} value={status}>
                                                {getPresentableLegalCaseStatus(status)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell style={{width: "11%"}}/>
                        </TableRow>
                        <TableRow>
                            <TableCell>KONU</TableCell>
                            <TableCell>{prosecutorsLabel}</TableCell>
                            <TableCell>{defendantsLabel}</TableCell>
                            {reportedEntitiesVisible && (
                                <TableCell>İHBAR OLUNANLAR</TableCell>
                            )}
                            {followTypeVisible && (
                                <TableCell>{followTypeLabel}</TableCell>
                            )}
                            <TableCell>{fileNumberLabel}</TableCell>
                            <TableCell>{corporateFileNumberLabel}</TableCell>
                            {courtHouseVisible && (
                                <TableCell>{courtHouseLabel}</TableCell>
                            )}
                            {courtVisible && (
                                <TableCell>{courtLabel}</TableCell>
                            )}
                            {caseType === CaseType.ConsumerArbitrationCommittee && (
                                <TableCell>HEYET</TableCell>
                            )}
                            {caseType === CaseType.PinkSlip && (
                                <TableCell>NOTER</TableCell>
                            )}
                            {caseType === CaseType.ExecuteFollowUp && (
                                <TableCell>İCRA DAİRESİ</TableCell>
                            )}
                            {investigationDateVisible && (
                                <TableCell>{investigationDateLabel}</TableCell>
                            )}
                            <TableCell>{caseDateLabel}</TableCell>
                            {statementDateVisible && (
                                <TableCell>{statementDateLabel}</TableCell>
                            )}
                            <TableCell>DURUMU</TableCell>
                            <TableCell>İŞLEMLER</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allPaginatedLegalCases?.entity.map((legalCase) => (
                            <TableRow key={legalCase.id}>
                                <TableCell>
                                    <Link to={getCaseDetailUrl(legalCase)} className="custom-link">
                                        {legalCase.name || NO_DATA}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    {legalCase.prosecutors?.map((prosecutor) => {
                                        switch (prosecutor.type as LitigantType) {
                                            case LitigantType.Company:
                                                return prosecutor.company?.value;
                                            case LitigantType.Client:
                                                return prosecutor.client?.value;
                                        }
                                    }).join(', ')}
                                </TableCell>
                                <TableCell>
                                    {legalCase.defendants?.map((defendant) => {
                                        switch (defendant.type as LitigantType) {
                                            case LitigantType.Company:
                                                return defendant.company?.value;
                                            case LitigantType.Client:
                                                return defendant.client?.value;
                                        }
                                    }).join(', ')}
                                </TableCell>
                                {reportedEntitiesVisible && (
                                    <TableCell>{legalCase.reportedEntities?.map((reportedEntity) => {
                                        switch (reportedEntity.type as LitigantType) {
                                            case LitigantType.Company:
                                                return reportedEntity.company?.value;
                                            case LitigantType.Client:
                                                return reportedEntity.client?.value;
                                        }
                                    }).join(', ')}</TableCell>
                                )}
                                {followTypeVisible && (
                                    <TableCell>-</TableCell>
                                )}
                                <TableCell>{legalCase.fileNumber}</TableCell>
                                <TableCell>{legalCase.corporateFileNumber}</TableCell>
                                {courtHouseVisible && (
                                    <TableCell>{legalCase.court?.courtHouse.name}</TableCell>
                                )}
                                {courtVisible && (
                                    <TableCell>{legalCase.court?.name}</TableCell>
                                )}
                                {caseType === CaseType.ConsumerArbitrationCommittee && (
                                    <TableCell>{legalCase.committee?.name || NO_DATA}</TableCell>
                                )}
                                {caseType === CaseType.PinkSlip && (
                                    <TableCell>{legalCase.notary?.name || NO_DATA}</TableCell>
                                )}
                                {caseType === CaseType.ExecuteFollowUp && (
                                    <TableCell>{legalCase.court?.name || NO_DATA}</TableCell>
                                )}
                                {investigationDateVisible && (
                                    <TableCell>
                                        {legalCase.investigationDate
                                            ? formatDate(legalCase.investigationDate)
                                            : NO_DATA
                                        }
                                    </TableCell>
                                )}
                                <TableCell>
                                    {legalCase.caseDate
                                        ? formatDate(legalCase.caseDate)
                                        : NO_DATA
                                    }
                                </TableCell>
                                {statementDateVisible && (
                                    <TableCell>
                                        -
                                    </TableCell>
                                )}
                                <TableCell>{getPresentableLegalCaseStatus(legalCase.status)}</TableCell>
                                <TableCell>
                                    <LegalCaseAction
                                        legalCase={legalCase}
                                        onAddFile={openAddFile}
                                        onChangeStatus={openChangeStatus}
                                        onAddTask={openAddTask}/>
                                </TableCell>
                            </TableRow>
                        ))}
                        {loadLegalCasesTaskStatus?.type !== AsyncTaskStatusType.Loading && allPaginatedLegalCases?.numberOfElements === 0 && (
                            <TableRow>
                                <TableCell colSpan={colSpan} style={{textAlign: "center"}}>
                                    {((): string => {
                                        switch (caseType) {
                                            case CaseType.ConsumerArbitrationCommittee:
                                                return "Tüketici Hakem Heyeti Davaları Bulunamadı";
                                            case CaseType.PinkSlip:
                                                return "İhtarnameler Bulunamadı";
                                            case CaseType.ExecuteFollowUp:
                                                return "İcra Takipleri Bulunamadı";
                                            default:
                                                return `${title} Bulunamadı`;
                                        }
                                    })()}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>
            {createLegalCaseDialogOpen && (
                <CreateLegalCaseDialog
                    page={page}
                    caseType={caseType}
                    handleClose={handleCreateLegalCaseDialogClose}
                    onAddLegalCase={handleAdd}/>
            )}
            {addNoteDialogOpen && activeLegalCase && (
                <AddNoteLegalCasePopup
                    legalCase={activeLegalCase}
                    onClose={handleAddNoteDialogClose}
                    onAddNoteLegalCase={handleAddNote}/>
            )}
            {addFileDialogOpen && activeLegalCase && (
                <AddFileToLegalCaseDialog
                    legalCase={activeLegalCase}
                    onClose={handleAddFileDialogClose}
                    onFileAdded={handleFileAdded}/>
            )}
            {deleteDialogOpen && activeLegalCase && (
                <DeleteLegalCasePopup
                    legalCase={activeLegalCase}
                    onClose={handleDeleteLegalCaseDialogClose}
                    onDeleteLegalCase={handleDelete}
                    page={page}/>
            )}
            {changeStatusDialogOpen && activeLegalCase && (
                <ChangeStatusLegalCasePopup
                    legalCase={activeLegalCase}
                    onClose={handleChangeStatusDialogClose}
                    onChangeStatus={handleChangeStatus}/>
            )}
            {addTaskDialogOpen && activeLegalCase && (
                <CreateTaskDialog
                    legalCaseId={activeLegalCase.id}
                    handleClose={handleAddTaskDialogClose}
                    handleCreateTask={handleCreateTask}/>
            )}
            {allPaginatedLegalCases?.totalNumberPages != null && allPaginatedLegalCases.totalNumberPages > 1 && (
                <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                    <Pagination
                        page={page}
                        count={allPaginatedLegalCases.totalNumberPages}
                        defaultPage={1}
                        onChange={handleChangePage}/>
                </div>
            )}
        </UserLayout>
    );
}
