import AccountCogOutline from 'mdi-material-ui/AccountCogOutline';
import {NavLink, VerticalNavItemsType} from '../theme/@core/layouts/types';
import BalanceIcon from '@mui/icons-material/Balance';
import GavelIcon from '@mui/icons-material/Gavel';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CurrencyLiraIcon from '@mui/icons-material/CurrencyLira';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import {
    CASE_PERMISSIONS,
    CONTRACT_PERMISSIONS,
    DASHBOARD_PERMISSIONS,
    SETTINGS_PERMISSIONS,
    TASK_PERMISSIONS,
    USER_PERMISSIONS,
    UserDTO,
    UserRole
} from "../api/dtos";
import {adminOrHasPermission} from "./utils";
import {ReactNode} from "react";

const navigation = (user: UserDTO | null): VerticalNavItemsType => {
    const hasCasePermissions = adminOrHasPermission(user, CASE_PERMISSIONS) || false;
    const hasTaskPermissions = adminOrHasPermission(user, TASK_PERMISSIONS) || false;
    const hasContractPermissions = adminOrHasPermission(user, CONTRACT_PERMISSIONS) || false;
    const hasSettingsPermissions = adminOrHasPermission(user, SETTINGS_PERMISSIONS) || false;
    const isAdminOrManager = user && [UserRole.Admin, UserRole.Manager].includes(user.role) || false;
    const hasUserPermissions = adminOrHasPermission(user, USER_PERMISSIONS) || false;
    const hasDashboardPermissions = adminOrHasPermission(user, DASHBOARD_PERMISSIONS) || false;

    const showCaseItems = ([
        {
            title: 'Davalar',
            icon: GavelIcon as unknown as ReactNode,
            path: '/',
            visible: isAdminOrManager || hasCasePermissions || hasDashboardPermissions
        },
        {
            title: 'Kullanıcı İstatistikleri',
            icon: GavelIcon as unknown as ReactNode,
            path: '/gosterge-paneli/kullanici-istatistikleri',
            visible: isAdminOrManager || hasDashboardPermissions
        },
        {
            title: 'Dava Durumları',
            icon: GavelIcon as unknown as ReactNode,
            path: '/gosterge-paneli/dava-durumlari',
            visible: isAdminOrManager || hasDashboardPermissions
        }
    ] as NavLink[]).filter((it) => it.visible === undefined || it.visible);

    const caseItems = ([
        {
            title: 'Tüm Dosyalar',
            icon: BalanceIcon as unknown as ReactNode,
            path: '/tum-dosyalar',
            openInNewTab: true,
            visible: isAdminOrManager || hasCasePermissions
        },
        {
            title: 'Hukuk Davaları',
            icon: BalanceIcon as unknown as ReactNode,
            path: '/hukuk-davalari',
            openInNewTab: true,
            visible: isAdminOrManager || hasCasePermissions
        },
        {
            title: 'Soruşturmalar',
            icon: BalanceIcon as unknown as ReactNode,
            path: '/sorusturmalar',
            openInNewTab: true,
            visible: isAdminOrManager || hasCasePermissions
        },
        {
            title: 'Ceza Davaları',
            icon: BalanceIcon as unknown as ReactNode,
            path: '/ceza-davalari',
            openInNewTab: true,
            visible: isAdminOrManager || hasCasePermissions
        },
        {
            title: 'İcra Davaları',
            icon: BalanceIcon as unknown as ReactNode,
            path: '/icra-davalari',
            openInNewTab: true,
            visible: isAdminOrManager || hasCasePermissions
        },
        {
            title: 'Tüketici Hakem Heyeti Davaları',
            icon: GavelIcon as unknown as ReactNode,
            path: '/tuketici-hakem-heyeti-davalari',
            visible: isAdminOrManager || hasCasePermissions
        },
        {
            title: 'İhtarnameler',
            icon: GavelIcon as unknown as ReactNode,
            path: '/ihtarnameler',
            visible: isAdminOrManager || hasCasePermissions
        },
        {
            title: '89/1 ve Maaş Haciz Müzekkereleri',
            icon: GavelIcon as unknown as ReactNode,
            path: '',
            visible: isAdminOrManager || hasCasePermissions
        },
        {
            title: 'İcra Takipleri',
            icon: GavelIcon as unknown as ReactNode,
            path: '/icra-takipleri',
            visible: isAdminOrManager || hasCasePermissions
        }
    ] as NavLink[]).filter((it) => it.visible === undefined || it.visible);

    const contractItems = ([
        {
            title: 'Sözleşmeler',
            icon: GavelIcon as unknown as ReactNode,
            path: '/sozlesmeler',
            visible: isAdminOrManager || hasContractPermissions,
        }
    ] as NavLink[]).filter((it) => it.visible === undefined || it.visible);

    const taskItems = ([
        {
            title: 'Görevler',
            icon: TaskAltIcon as unknown as ReactNode,
            path: '/gorevler',
            visible: isAdminOrManager || hasTaskPermissions,
        },
        {
            title: 'Görevlerim',
            icon: TaskAltIcon as unknown as ReactNode,
            path: '/gorevlerim',
            visible: isAdminOrManager || hasTaskPermissions,
        }
    ] as NavLink[]).filter((it) => it.visible === undefined || it.visible);

    const expedureItems = ([
        {
            title: 'Masraflar',
            icon: CurrencyLiraIcon as unknown as ReactNode,
            path: ''
        }
    ] as NavLink[]).filter((it) => it.visible === undefined || it.visible);

    const settingsItems = ([
        {
            title: 'Kullanıcılar',
            icon: AccountCogOutline as unknown as ReactNode,
            path: '/kullanicilar',
            visible: isAdminOrManager || hasSettingsPermissions || hasUserPermissions,
        },
        {
            title: 'E-Posta Ayarları',
            icon: AlternateEmailIcon as unknown as ReactNode,
            path: '/mail-ayarlari',
            visible: isAdminOrManager || hasSettingsPermissions,
        },
        {
            title: 'Fenerbahçe Şubeler',
            icon: BusinessIcon as unknown as ReactNode,
            path: '/fenerbahce-subeler',
            visible: isAdminOrManager || hasSettingsPermissions,
        },
        {
            title: 'Fenerbahçe İştirakler',
            icon: BusinessIcon as unknown as ReactNode,
            path: '/fenerbahce-istirakler',
            visible: isAdminOrManager || hasSettingsPermissions,
        },
        {
            title: 'Karşı Taraf',
            icon: BusinessIcon as unknown as ReactNode,
            path: '/karsi-taraf',
            visible: isAdminOrManager || hasSettingsPermissions,
        },
        {
            title: 'Adliyeler',
            icon: AccountBalanceIcon as unknown as ReactNode,
            path: '/adliyeler',
            visible: isAdminOrManager || hasSettingsPermissions,
        },
        {
            title: 'Mahkeme/Savcılık/İcra Dairesi',
            icon: AccountBalanceIcon as unknown as ReactNode,
            path: '/mahkemeler',
            visible: isAdminOrManager || hasSettingsPermissions,
        },
        {
            title: 'Tüketici Hakem Heyeti',
            icon: MyLocationIcon as unknown as ReactNode,
            path: '/tuketici-hakem-heyeti',
            visible: isAdminOrManager || hasSettingsPermissions,
        },
        {
            title: 'Noterler',
            icon: MyLocationIcon as unknown as ReactNode,
            path: '/noterler',
            visible: isAdminOrManager || hasSettingsPermissions,
        },
        {
            title: 'Şehirler',
            icon: MyLocationIcon as unknown as ReactNode,
            path: '/sehirler',
            visible: isAdminOrManager || hasSettingsPermissions,
        },
        {
            title: 'Uygulama Logları',
            icon: AccountCogOutline as unknown as ReactNode,
            path: '/log',
            visible: isAdminOrManager,
        }
    ] as NavLink[]).filter((it) => it.visible === undefined || it.visible);

    return [
        {
            sectionTitle: 'Gösterge Paneli',
            items: showCaseItems,
        },
        {
            sectionTitle: 'Davalar',
            items: caseItems
        },
        {
            sectionTitle: 'Sözleşmeler',
            items: contractItems
        },
        {
            sectionTitle: 'Görevler',
            items: taskItems
        },
        {
            sectionTitle: 'Müşavirlik',
            visible: false,
            items: expedureItems
        },
        {
            sectionTitle: 'Ayarlar',
            items: settingsItems
        },
    ];
};

export default navigation;
