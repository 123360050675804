import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import UserLayout from "../components/UserLayout";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import {Box, FormControl, InputLabel, MenuItem, Pagination, Select,} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {tr} from "date-fns/locale";
import {DateRangePicker} from "../components/DateRangePicker";
import {AppState} from "../store";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {getLogsAction} from "../api/appUser";
import {
    getPresentableLogLevel,
    getPresentableLogType,
    getPresentableUserAction,
    LogLevel,
    LogType,
    UserAction
} from "../api/dtos";
import moment from "moment";
import {Autocomplete} from "@mui/lab";
import TextField from "@mui/material/TextField";
import {NO_DATA} from "../shared/utils";

export default function Logs() {
    const dispatch: any = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get("page") || "1");

    const [type, setType] = useState<string | null>(null);
    const [level, setLevel] = useState<string | null>(null);
    const [userAction, setUserAction] = useState<string>("");
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const logs = useSelector((state: AppState) => state.appUser.logs);
    const totalNumberPages = logs?.totalNumberPages || 0;

    useEffect(() => {
        const params = {
            page,
            type: type || null,
            level: level || null,
            userAction: userAction || null,
            fromDate: startDate?.toISOString() || null,
            toDate: endDate?.toISOString() || null,
            systemAction: null,
            userId: null
        };

        dispatch(getLogsAction(params));
    }, [dispatch, page, type, level, userAction, startDate, endDate]);

    const handleChangePage = (_: React.ChangeEvent<unknown>, value: number) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("page", value.toString());
        navigate(`?${searchParams.toString()}`);
    };

    const applyTypeFilter = (e: { target: { value: string; }; }) => {
        const selectedType = e.target.value || null;
        setType(selectedType);
        navigate("?page=1");
    };

    const applyLevelFilter = (e: { target: { value: string; }; }) => {
        const selectedLevel = e.target.value || null;
        setLevel(selectedLevel);
        navigate("?page=1");
    };

    const applyUserActionFilter = (e: { target: { value: string; }; }) => {
        const inputTextSearch = e.target.value;
        setUserAction(inputTextSearch);
        navigate("?page=1");
    };

    const applyStartDateFilter = (date: Date | null) => {
        setStartDate(date);
        navigate("?page=1");
    };

    const applyEndDateFilter = (date: Date | null) => {
        setEndDate(date);
        navigate("?page=1");
    };

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>LOG KAYITLARI</h1>
                <Box sx={{overflowX: "auto"}}>
                    <Table sx={{minWidth: 800}} aria-label="log table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="typeFilter">TİP</InputLabel>
                                        <Select
                                            labelId="typeFilter"
                                            label="Tip"
                                            value={type || ""}
                                            onChange={applyTypeFilter}>
                                            <MenuItem value="">Tümü</MenuItem>
                                            {Object.values(LogType).map((type) => (
                                                <MenuItem key={type} value={type}>
                                                    {getPresentableLogType(type)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    <FormControl fullWidth size="small">
                                        <InputLabel id="levelFilter">SEVİYE</InputLabel>
                                        <Select
                                            labelId="levelFilter"
                                            value={level || ""}
                                            label="Seviye"
                                            onChange={applyLevelFilter}>
                                            <MenuItem value="">Tümü</MenuItem>
                                            {Object.values(LogLevel).map((level) => (
                                                <MenuItem key={level} value={level}>
                                                    {getPresentableLogLevel(level)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    <FormControl fullWidth size="small">
                                        <Autocomplete
                                            id="userActionFilter"
                                            options={Object.values(UserAction)}
                                            getOptionLabel={(option: UserAction | null) => option ? getPresentableUserAction(option) : ""}
                                            value={userAction ? userAction as UserAction : null}
                                            onChange={(event, newValue) => {
                                                setUserAction(newValue || "");
                                                navigate("?page=1");
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Kullanıcı Eylemi Ara" variant="outlined"/>
                                            )}/>
                                    </FormControl>
                                </TableCell>
                                <TableCell/>
                                <TableCell>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
                                        <DateRangePicker
                                            startDate={startDate}
                                            endDate={endDate}
                                            applyStartDateFilter={applyStartDateFilter}
                                            applyEndDateFilter={applyEndDateFilter}/>
                                    </LocalizationProvider>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Tip</TableCell>
                                <TableCell>Seviye</TableCell>
                                <TableCell>Mesaj</TableCell>
                                <TableCell>Kullanıcı</TableCell>
                                <TableCell>Oluşturulma Tarihi</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {logs?.entity.map((log) => (
                                <TableRow key={log.id}>
                                    <TableCell>{log.type ? getPresentableLogType(log.type) : NO_DATA}</TableCell>
                                    <TableCell>{log.level ? getPresentableLogLevel(log.level) : NO_DATA}</TableCell>
                                    <TableCell>{log.logMessage}</TableCell>
                                    <TableCell>{log.user.firstName}</TableCell>
                                    <TableCell>{moment(log.createDate * 1000).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
                {totalNumberPages > 1 && (
                    <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                        <Pagination
                            page={page}
                            count={totalNumberPages}
                            defaultPage={1}
                            onChange={handleChangePage}/>
                    </div>
                )}
            </div>
        </UserLayout>
    );
}
