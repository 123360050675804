import {Grid, Paper, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {ContractDTO, getPresentableContractStatus, getPresenTableContractType} from "../../api/dtos";
import {getContractAction} from "../../api/contract";
import {setContract, setLoadContractTaskStatus} from "../../features/contracts/contractSlice";
import {formatDate, NO_DATA} from "../../shared/utils";

export interface ContractDetailsProps {
    id: number;
}

const ContractDetailsRow: React.FC<{ label: string; value: string | number }> = ({label, value}) => (
    <Grid item xs={6} container spacing={1} alignItems="center">
        <Grid item>
            <Typography variant="subtitle1" fontWeight="bold" display="inline">
                {label}:
            </Typography>
        </Grid>
        <Grid item>
            <Typography variant="body1" display="inline">
                {value}
            </Typography>
        </Grid>
    </Grid>
);

export const ContractDetails: React.FC<ContractDetailsProps> = ({id}) => {
    const dispatch: any = useDispatch();
    const contract = useSelector((state: AppState) => state.contract.contract);

    useEffect(() => {
        dispatch(getContractAction(id));
        return () => {
            dispatch(setContract(null));
            dispatch(setLoadContractTaskStatus(null));
        };
    }, [id, dispatch]);

    if (!contract) {
        return null;
    }

    const {
        relatedSide,
        type,
        signDate,
        validUntil,
        relatedCompany,
        status
    } = contract as ContractDTO;

    return (
        <Paper elevation={3} sx={{p: 3}}>
            <Grid container spacing={2}>
                <ContractDetailsRow label="İlgili Taraf" value={relatedSide || NO_DATA}/>
                <ContractDetailsRow label="Sözleşme Türü" value={getPresenTableContractType(type)}/>
                <ContractDetailsRow label="Başlangıç Tarihi"
                                    value={signDate ? formatDate(signDate) : NO_DATA}/>
                <ContractDetailsRow label="Geçerlilik Tarihi"
                                    value={validUntil ? formatDate(validUntil) : "Süresiz"}/>
                <ContractDetailsRow label="İlgili Şube/İştirak" value={relatedCompany.name || NO_DATA}/>
                <ContractDetailsRow label="Durum" value={getPresentableContractStatus(status)}/>
            </Grid>
        </Paper>
    );
};
