import {LitigantType} from "./litigantType";

export enum UserRole {
    Admin = 'Admin',
    Manager = 'Manager',
    Company = 'Company',
    Member = 'Member',
}

export const getPresentableUserRole = (userRole: UserRole) => {
    switch (userRole) {
        case UserRole.Admin:
            return "Sistem Yöneticisi";
        case UserRole.Manager:
            return "Yönetici";
        case UserRole.Company:
            return "Şube/İştirak Kullanıcısı";
        case UserRole.Member:
            return "Kullanıcı";
    }
}

export enum CourtType {
    Law = 'Law',
    Investigation = 'Investigation',
    Criminal = 'Criminal',
    Executive = 'Executive'
}

export const getPresentableCourtType = (courtType: CourtType) => {
    switch (courtType) {
        case CourtType.Criminal:
            return "Ceza Mahkemesi";
        case CourtType.Executive:
            return "İcra Dairesi";
        case CourtType.Investigation:
            return "Savcılık";
        case CourtType.Law:
            return "Hukuk Mahkemesi";
    }
}

export enum LitigationType {
    Takip1 = 'Takip1',
    Takip2 = 'Takip2'
}

export enum CaseType {
    Law = 'Law',
    Investigation = 'Investigation',
    Criminal = 'Criminal',
    Executive = 'Executive',
    ConsumerArbitrationCommittee = 'ConsumerArbitrationCommittee',
    ExecuteFollowUp = 'ExecuteFollowUp',
    WageGarnishmentNotice = 'WageGarnishmentNotice',
    PinkSlip = 'PinkSlip',
};

export const getPresentableCaseType = (caseType: CaseType) => {
    switch (caseType) {
        case CaseType.Criminal:
            return "Ceza Davası";
        case CaseType.Executive:
            return "İcra Davası";
        case CaseType.Investigation:
            return "Soruşturma";
        case CaseType.Law:
            return "Hukuk Davası";
        case CaseType.ConsumerArbitrationCommittee:
            return "Tüketici Hakem Heyeti";
        case CaseType.ExecuteFollowUp:
            return "İcra Takibi";
        case CaseType.WageGarnishmentNotice:
            return "Maaş Kesme Bildirimi";
        case CaseType.PinkSlip:
            return "İhtarname";
    }
};

export enum LegalCaseStatus {
    Open = 'Open',
    Closed = 'Closed',
    Execution = 'Execution',
    NonProsecution = 'NonProsecution'
};

export const getPresentableLegalCaseStatus = (status: LegalCaseStatus) => {
    switch (status) {
        case LegalCaseStatus.Open:
            return "Açık - Derdest";
        case LegalCaseStatus.Closed:
            return "Kapalı";
        case LegalCaseStatus.Execution:
            return "İnfaz";
        case LegalCaseStatus.NonProsecution:
            return "Takipsizlik";
    }
};

export enum UserPermission {
    USER_ADD = "USER_ADD",
    USER_EDIT = "USER_EDIT",
    USER_LOCK = "USER_LOCK",
    USER_PASSWORD_RESET = "USER_PASSWORD_RESET",
    CASE_ADD = "CASE_ADD",
    CASE_DELETE = "CASE_DELETE",
    CASE_EDIT = "CASE_EDIT",
    CASE_STATUS_UPDATE = "CASE_STATUS_UPDATE",
    CASE_FILE_ADD = "CASE_FILE_ADD",
    CASE_FILE_DELETE = "CASE_FILE_DELETE",
    TASK_ADD = "TASK_ADD",
    TASK_DELETE = "TASK_DELETE",
    TASK_EDIT = "TASK_EDIT",
    TASK_STATUS_UPDATE = "TASK_STATUS_UPDATE",
    TASK_FILE_ADD = "TASK_FILE_ADD",
    TASK_FILE_DELETE = "TASK_FILE_DELETE",
    TASK_ASSIGNEE_UPDATE = "TASK_ASSIGNEE_UPDATE",
    CONTRACT_LIST = "CONTRACT_LIST",
    CONTRACT_ADD = "CONTRACT_ADD",
    CONTRACT_DELETE = "CONTRACT_DELETE",
    CONTRACT_EDIT = "CONTRACT_EDIT",
    CONTRACT_FILE_ADD = "CONTRACT_FILE_ADD",
    CONTRACT_FILE_DELETE = "CONTRACT_FILE_DELETE",
    SETTINGS_EDIT = "SETTINGS_EDIT",
    EXPENSE_LIST = "EXPENSE_LIST",
    EXPENSE_ADD = "EXPENSE_ADD",
    EXPENSE_DELETE = "EXPENSE_DELETE",
    EXPENSE_EDIT = "EXPENSE_EDIT",
    EXPENSE_FILE_ADD = "EXPENSE_FILE_ADD",
    EXPENSE_FILE_DELETE = "EXPENSE_FILE_DELETE",
    DASHBOARD_ACCESS = "DASHBOARD_ACCESS"
};

export const USER_PERMISSIONS = [
    UserPermission.USER_ADD,
    UserPermission.USER_EDIT,
    UserPermission.USER_LOCK,
];

export const CASE_PERMISSIONS = [
    UserPermission.CASE_ADD,
    UserPermission.CASE_FILE_ADD,
    UserPermission.CASE_FILE_DELETE,
    UserPermission.CASE_EDIT,
    UserPermission.CASE_STATUS_UPDATE,
    UserPermission.CASE_DELETE,
];

export const TASK_PERMISSIONS = [
    UserPermission.TASK_ADD,
    UserPermission.TASK_DELETE,
    UserPermission.TASK_EDIT,
    UserPermission.TASK_STATUS_UPDATE,
    UserPermission.TASK_FILE_ADD,
    UserPermission.TASK_FILE_DELETE,
    UserPermission.TASK_ASSIGNEE_UPDATE,
];

export const CONTRACT_PERMISSIONS = [
    UserPermission.CONTRACT_LIST,
    UserPermission.CONTRACT_ADD,
    UserPermission.CONTRACT_DELETE,
    UserPermission.CONTRACT_EDIT,
    UserPermission.CONTRACT_FILE_ADD,
    UserPermission.CONTRACT_FILE_DELETE,
];

export const SETTINGS_PERMISSIONS = [
    UserPermission.SETTINGS_EDIT,
];

export const DASHBOARD_PERMISSIONS = [
    UserPermission.DASHBOARD_ACCESS
];

export const EXPENSE_PERMISSIONS = [
    UserPermission.EXPENSE_LIST,
    UserPermission.EXPENSE_ADD,
    UserPermission.EXPENSE_DELETE,
    UserPermission.EXPENSE_EDIT,
    UserPermission.EXPENSE_FILE_ADD,
    UserPermission.EXPENSE_FILE_DELETE,
];

export const MEMBER_PERMISSIONS_LIST = [
    UserPermission.CASE_ADD,
    UserPermission.CASE_EDIT,
    UserPermission.CASE_FILE_ADD,
    UserPermission.CASE_STATUS_UPDATE,
    UserPermission.TASK_EDIT,
    UserPermission.TASK_STATUS_UPDATE,
    UserPermission.TASK_FILE_ADD
];

export const COMPANY_PERMISSIONS_LIST = [
    UserPermission.TASK_EDIT,
    UserPermission.TASK_STATUS_UPDATE,
    UserPermission.TASK_FILE_ADD,
    UserPermission.CONTRACT_LIST,
    UserPermission.CONTRACT_ADD,
    UserPermission.CONTRACT_DELETE,
    UserPermission.CONTRACT_EDIT,
    UserPermission.CONTRACT_FILE_ADD,
    UserPermission.CONTRACT_FILE_DELETE,
];

export const AllGroupedPermissions = [
    {
        title: "Kullanıcı İzinleri",
        options: USER_PERMISSIONS
    },
    {
        title: "Dava İzinleri",
        options: CASE_PERMISSIONS
    },
    {
        title: "Görev İzinleri",
        options: TASK_PERMISSIONS
    },
    {
        title: "Sözleşme İzinleri",
        options: CONTRACT_PERMISSIONS
    },
    {
        title: "Diğer İzinler",
        options: [
            ...SETTINGS_PERMISSIONS,
            ...DASHBOARD_PERMISSIONS
        ]
    },
    // {
    //     title: "Harcama İzinleri",
    //     options: EXPENSE_PERMISSIONS
    // },
];

export const getPresentableUserPermission = (permission: UserPermission) => {
    switch (permission) {
        case UserPermission.USER_ADD:
            return "Kullanıcı Ekleme";
        case UserPermission.USER_EDIT:
            return "Kullanıcı Düzenleme";
        case UserPermission.USER_LOCK:
            return "Kullanıcı Kilitleme";
        case UserPermission.USER_PASSWORD_RESET:
            return "Kullanıcı Şifre Sıfırlama";
        case UserPermission.CASE_ADD:
            return "Dava Ekleme";
        case UserPermission.CASE_DELETE:
            return "Dava Silme";
        case UserPermission.CASE_EDIT:
            return "Dava Düzenleme";
        case UserPermission.CASE_STATUS_UPDATE:
            return "Dava Durum Güncelleme";
        case UserPermission.CASE_FILE_ADD:
            return "Dava Dosya Ekleme";
        case UserPermission.CASE_FILE_DELETE:
            return "Dava Dosya Silme";
        case UserPermission.TASK_ADD:
            return "Görev Ekleme";
        case UserPermission.TASK_DELETE:
            return "Görev Silme";
        case UserPermission.TASK_EDIT:
            return "Görev Düzenleme";
        case UserPermission.TASK_STATUS_UPDATE:
            return "Görev Durum Güncelleme";
        case UserPermission.TASK_FILE_ADD:
            return "Görev Dosya Ekleme";
        case UserPermission.TASK_FILE_DELETE:
            return "Görev Dosya Silme";
        case UserPermission.TASK_ASSIGNEE_UPDATE:
            return "Görev Atama Güncelleme";
        case UserPermission.CONTRACT_LIST:
            return "Sözleşme Görme";
        case UserPermission.CONTRACT_ADD:
            return "Sözleşme Ekleme";
        case UserPermission.CONTRACT_DELETE:
            return "Sözleşme Silme";
        case UserPermission.CONTRACT_EDIT:
            return "Sözleşme Düzenleme";
        case UserPermission.CONTRACT_FILE_ADD:
            return "Sözleşme Dosya Ekleme";
        case UserPermission.CONTRACT_FILE_DELETE:
            return "Sözleşme Dosya Silme";
        case UserPermission.SETTINGS_EDIT:
            return "Konfigürasyon Düzenleme";
        case UserPermission.EXPENSE_LIST:
            return "Harcama Görme";
        case UserPermission.EXPENSE_ADD:
            return "Harcama Ekleme";
        case UserPermission.EXPENSE_DELETE:
            return "Harcama Silme";
        case UserPermission.EXPENSE_EDIT:
            return "Harcama Düzenleme";
        case UserPermission.EXPENSE_FILE_ADD:
            return "Harcama Dosya Ekleme";
        case UserPermission.EXPENSE_FILE_DELETE:
            return "Harcama Dosya Silme";
        case UserPermission.DASHBOARD_ACCESS:
            return "Gösterge Paneli Erişimi";
        default:
            return "Bilinmeyen Yetki";
    }
}

export interface AddUserDTO {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    passwordConfirmation: string;
    role: UserRole;
    associatedCompanyId: number | null;
    permissions: UserPermission[];
}

export interface EditUserDTO {
    firstName: string;
    lastName: string;
    role: UserRole;
    associatedCompanyId: number | null
}

export interface AddEditCompanyDTO {
    name: string;
    type: CompanyType
}

export interface SettingsLookupDTO {
    id: number;
    value: string;
}

export interface AppUserLookupDTO {
    id: number;
    value: string;
    associatedCompany: CompanyDTO | null;
}

export interface AddEditCourtDTO {
    name: string;
    type: CourtType;
    courtHouseId: number;
}

export interface AddEditCourtHouseDTO {
    name: string,
    cityPlate: number
}

export interface AddLitigantToCaseDTO {
    companyId: number | null;
    clientId: number | null;
    name: string | null;
    type: LitigantType;
}

export interface AddLegalCaseMetadata {
    file: File;
    metadata: AddLegalCaseDTO;
}

export interface AddLegalCaseDTO {
    name: string;
    prosecutorIds: AddLitigantToCaseDTO[];
    defendantIds: AddLitigantToCaseDTO[];
    reportedEntityIds: AddLitigantToCaseDTO[];
    courtId: number;
    notaryId: number | null;
    committeeId: number | null;
    type: CaseType;
    fileNumber: string;
    corporateFileNumber: string;
    caseDate: number | null;
    investigationDate: string | null;
    litigationType: LitigationType | null;
    trialDates: number[];
    notes: string | null;
    relatedCaseIds: number[];
    appeal: string | null;
    responsibleIds: number[];
}

export enum TaskType {
    LegalNotice = 'LegalNotice',
    General = 'General',
    LegalCase = 'LegalCase',
    Contract = 'Contract'
}

export const getPresentableTaskType = (taskType: TaskType) => {
    switch (taskType) {
        case TaskType.LegalNotice:
            return 'Tebligat Görevleri';
        case TaskType.General:
            return 'Genel Görevler';
        case TaskType.LegalCase:
            return 'Dava Görevleri';
        case TaskType.Contract:
            return 'Sözleşme Görevleri';
        default:
            return 'Bilinmeyen Görev Türü';
    }
}

export enum TaskPriority {
    Urgent = 'Urgent',
    High = 'High',
    Medium = 'Medium',
    Low = 'Low'
}

export const getPresentableTaskPriority = (taskPriority: TaskPriority) => {
    switch (taskPriority) {
        case TaskPriority.Urgent:
            return 'Acil';
        case TaskPriority.High:
            return 'Yüksek';
        case TaskPriority.Medium:
            return 'Orta';
        case TaskPriority.Low:
            return 'Düşük'
    }
}

export interface AddTaskDTO {
    type: TaskType;
    priority: TaskPriority;
    legalCaseId: number | null;
    contractId: number | null;
    assigneeIds: number[];
    title: string;
    description: string;
    dueDate: string | null;
    companyId: number | null;
}

export interface LitigantDTO {
    id: number;
    company: SettingsLookupDTO | null;
    client: SettingsLookupDTO | null;
    type: LitigantType
}

export interface CityDTO {
    plateId: number;
    name: string;
}

export interface EditCourtHouseDTO {
    name: string;
    cityId: number;
}

export interface CourtHouseDTO {
    id: number;
    name: string;
    city: CityDTO
}

export interface CourtDTO {
    id: number;
    name: string;
    courtHouse: CourtHouseDTO;
    type: CourtType;
}

export interface MinimalLegalCaseDTO {
    id: number;
    name: string;
    type: CaseType;
}

export enum TaskStatus {
    Created = 'Created',
    InProgress = 'InProgress',
    OverDue = 'OverDue',
    WaitingForReply = 'WaitingForReply',
    Completed = 'Completed'
}

export interface EditTaskStatusDTO {
    newStatus: TaskStatus;
    waitReason: string | null;
}

export const getPresentableTaskStatus = (status: TaskStatus) => {
    switch (status) {
        case TaskStatus.InProgress:
            return 'Devam Ediyor';
        case TaskStatus.Completed:
            return 'Tamamlandı';
        case TaskStatus.Created:
            return 'Oluşturuldu';
        case TaskStatus.OverDue:
            return 'Yanıt Bekleniyor';
        case TaskStatus.WaitingForReply:
            return 'Cevap İçin Bekleniyor';
    }
}

export interface UserInTaskDTO {
    id: number;
    firstName: string;
    lastName: string;
}

export interface TaskDTO {
    id: number;
    type: TaskType;
    title: string;
    description: string;
    status: TaskStatus;
    priority: TaskPriority;
    legalCase: MinimalLegalCaseDTO | null;
    assignees: UserInTaskDTO[];
    dueDate: number | null;
    createdAt: number | null;
    waitReason: string | null;
    createdBy: UserInTaskDTO;
}

export interface EditTaskAssigneesDTO {
    assigneeIds: number[];
}

export interface EditTaskDTO {
    title: string;
    description: string;
    priority: TaskPriority;
    dueDate: number | null;
}

export interface ExpenseDTO {
    id: number;
    name: string;
    currency: string;
    amount: any;
    relatedTask: TaskDTO | null;
    relatedLegalCase: MinimalLegalCaseDTO | null
}

export interface UserInLegalCaseDTO {
    id: number;
    firstName: string;
    lastName: string;
}

export enum LegalNoticeStatus {
    Created = 'Created',
    InProgress = 'InProgress',
    Completed = 'Completed'
}

export const getPresentableLegalNoticeStatus = (status: LegalNoticeStatus) => {
    switch (status) {
        case LegalNoticeStatus.Created:
            return "Oluşturuldu";
        case LegalNoticeStatus.InProgress:
            return "Devam Ediyor";
        case LegalNoticeStatus.Completed:
            return "Tamamlandı";
    }
}

export interface CreateLegalNoticeDTO {
    title: String;
    description: string;
    arrivalDate: string;
    dueDate: string;
    legalCaseId: number;
    assigneeIds: number[];
}

export interface LegalCaseInLegalNoticeDTO {
    id: number;
    name: string;
    type: CaseType;
}

export interface LegalNoticeDTO {
    id: number;
    title: string;
    description: string;
    status: LegalNoticeStatus;
    arrivalDate: number;
    dueDate: number;
    assignees: UserInTaskDTO[];
    legalCase: LegalCaseInLegalNoticeDTO;
}

export interface LegalCaseDTO {
    id: number;
    name: string;
    prosecutors: LitigantDTO[];
    defendants: LitigantDTO[];
    reportedEntities: LitigantDTO[];
    court: CourtDTO | null;
    notary: NotaryDTO | null;
    committee: ConsumerArbitrationCommitteeDTO | null;
    type: CaseType;
    status: LegalCaseStatus;
    fileNumber: string;
    caseDate: number | null;
    investigationDate: number | null;
    litigationType: LitigationType | null;
    trialDates: number[];
    files: CaseFileInfoDTO[];
    notes: string | null;
    relatedCases: MinimalLegalCaseDTO[];
    appeal: string | null;
    responsibles: UserInLegalCaseDTO[];
    corporateFileNumber: string;
    createdAt: number;
    createdBy: UserInLegalCaseDTO;
}

export const getPresentableLegalCase = (legalCaseType: CaseType) => {
    switch (legalCaseType) {
        case CaseType.Criminal:
            return "Ceza Davası";
        case CaseType.Executive:
            return "İcra Davası";
        case CaseType.Investigation:
            return "Soruşturma";
        case CaseType.Law:
            return "Hukuk Davası";
    }
}

export interface PaginatedEntityList<T> {
    totalNumberPages: number;
    totalElements: number;
    numberOfElements: number;
    entity: T[];
}

export interface UserDTO {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    locked: boolean;
    deleted: boolean;
    role: UserRole;
    associatedCompany: CompanyDTO | null;
    mfaEnabled: boolean;
    permissions: UserPermission[];
}

export interface CompanyDTO {
    id: number;
    name: string;
    type: CompanyType;
}

export interface LegalCaseStatisticDTO {
    name: string;
    count: number;
}

export enum CompanyType {
    Branch = 'Branch',
    Subsidiary = 'Subsidiary'
}

export const getPresentableCompanyType = (type: CompanyType) => {
    switch (type) {
        case CompanyType.Branch:
            return "Şubeler";
        case CompanyType.Subsidiary:
            return "İştirakler";
    }
}

export interface LegalCaseStatisticsDTO {
    companyProsecutorStatistics: LegalCaseStatisticDTO[];
    companyProsecutorOpenStatistics: LegalCaseStatisticDTO[];
    companyDefendantStatistics: LegalCaseStatisticDTO[];
    companyDefendantOpenStatistics: LegalCaseStatisticDTO[];
    companyReportedEntityStatistics: LegalCaseStatisticDTO[];
    companyReportedEntityOpenStatistics: LegalCaseStatisticDTO[];
    statisticsByUser: LegalCaseStatisticDTO[];
    statisticsByStatus: LegalCaseStatisticDTO[];
    statisticsByUserOpen: LegalCaseStatisticDTO[];
}

export interface AddEditMailSettingsDTO {
    propMap: { [key: string]: string };
}

export interface PasswordResetDTO {
    oldPassword: string | null;
    password: string;
    passwordConfirmation: string;
}

export interface ContractDTO {
    id: number;
    type: ContractType;
    status: ContractStatus;
    relatedSide: string;
    signDate: number;
    validUntil?: number;
    relatedCompany: CompanyDTO;
    createdAt: number;
    createdBy: UserInContractDTO
}

export interface UserInContractDTO {
    id: number;
    firstName: string;
    lastName: string;
}

export enum ContractType {
    Sports = 'Sports',
    Termination = 'Termination',
    Sponsor = 'Sponsor'
}

export const getPresenTableContractType = (contractType: ContractType) => {
    switch (contractType) {
        case ContractType.Sports:
            return 'Sporcu Sözleşmesi';
        case ContractType.Sponsor:
            return 'Sponsor';
        case ContractType.Termination:
            return 'Fesih';
    }
}

export enum ContractStatus {
    Signed = 'Signed',
    Expired = 'Expired'
}

export const getPresentableContractStatus = (contractStatus: ContractStatus) => {
    switch (contractStatus) {
        case ContractStatus.Signed:
            return 'Kayıtlı'
        case ContractStatus.Expired:
            return 'Tamamlanmış'
    }
}

export interface AddContractDTO {
    type: ContractType;
    status: ContractStatus;
    relatedSide: string;
    signDate: string;
    validUntil?: string;
    relatedCompanyId: number;
}

export interface AddNoteToLegalCaseDTO {
    note: string;
}

export interface EditContractDTO {
    type: ContractType,
    relatedSide: string,
    signDate: number,
    validUntil: number,
}

export interface UserInNotificationDTO {
    id: number;
    firstName: string;
    lastName: string;
}

export interface TaskInNotificationDTO {
    id: number;
    title: string;
}

export interface NotificationDTO {
    id: number;
    title: string;
    description: string;
    relatedTask: TaskInNotificationDTO | null;
    user: UserInNotificationDTO;
    read: boolean;
}

export interface AddCaseFileToLegalCaseDTO {
    description: string;
    folderId: number | null;
}

export interface AddCaseFileToLegalCaseMetadata {
    file: File;
    metadata: AddCaseFileToLegalCaseDTO;
}

export interface AddTaskFileDTO {
    description: string;
    folderId: number | null;
}

export interface AddTaskFileMetadata {
    file: File;
    metadata: AddCaseFileToLegalCaseDTO;
}

export interface AddContractFileDTO {
    description: string;
    folderId: number | null;
}

export interface AddContractFileMetadata {
    file: File;
    metadata: AddCaseFileToLegalCaseDTO;
}

export interface CaseFileInfoDTO {
    id: number;
    name: string;
    relativeDirPath: string;
    folder?: CaseFolderDTO;
    contentType: string;
    description: string;
    ready: boolean;
}

export interface CreateFolderDTO {
    parentFolderId: number | null;
    name: string;
}

export interface CaseFolderDTO {
    id: number;
    name: string;
    parentFolderId?: number;
}

export interface FolderFilesAndFoldersDTO {
    folder: CaseFolderDTO;
    files: CaseFileInfoDTO[];
    folders: CaseFolderDTO[];
}

export enum ClientType {
    Person = "Person",
    Institution = "Institution",
}

export const getPresentableClientType = (clientType: ClientType) => {
    switch (clientType) {
        case ClientType.Person:
            return 'Kişi'
        case ClientType.Institution:
            return 'Kurum'
    }
}

export interface AddClientDTO {
    name: string;
    type: ClientType;
    tckn?: string;
    vkn?: string;
    address: string;
    city: number;
}

export interface EditClientDTO {
    name: string;
    tckn?: string;
    vkn?: string;
    address: string;
    city: number;
}

export interface EditUserPermissionsDTO {
    permissions: UserPermission[];
    role: UserRole;
}

export enum LogType {
    ANY = 'ANY',
    USER_ACTION = 'USER_ACTION',
    SYSTEM_ACTION = 'SYSTEM_ACTION',
    RESET_OWN_PASSWORD = 'RESET_OWN_PASSWORD',
    GET_NOTIFICATIONS = 'GET_NOTIFICATIONS',
    MARK_NOTIFICATION = 'MARK_NOTIFICATION',
    DELETE_NOTIFICATION = 'DELETE_NOTIFICATION',
}

export const getPresentableLogType = (logType: LogType) => {
    switch (logType) {
        case LogType.ANY:
            return 'Herhangi Bir İşlem';
        case LogType.USER_ACTION:
            return 'Kullanıcı İşlemi';
        case LogType.SYSTEM_ACTION:
            return 'Sistem İşlemi';
        case LogType.RESET_OWN_PASSWORD:
            return 'Kendi Şifresini Sıfırla';
        case LogType.GET_NOTIFICATIONS:
            return 'Bildirimleri Getir';
        case LogType.MARK_NOTIFICATION:
            return 'Bildirimi İşaretle';
        case LogType.DELETE_NOTIFICATION:
            return 'Bildirimi Sil';
    }
}

export interface AppUserInLogDTO {
    id: number;
    firstName: string;
    lastName: string;
}

export enum SystemAction {
    SEND_NOTIFICATION = 'SEND_NOTIFICATION',
}

export enum UserAction {
    USER_ADD = 'USER_ADD',
    USER_EDIT = 'USER_EDIT',
    USER_LOCK = 'USER_LOCK',
    USER_LIST = 'USER_LIST',
    USER_PASSWORD_RESET = 'USER_PASSWORD_RESET',
    USER_OWN_PASSWORD_RESET = 'USER_OWN_PASSWORD_RESET',
    USER_ENABLE_MFA = 'USER_ENABLE_MFA',
    USER_GENERATE_MFA_SECRET = 'USER_GENERATE_MFA_SECRET',
    USER_DELETE = 'USER_DELETE',
    USER_PERMISSION_EDIT = 'USER_PERMISSION_EDIT',
    SETTINGS_EDIT = 'SETTINGS_EDIT',
    SETTINGS_DELETE = 'SETTINGS_DELETE',
    SETTINGS_LIST = 'SETTINGS_LIST',
    CASE_ADD = 'CASE_ADD',
    CASE_EDIT = 'CASE_EDIT',
    CASE_STATUS_UPDATE = 'CASE_STATUS_UPDATE',
    CASE_FILE_ADD = 'CASE_FILE_ADD',
    CASE_FILE_DELETE = 'CASE_FILE_DELETE',
    CASE_FILE_DOWNLOAD = 'CASE_FILE_DOWNLOAD',
    CASE_FILE_MOVE = 'CASE_FILE_MOVE',
    CASE_FILE_LIST = 'CASE_FILE_LIST',
    CASE_DELETE = 'CASE_DELETE',
    CASE_LIST = 'CASE_LIST',
    CASE_NOTE_ADD = 'CASE_NOTE_ADD',
    CASE_CREATE_FOLDER = 'CASE_CREATE_FOLDER',
    CASE_DELETE_FOLDER = 'CASE_DELETE_FOLDER',
    TASK_ADD = 'TASK_ADD',
    TASK_EDIT = 'TASK_EDIT',
    TASK_STATUS_UPDATE = 'TASK_STATUS_UPDATE',
    TASK_ASSIGNEE_UPDATE = 'TASK_ASSIGNEE_UPDATE',
    TASK_FILE_ADD = 'TASK_FILE_ADD',
    TASK_FILE_DELETE = 'TASK_FILE_DELETE',
    TASK_DELETE = 'TASK_DELETE',
    CONTRACT_ADD = 'CONTRACT_ADD',
    CONTRACT_EDIT = 'CONTRACT_EDIT',
    CONTRACT_DELETE = 'CONTRACT_DELETE',
    CONTRACT_FILE_ADD = 'CONTRACT_FILE_ADD',
    CONTRACT_FILE_DELETE = 'CONTRACT_FILE_DELETE',
    CONTRACT_LIST = 'CONTRACT_LIST',
    EXPENSE_ADD = 'EXPENSE_ADD',
    EXPENSE_EDIT = 'EXPENSE_EDIT',
    EXPENSE_DELETE = 'EXPENSE_DELETE',
    EXPENSE_FILE_ADD = 'EXPENSE_FILE_ADD',
    EXPENSE_FILE_DELETE = 'EXPENSE_FILE_DELETE',
    EXPENSE_LIST = 'EXPENSE_LIST',
    NOTIFICATION_LIST = 'NOTIFICATION_LIST',
    NOTIFICATION_MARK = 'NOTIFICATION_MARK',
    NOTIFICATION_DELETE = 'NOTIFICATION_DELETE',
    LOG_LIST = 'LOG_LIST',
}

export const getPresentableUserAction = (userAction: UserAction) => {
    switch (userAction) {
        case UserAction.USER_ADD:
            return 'Kullanıcı Ekle';
        case UserAction.USER_EDIT:
            return 'Kullanıcı Düzenle';
        case UserAction.USER_LOCK:
            return 'Kullanıcı Kilitle';
        case UserAction.USER_LIST:
            return 'Kullanıcı Listesi';
        case UserAction.USER_PASSWORD_RESET:
            return 'Kullanıcı Şifresini Sıfırla';
        case UserAction.USER_OWN_PASSWORD_RESET:
            return 'Kendi Şifresini Sıfırla';
        case UserAction.USER_ENABLE_MFA:
            return 'MFA (Çok Faktörlü Kimlik Doğrulama) Etkinleştir';
        case UserAction.USER_GENERATE_MFA_SECRET:
            return 'MFA Gizli Anahtar Oluştur';
        case UserAction.USER_DELETE:
            return 'Kullanıcı Sil';
        case UserAction.USER_PERMISSION_EDIT:
            return 'Kullanıcı Yetkilerini Düzenle';
        case UserAction.SETTINGS_EDIT:
            return 'Ayarları Düzenle';
        case UserAction.SETTINGS_DELETE:
            return 'Ayarları Sil';
        case UserAction.SETTINGS_LIST:
            return 'Ayarlar Listesi';
        case UserAction.CASE_ADD:
            return 'Dosya Ekle';
        case UserAction.CASE_EDIT:
            return 'Dosya Düzenle';
        case UserAction.CASE_STATUS_UPDATE:
            return 'Dosya Durumunu Güncelle';
        case UserAction.CASE_FILE_ADD:
            return 'Dosya Belgesi Ekle';
        case UserAction.CASE_FILE_DELETE:
            return 'Dosya Belgesini Sil';
        case UserAction.CASE_FILE_DOWNLOAD:
            return 'Dosya Belgesini İndir';
        case UserAction.CASE_FILE_MOVE:
            return 'Dosya Belgesini Taşı';
        case UserAction.CASE_FILE_LIST:
            return 'Dosya Belgeleri Listesi';
        case UserAction.CASE_DELETE:
            return 'Dosyayı Sil';
        case UserAction.CASE_LIST:
            return 'Dosyalar Listesi';
        case UserAction.CASE_NOTE_ADD:
            return 'Dosya Notu Ekle';
        case UserAction.CASE_CREATE_FOLDER:
            return 'Klasör Oluştur';
        case UserAction.CASE_DELETE_FOLDER:
            return 'Klasörü Sil';
        case UserAction.TASK_ADD:
            return 'Görev Ekle';
        case UserAction.TASK_EDIT:
            return 'Görev Düzenle';
        case UserAction.TASK_STATUS_UPDATE:
            return 'Görev Durumunu Güncelle';
        case UserAction.TASK_ASSIGNEE_UPDATE:
            return 'Görev Atamasını Güncelle';
        case UserAction.TASK_FILE_ADD:
            return 'Görev Dosyası Ekle';
        case UserAction.TASK_FILE_DELETE:
            return 'Görev Dosyasını Sil';
        case UserAction.TASK_DELETE:
            return 'Görevi Sil';
        case UserAction.CONTRACT_ADD:
            return 'Sözleşme Ekle';
        case UserAction.CONTRACT_EDIT:
            return 'Sözleşme Düzenle';
        case UserAction.CONTRACT_DELETE:
            return 'Sözleşmeyi Sil';
        case UserAction.CONTRACT_FILE_ADD:
            return 'Sözleşme Dosyası Ekle';
        case UserAction.CONTRACT_FILE_DELETE:
            return 'Sözleşme Dosyasını Sil';
        case UserAction.CONTRACT_LIST:
            return 'Sözleşmeler Listesi';
        case UserAction.EXPENSE_ADD:
            return 'Gider Ekle';
        case UserAction.EXPENSE_EDIT:
            return 'Gider Düzenle';
        case UserAction.EXPENSE_DELETE:
            return 'Gider Sil';
        case UserAction.EXPENSE_FILE_ADD:
            return 'Gider Dosyası Ekle';
        case UserAction.EXPENSE_FILE_DELETE:
            return 'Gider Dosyasını Sil';
        case UserAction.EXPENSE_LIST:
            return 'Giderler Listesi';
        case UserAction.NOTIFICATION_LIST:
            return 'Bildirim Listesi';
        case UserAction.NOTIFICATION_MARK:
            return 'Bildirimi İşaretle';
        case UserAction.NOTIFICATION_DELETE:
            return 'Bildirimi Sil';
        case UserAction.LOG_LIST:
            return 'Log Kayıtlarını Görüntüle';
        default:
            return 'Bilinmeyen Eylem';
    }
}

export enum LogLevel {
    TRACE = 'TRACE',
    DEBUG = 'DEBUG',
    INFO = 'INFO',
    WARN = 'WARN',
    ERROR = 'ERROR',
    FATAL = 'FATAL',
    OFF = 'OFF',
}

export const getPresentableLogLevel = (logLevel: LogLevel) => {
    switch (logLevel) {
        case LogLevel.TRACE:
            return 'İzleme';
        case LogLevel.DEBUG:
            return 'Hata Ayıklama';
        case LogLevel.INFO:
            return 'Bilgi';
        case LogLevel.WARN:
            return 'Uyarı';
        case LogLevel.ERROR:
            return 'Hata';
        case LogLevel.FATAL:
            return 'Kritik Hata';
        case LogLevel.OFF:
            return 'Kapalı';
        default:
            return 'Bilinmeyen Seviye';
    }
};

export interface AppLogDTO {
    id: number;
    type: LogType | null;
    level: LogLevel | null;
    userAction: UserAction | null;
    systemAction: SystemAction | null;
    logMessage: string;
    user: AppUserInLogDTO;
    createDate: number;
}

export interface ClientDTO {
    id: number;
    name: string;
    type: ClientType;
    tckn: string | null;
    vkn: string | null;
    address: string;
    city: CityDTO;
}

export interface AddEditNotaryDTO {
    name: string;
    cityId: number;
}

export interface NotaryDTO {
    id: number;
    name: string;
    city: CityDTO;
}

export interface AddEditCommitteeDTO {
    name: string;
    cityId: number;
}

export interface ConsumerArbitrationCommitteeDTO {
    id: number;
    name: string;
    city: CityDTO;
}

export interface SearchResultDTO {
    id: number;
    name: string;
    prosecutors: LitigantDTO[];
    defendants: LitigantDTO[];
    reportedEntities: LitigantDTO[];
    type: CaseType;
    status: LegalCaseStatus;
    fileNumber: string;
    corporateFileNumber: string;
    caseDate: string | null;
}
