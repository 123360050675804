import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";
import {createCourtHouseAction, getCourtHousesAction, getLoadCitiesAction} from "../../api/settings";
import FormControl from "@mui/material/FormControl";
import {TextFieldVariants} from "@mui/material/TextField/TextField";
import ErrorText from "../../components/ErrorText";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import {setCreateHouseTaskStatus, setLoadCitiesTaskStatus} from "../../features/settings/settingsSlice";
import TextField from "@mui/material/TextField";
import CustomDialog from "../../components/CustomDialog";
import {AppState} from "../../store";
import {Autocomplete} from "@mui/lab";

interface CreateCourtHouseProps {
    handleClose: () => void;
}

interface AddCreateLegalCaseFormData {
    cityId: number | null;
    courtHouseId: number | null;
}

export const CreateCourtHouse: React.FC<CreateCourtHouseProps> = ({handleClose}) => {
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get('page') || "1");
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const allCitiesLookup = useSelector((state: AppState) => state.settings.allCitiesLookup);
    const createCourtHouseTaskStatus = useSelector((state: AppState) => state.settings.createCourtHouseTaskStatus);
    const [errors, setErrors] = useState<FieldErrors>({});
    const defaultFormData: AddCreateLegalCaseFormData = {
        cityId: null,
        courtHouseId: null,
    };
    const [formData, setFormData] = useState<AddCreateLegalCaseFormData>(defaultFormData);

    useEffect(() => {
        dispatch(getLoadCitiesAction());
        return () => {
            dispatch(setLoadCitiesTaskStatus(null));
        }
    }, [dispatch]);

    const handleChangeCity = (event: any, value: any) => {
        setFormData({
            ...formData,
            cityId: value?.id || null,
        });
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson: any = {};
        formData.forEach((value, key) => {
            formJson[key] = value;
        });

        const result = await dispatch(createCourtHouseAction(formJson));

        if (result.type == "settings/createCourtHouse/fulfilled") {
            handleClose();

            if (page === 1) {
                dispatch(getCourtHousesAction({
                    filterByName: "",
                    page
                }));
            } else {
                navigate("/adliyeler");
            }
        }
    };

    useEffect(() => {
        return () => {
            dispatch(setCreateHouseTaskStatus(null));
        }
    }, []);

    const fieldVariant = "outlined"

    return (
        <CustomDialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}>
            <DialogTitle>Adliye Oluştur</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mb: 1}}>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="name"
                        label="Adı"
                        type="text"
                        fullWidth
                        variant="outlined"/>
                </FormControl>

                <FormControl fullWidth sx={{mt: 2, mb: 1}}>
                    <Autocomplete
                        options={allCitiesLookup || []}
                        getOptionLabel={(option: any) => option.value || ""}
                        value={
                            (allCitiesLookup || []).find((city: any) => city.id === formData.cityId) || null
                        }
                        onChange={handleChangeCity}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Şehirler"
                                variant={fieldVariant as TextFieldVariants}
                                required
                                error={!!errors.cities}/>
                        )}/>
                    <input
                        type="hidden"
                        name="cityId"
                        value={formData.cityId || ""}/>
                    {errors.cities && <ErrorText message={errors.cities}/>}
                    {createCourtHouseTaskStatus?.type === AsyncTaskStatusType.Error &&
                        createCourtHouseTaskStatus.errorMessage && (
                            <ErrorText type="form" message={createCourtHouseTaskStatus.errorMessage}/>
                        )}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button type="submit">Oluştur</Button>
            </DialogActions>
        </CustomDialog>
    );
};
