import React, {useEffect, useState} from "react";
import {
    Box,
    Fab,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {
    downloadTaskFile,
    getTaskAction,
    getTaskFolderFilesAndFoldersAction,
    moveTaskFileToFolderAction
} from "../../api/task";
import {setFolderFilesAndFolders, setLoadTaskStatus, setTask} from "../../features/task/taskSlice";
import AddIcon from "@mui/icons-material/Add";
import {AddFileToTaskDialog} from "../add-file-to-task/add-file-to-task";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import File from "mdi-material-ui/File";
import Folder from "mdi-material-ui/Folder";
import {AddFolderToTaskDialog} from "../add-folder-to-task/add-folder-to-task";
import {CreateNewFolder} from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FolderAction from "../../actions/FolderAction";
import {DeleteTaskFolderDialog} from "../delete-folder/deleteTaskFolderDialog";
import {CaseFolderDTO} from "../../api/dtos";
import {DragHandler} from "../legal-case-details/drag-handler";

const handleDownloadTaskFile = (file: CaseFolderDTO, taskId: number, dispatch: any, e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(downloadTaskFile({taskId, file}));
};

export interface TaskFilesProps {
    id: number;
}

interface DocumentRowProps {
    file: CaseFolderDTO;
    taskId: number;
}

const DocumentRow: React.FC<DocumentRowProps> = ({taskId, file}) => {
    const dispatch: any = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [dragging, setDragging] = useState(false);
    const appendedNode = document.body;

    const handleDragStart = () => {
        setDragging(true);
    };

    const handleMouseUp = async (e: MouseEvent) => {
        const target = e.target as HTMLElement;
        const dropAreaElement = target.closest(".drop-area");
        if (dropAreaElement && dropAreaElement.hasAttribute("data-id") !== undefined) {
            const dataId = dropAreaElement.getAttribute("data-id");
            const folderId = dataId == null ? null : Number(dataId);

            const result = await dispatch(moveTaskFileToFolderAction({
                taskId,
                folderId,
                fileId: file.id
            }));

            if (result.type === "task/moveTaskFileToFolder/fulfilled") {
                const params = new URLSearchParams(location.search);
                if (folderId) {
                    params.set("folderId", folderId.toString());
                } else {
                    params.delete("folderId");
                }

                navigate(`?${params.toString()}`);
            }
        }
        setDragging(false);
    };

    const innerDownloadTaskFile = (e: React.MouseEvent) => handleDownloadTaskFile(file, taskId, dispatch, e)

    return (
        <DragHandler
            dragging={dragging}
            active={true}
            onDragStart={handleDragStart}
            onMouseUp={handleMouseUp}
            appendedNode={appendedNode}>
            <div style={{display: "flex", alignItems: "center"}}>
                <span className="document"><File/></span>
                <a
                    href="#"
                    onClick={innerDownloadTaskFile}
                    style={{textDecoration: 'none', color: '#26a3ff'}}>
                    {file.name}
                </a>
            </div>
        </DragHandler>
    )
}

interface FolderInfo {
    folderId: number;
    folderName: string;
}

interface FolderRowProps {
    folderInfo: FolderInfo;
    taskId: number;
}

const FolderRow: React.FC<FolderRowProps> = ({folderInfo, taskId}) => {
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const goFolder = () => {
        dispatch(setFolderFilesAndFolders(null));
        navigate(`/gorevler/${taskId}/dosyalar?folderId=${folderInfo.folderId}`);
    }

    return (
        <div className="drop-area" data-id={folderInfo.folderId}>
            <div style={{display: "flex", alignItems: "center"}}>
                <span className="folder"><Folder/></span>
                <a
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        goFolder();
                    }}
                    style={{textDecoration: 'none', color: '#26a3ff'}}>
                    {folderInfo.folderName}
                </a>
            </div>
        </div>
    )
}

export const TaskFiles: React.FC<TaskFilesProps> = ({id}) => {
    const location = useLocation();
    const dispatch: any = useDispatch();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const folderId = searchParams.get('folderId') ? Number(searchParams.get('folderId')) : undefined;

    const task = useSelector((state: AppState) => state.task.task);
    const folderFilesAndFolders = useSelector((state: AppState) => state.task.folderFilesAndFolders);
    const [addFileDialogOpen, setAddFileDialogOpen] = useState(false);
    const [addFolderDialogOpen, setAddFolderDialogOpen] = useState(false);
    const [deleteFolderDialogOpen, setDeleteFolderDialogOpen] = useState(false);
    const [activeFolder, setActiveFolder] = useState<CaseFolderDTO | null>(null);

    const handleAddFileDialogOpen = () => setAddFileDialogOpen(true);
    const handleAddFileDialogClose = () => setAddFileDialogOpen(false);

    const handleAddFolderDialogOpen = () => setAddFolderDialogOpen(true);
    const handleAddFolderDialogClose = () => setAddFolderDialogOpen(false);


    useEffect(() => {
        dispatch(getTaskAction(id));
        dispatch(getTaskFolderFilesAndFoldersAction({taskId: id, folderId}));

        return () => {
            dispatch(setTask(null));
            dispatch(setLoadTaskStatus(null));
            dispatch(setFolderFilesAndFolders(null));
        };
    }, [id, dispatch]);

    useEffect(() => {
        dispatch(getTaskFolderFilesAndFoldersAction({taskId: id, folderId}));
    }, [folderId]);

    const handleFileAdd = () => dispatch(getTaskFolderFilesAndFoldersAction({taskId: id, folderId}));
    const handleFolderAdd = () => dispatch(getTaskFolderFilesAndFoldersAction({taskId: id, folderId}));

    const openAddFile = () => handleAddFileDialogOpen();

    const openAddFolder = () => handleAddFolderDialogOpen();

    const openDelete = (folder: CaseFolderDTO) => handleDeleteFolderDialogOpen(folder);

    if (!task) {
        return null;
    }
    let folders = folderFilesAndFolders?.folders;
    let files = folderFilesAndFolders?.files;
    const folder = folderFilesAndFolders?.folder;

    files = files?.filter((it) => it.folder?.id === folderId);
    folders = folders?.filter((it) => it.parentFolderId === folderId)

    const goBack = () => {
        const searchParams = new URLSearchParams(location.search);
        if (folder?.parentFolderId) {
            searchParams.set('folderId', folder.parentFolderId.toString());
        } else {
            searchParams.delete('folderId');
        }
        navigate(`?${searchParams.toString()}`);
    }

    const handleDeleteFolderDialogOpen = (folder: CaseFolderDTO) => {
        setActiveFolder(folder);
        setDeleteFolderDialogOpen(true);
    };

    const handleDeleteFolderDialogClose = () => {
        setActiveFolder(null);
        setDeleteFolderDialogOpen(false);
    };

    const handleFolderDelete = () => {
        dispatch(getTaskFolderFilesAndFoldersAction({taskId: id, folderId}));
        handleDeleteFolderDialogClose();
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 1,
                    m: 1,
                    borderRadius: 1,
                }}>
                {folder && (
                    <div className="drop-area" data-id={folder.parentFolderId || null}
                         style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <Fab
                            size="small"
                            color="default"
                            aria-label="back"
                            onClick={goBack}>
                            <ArrowBackIcon/>
                        </Fab>

                        <Typography variant="body1" sx={{ml: 5}}>
                            <strong>{folder.name}</strong>
                        </Typography>
                    </div>
                )}
                <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    sx={{marginLeft: "auto", marginBottom: 7}}
                    onClick={openAddFile}>
                    <AddIcon/>
                </Fab>
                <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    sx={{marginBottom: 7, marginLeft: 2}}
                    onClick={openAddFolder}>
                    <CreateNewFolder/>
                </Fab>
            </Box>

            {addFileDialogOpen && (
                <AddFileToTaskDialog
                    task={task}
                    folderId={folderId}
                    onClose={handleAddFileDialogClose}
                    onFileAdded={handleFileAdd}/>
            )}

            {addFolderDialogOpen && (
                <AddFolderToTaskDialog
                    task={task}
                    folderId={folderId}
                    onClose={handleAddFolderDialogClose}
                    onFolderAdded={handleFolderAdd}/>
            )}

            {deleteFolderDialogOpen && activeFolder && (
                <DeleteTaskFolderDialog
                    handleClose={handleDeleteFolderDialogClose}
                    handleDelete={handleFolderDelete}
                    taskId={id}
                    folderId={activeFolder.id}
                    folderName={activeFolder.name}/>
            )}

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: "45%"}}>Dosya Adı</TableCell>
                            <TableCell style={{width: "45%"}}>Açıklama</TableCell>
                            <TableCell style={{width: "10%"}}>İşlemler</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {folders?.map((it) => (
                            <TableRow key={it.id}>
                                <TableCell>
                                    <FolderRow taskId={task.id}
                                               folderInfo={{folderId: it.id, folderName: it.name}}/>
                                </TableCell>
                                <TableCell/>
                                <TableCell>
                                    <FolderAction
                                        folder={it}
                                        onDelete={openDelete}/>
                                </TableCell>
                            </TableRow>
                        ))}
                        {files?.map((it) => (
                            <TableRow key={it.id}>
                                <TableCell>
                                    <DocumentRow taskId={task.id} file={it}/>
                                </TableCell>
                                <TableCell>{it.description}</TableCell>
                                <TableCell/>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
