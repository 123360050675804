import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import FormControl from "@mui/material/FormControl";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import Button from "@mui/material/Button";
import {getPresentableLitigantType, LitigantType} from "../../api/litigantType";
import {AddLitigantToCaseDTO, CompanyType} from "../../api/dtos";
import CustomDialog from "../../components/CustomDialog";
import {getAllClientsAction, getAllCompaniesAction} from "../../api/settings";
import {AddLitigantType} from "./createLegalCaseDialog";
import {CreateCompanyDialog} from "../create-company/createCompanyDialog";
import {CreateClientDialog} from "../create-client/createClientDialog";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {Autocomplete} from "@mui/lab";

interface AddReportedEntitiesDialogProps {
    handleClose: () => void;
    addReportedEntities: (result: AddLitigantType) => void;
    excludeItems: AddLitigantToCaseDTO[];
}

interface FormData {
    litigantType: LitigantType;
    selection: number | null;
    name: string;
}

export const AddReportedEntitiesDialog: React.FC<AddReportedEntitiesDialogProps> = ({
                                                                                        handleClose,
                                                                                        addReportedEntities,
                                                                                        excludeItems
                                                                                    }) => {
    const allClients = useSelector((state: AppState) => state.settings.allClientsLookup);
    const allCompanies = useSelector((state: AppState) => state.settings.allCompanies);
    const dispatch: any = useDispatch();

    const [isCreateCompanyDialogOpen, setIsCreateCompanyDialogOpen] = useState(false);
    const [isCreateClientDialogOpen, setIsCreateClientDialogOpen] = useState(false);

    const handleCloseCreateCompanyDialog = () => {
        setIsCreateCompanyDialogOpen(false);
    };

    const handleCloseCreateClientDialog = () => {
        setIsCreateClientDialogOpen(false);
    };

    const handleNewCompanyCreated = () => {
        dispatch(getAllCompaniesAction());
        handleCloseCreateCompanyDialog();
    };

    const handleNewClientCreated = () => {
        dispatch(getAllClientsAction());
        handleCloseCreateClientDialog();
    };

    const excludedClientIds = excludeItems
        .filter((excludeItem) => excludeItem.type === LitigantType.Client)
        .map((excludeItem) => excludeItem.clientId);

    const filteredAllClients = allClients?.filter(
        (client) => !excludedClientIds.includes(client.id)
    );

    const filteredAllClientsWithNewOption = [
        {id: -1, value: "Yeni Kayıt"},
        ...(filteredAllClients || [])
    ];

    const excludedCompanyIds = excludeItems
        .filter((excludeItem) => excludeItem.type === LitigantType.Company)
        .map((excludeItem) => excludeItem.companyId);

    const filteredAllCompanies = allCompanies?.filter(
        (company) => !excludedCompanyIds.includes(company.id)
    );

    const initialFormData: FormData = {
        litigantType: LitigantType.Company,
        selection: null,
        name: ""
    };

    const litigantTypeRef = useRef(initialFormData.litigantType);
    const [formData, setFormData] = useState<FormData>(initialFormData);

    useEffect(() => {
        if (formData.litigantType !== litigantTypeRef.current) {
            setFormData((prevFormData: any) => ({
                ...prevFormData,
                selection: null,
                name: "",
            }));
        }
        litigantTypeRef.current = formData.litigantType;
    }, [formData.litigantType]);

    useEffect(() => {
        if (!allCompanies || allCompanies.length === 0) {
            dispatch(getAllCompaniesAction());
        }
    }, [dispatch, allCompanies]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let selection = undefined;
        let name = undefined;
        switch (formData.litigantType) {
            case LitigantType.Company:
                selection = allCompanies?.find((it) => it.id === formData.selection);
                break;
            case LitigantType.Client:
                selection = allClients?.find((it) => it.id === formData.selection);
                break;
        }

        addReportedEntities({
            litigantType: formData.litigantType,
            selection,
            name
        });
        handleClose();
    };

    const submitDisabled = formData.selection == null;

    return (
        <>
            <CustomDialog
                open={true}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit
                }}>
                <DialogTitle>İhbar Olunan Seç</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                        <InputLabel id="litigantType-label">İhbar Olunan Tipi</InputLabel>
                        <Select
                            autoFocus={true}
                            labelId="status-label"
                            id="litigantType"
                            name="litigantType"
                            value={formData.litigantType}
                            label="İhbar Olunan Tipi"
                            onChange={(e) => setFormData({...formData, litigantType: e.target.value as LitigantType})}>
                            {Object.values(LitigantType).map((litigantType) => (
                                <MenuItem key={litigantType} value={litigantType}>
                                    {getPresentableLitigantType(litigantType)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {formData.litigantType === LitigantType.Client && (
                        <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                            <Autocomplete
                                options={filteredAllClientsWithNewOption}
                                getOptionLabel={(option: any) => option.value || ""}
                                onChange={(event, newValue) => {
                                    if (newValue?.id === -1) {
                                        setIsCreateClientDialogOpen(true);
                                    } else {
                                        setFormData({
                                            ...formData,
                                            selection: newValue ? newValue.id : null,
                                        });
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="İhbar Olunan" variant="outlined"/>
                                )}
                                value={filteredAllClientsWithNewOption.find(
                                    (client) => client.id === formData.selection
                                ) || null}/>
                        </FormControl>
                    )}

                    {formData.litigantType === LitigantType.Company && (
                        <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                            <Autocomplete
                                options={filteredAllCompanies || []}
                                groupBy={(option: any) =>
                                    option.type === CompanyType.Branch
                                        ? "ŞUBELER"
                                        : option.type === CompanyType.Subsidiary
                                            ? "İŞTİRAKLER"
                                            : ""
                                }
                                getOptionLabel={(option: any) => option.name || ""}
                                onChange={(event, newValue) => {
                                    setFormData({
                                        ...formData,
                                        selection: newValue ? newValue.id : null,
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="İhbar Olunan" variant="outlined"/>
                                )}
                                value={filteredAllCompanies?.find(
                                    (company) => company.id === formData.selection
                                ) || null}/>
                        </FormControl>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>İptal</Button>
                    <Button type="submit" disabled={submitDisabled}>Ekle</Button>
                </DialogActions>
            </CustomDialog>

            {isCreateCompanyDialogOpen && (
                <CreateCompanyDialog
                    companyType={CompanyType.Subsidiary}
                    handleClose={handleCloseCreateCompanyDialog}
                    handleCreate={handleNewCompanyCreated}/>
            )}

            {isCreateClientDialogOpen && (
                <CreateClientDialog
                    handleClose={handleCloseCreateClientDialog}
                    handleCreate={handleNewClientCreated}/>
            )}
        </>
    );
};
