import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncTaskStatus } from "../../shared/dtos";
import { SearchResultDTO, PaginatedEntityList } from "../../api/dtos";

export interface SearchState {
    searchResults: PaginatedEntityList<SearchResultDTO> | null;
    searchTaskStatus: AsyncTaskStatus | null;
}

const initialState: SearchState = {
    searchResults: null,
    searchTaskStatus: null,
};

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setSearchTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.searchTaskStatus = action.payload;
        },
        setSearchResults: (state, action: PayloadAction<PaginatedEntityList<SearchResultDTO> | null>) => {
            state.searchResults = action.payload;
        },
    }
});

export const {
    setSearchTaskStatus,
    setSearchResults,
} = searchSlice.actions;

export default searchSlice.reducer;