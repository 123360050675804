import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Box from '@mui/material/Box';
import {Theme} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import InputAdornment from '@mui/material/InputAdornment';
import Magnify from 'mdi-material-ui/Magnify';
import {Settings} from "../theme/@core/context/settingsContext";
import ModeToggler from '../theme/@core/layouts/components/shared-components/ModeToggler';
import UserDropdown from "./UserDropdown";
import {useDispatch, useSelector} from 'react-redux';
import {AppState} from '../store';
import {searchTaskAction} from '../api/search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useNavigate} from "react-router-dom";
import Menu from '@mui/material/Menu';
import Link from '@mui/material/Link';
import {debounce} from "@mui/material";
import {formatDate} from "../shared/utils";
import {setSearchResults} from "../features/search/searchSlice";
import {SearchResultDTO} from '../api/dtos';

interface Props {
    hidden: boolean;
    settings: Settings;
    toggleNavVisibility: () => void;
    saveSettings: (values: Settings) => void;
}

const AppBarContent = (props: Props) => {
    const {hidden, settings, saveSettings, toggleNavVisibility} = props;
    const hiddenSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const dispatch: any = useDispatch();
    const searchResults = useSelector((state: AppState) => state.search.searchResults);
    const [searchText, setSearchText] = useState('');
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const searchInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (searchResults && searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [searchResults]);

    const debouncedSearch = useCallback(
        debounce((value) => {
            if (value.length > 2) {
                dispatch(searchTaskAction({searchText: value, pageSize: 5, pageNumber: 1}));
                setAnchorEl(searchInputRef.current);
            } else {
                setAnchorEl(null);
            }
        }, 300),
        [dispatch]
    );

    const handleClose = () => {
        setAnchorEl(null);
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchText(value);
        if (value.length > 2) {
            debouncedSearch(value);
        } else {
            dispatch(setSearchResults(null));
            setAnchorEl(null);
        }
    };

    const searchOptions = useMemo(() => searchResults ? searchResults.entity.slice(0, 5) : [], [searchResults]);

    return (
        <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Box className='actions-left' sx={{mr: 2, display: 'flex', alignItems: 'center'}}>
                {hidden && (
                    <IconButton
                        color='inherit'
                        onClick={toggleNavVisibility}
                        sx={{ml: -2.75, ...(hiddenSm ? {} : {mr: 3.5})}}>
                        <Menu open={Boolean(anchorEl)}/>
                    </IconButton>
                )}
                <TextField
                    size='small'
                    sx={{'& .MuiOutlinedInput-root': {borderRadius: 4, width: '500px'}}}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <Magnify fontSize='small'/>
                            </InputAdornment>
                        )
                    }}
                    value={searchText}
                    onChange={handleSearch}
                    inputRef={searchInputRef}
                    tabIndex={0}
                    autoFocus/>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    disableAutoFocusItem
                    disableEnforceFocus
                    disableRestoreFocus>
                    <Paper sx={{ overflow: 'auto' , left:'-300px'}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: '20%' }}>DAVA ADI</TableCell>
                                    <TableCell sx={{ width: '15%' }}>DAVACI</TableCell>
                                    <TableCell sx={{ width: '15%' }}>DAVALI</TableCell>
                                    <TableCell sx={{ width: '20%' }}>DAVA DOSYA NUMARASI</TableCell>
                                    <TableCell sx={{ width: '15%' }}>DOSYA ÖZEL NUMARASI</TableCell>
                                    <TableCell sx={{ width: '15%' }}>DAVA TARİHİ</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {searchOptions.map((result: SearchResultDTO) => (
                                    <TableRow
                                        key={result.id}
                                        onClick={() => {
                                            navigate(`/hukuk-davalari/${result.id}/ayrintilar`);
                                            handleClose();
                                        }}>
                                        <TableCell>
                                            <Link
                                                component="button"
                                                onClick={() => navigate(`/hukuk-davalari/${result.id}/ayrintilar`)}
                                                underline="hover">
                                                {result.name}
                                            </Link>
                                        </TableCell>
                                        <TableCell>{result.prosecutors.map(p => p.company?.value).join(', ')}</TableCell>
                                        <TableCell>{result.defendants.map(d => d.company?.value).join(', ')}</TableCell>
                                        <TableCell>{result.fileNumber}</TableCell>
                                        <TableCell>{result.corporateFileNumber}</TableCell>
                                        <TableCell>{formatDate(Number(result.caseDate))}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <Box sx={{p: 2, textAlign: 'center'}}>
                            <Link
                                component="button"
                                onClick={() => {
                                    navigate(`/arama-sonuclari?searchText=${searchText}&page=1`);
                                    handleClose();
                                }}
                                underline="hover">
                                Tüm Sonuçlar
                            </Link>
                        </Box>
                    </Paper>
                </Menu>
            </Box>
            <Box className='actions-right' sx={{display: 'flex', alignItems: 'center'}}>
                <ModeToggler settings={settings} saveSettings={saveSettings}/>
                <UserDropdown/>
            </Box>
        </Box>
    );
}

export default AppBarContent;
