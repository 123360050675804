import React, {MouseEvent, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DotsVertical from "mdi-material-ui/DotsVertical";
import {AddCircle, Delete, Edit, Group, SwapHoriz} from "@mui/icons-material";
import {TaskDTO, UserPermission} from "../api/dtos";
import {useSelector} from "react-redux";
import {AppState} from "../store";
import Tooltip from "@mui/material/Tooltip";
import {adminOrHasPermission} from "../shared/utils";

interface TaskActionProps {
    task: TaskDTO;
    onChangeStatus: (task: TaskDTO) => void;
    onDelete: (task: TaskDTO) => void;
    onEditTask: (task: TaskDTO) => void;
    onEditAssignees: (task: TaskDTO) => void;
    onAddFile: (task: TaskDTO) => void;
}

const TaskAction: React.FC<TaskActionProps> = ({
                                                   task,
                                                   onChangeStatus,
                                                   onDelete,
                                                   onEditTask,
                                                   onEditAssignees,
                                                   onAddFile
                                               }) => {
    const user = useSelector((state: AppState) => state.auth.userWithToken?.user);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onMenuItemClick = (action: () => void) => {
        handleClose();
        action();
    };

    const changeStatus = () => onMenuItemClick(() => onChangeStatus(task));
    const deleteTask = () => onMenuItemClick(() => onDelete(task));
    const editTask = () => onMenuItemClick(() => onEditTask(task));
    const editAssignees = () => onMenuItemClick(() => onEditAssignees(task));
    const addFile = () => onMenuItemClick(() => onAddFile(task));

    const taskEdit = adminOrHasPermission(user, UserPermission.TASK_EDIT);
    const taskStatusUpdate = adminOrHasPermission(user, UserPermission.TASK_STATUS_UPDATE);
    const taskDelete = adminOrHasPermission(user, UserPermission.TASK_DELETE);
    const taskAssigneeEdit = adminOrHasPermission(user, UserPermission.TASK_ASSIGNEE_UPDATE);
    const taskFileAdd = adminOrHasPermission(user, UserPermission.TASK_FILE_ADD);

    const isTaskAssignedToCurrentUser = task.assignees?.some(assignee => assignee.id === user?.id);

    const isPrivilegedUser = user?.role === "Admin" || user?.role === "Manager";
    const isAssignedToUser = isTaskAssignedToCurrentUser;

    const canEdit = taskEdit && isAssignedToUser || isPrivilegedUser;
    const canChangeStatus = taskStatusUpdate && isAssignedToUser || isPrivilegedUser;
    const canDelete = taskDelete && isAssignedToUser || isPrivilegedUser;
    const canEditAssignees = taskAssigneeEdit && isAssignedToUser || isPrivilegedUser;
    const canAddFile = taskFileAdd && isAssignedToUser || isPrivilegedUser;

    const hasAtLeastOneAction = canEdit || canChangeStatus || canDelete || canEditAssignees || canAddFile;

    return hasAtLeastOneAction
        ? (
            <>
                <IconButton
                    aria-controls={open ? 'task-action-menu' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    size='small' aria-label='settings' className='card-more-options'
                    sx={{color: 'text.secondary'}}>
                    <DotsVertical/>
                </IconButton>
                <Menu
                    id="task-action-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}>
                    {canEdit && (
                        <MenuItem onClick={editTask}>
                            <IconButton size='small' aria-label='edit' className='card-more-options'
                                        sx={{color: 'text.secondary'}}>
                                <Edit/>
                            </IconButton>
                            Düzenle
                        </MenuItem>
                    )}
                    {canChangeStatus && (
                        <MenuItem onClick={changeStatus}>
                            <IconButton size='small' aria-label='change status' className='card-more-options'
                                        sx={{color: 'text.secondary'}}>
                                <SwapHoriz/>
                            </IconButton>
                            Durumunu Değiştir
                        </MenuItem>
                    )}
                    {canDelete && (
                        <MenuItem onClick={deleteTask}>
                            <IconButton size='small' aria-label='delete' className='card-more-options'
                                        sx={{color: 'text.secondary'}}>
                                <Delete/>
                            </IconButton>
                            Görevi Sil
                        </MenuItem>
                    )}
                    {canEditAssignees && (
                        <MenuItem onClick={editAssignees}>
                            <IconButton size='small' aria-label='edit assignees' className='card-more-options'
                                        sx={{color: 'text.secondary'}}>
                                <Group/>
                            </IconButton>
                            Atanan Kişiyi Düzenle
                        </MenuItem>
                    )}
                    {canAddFile && (
                        <MenuItem onClick={addFile}>
                            <IconButton size='small' aria-label='add file' className='card-more-options'
                                        sx={{color: 'text.secondary'}}>
                                <AddCircle/>
                            </IconButton>
                            Dosya Ekle
                        </MenuItem>
                    )}
                </Menu>
            </>
        )
        : (
            <Tooltip title={isTaskAssignedToCurrentUser ? "İşlem yetkiniz yok" : "Bu görev size atanmadı"}>
                <IconButton
                    size="small"
                    aria-label="settings"
                    className="card-more-options"
                    sx={{color: 'action.disabled'}}>
                    <DotsVertical/>
                </IconButton>
            </Tooltip>
        );
}

export default TaskAction;
