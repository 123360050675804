import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AsyncTaskStatus} from "../../shared/dtos";
import {
    CityDTO,
    ClientDTO,
    CompanyDTO,
    ConsumerArbitrationCommitteeDTO,
    CourtDTO,
    CourtHouseDTO,
    NotaryDTO,
    PaginatedEntityList,
    SettingsLookupDTO
} from "../../api/dtos";

export interface SettingsState {
    allCompanies: CompanyDTO[] | null;
    allClientsLookup: SettingsLookupDTO[] | null;
    allCitiesLookup: SettingsLookupDTO[] | null;
    allCourts: CourtDTO[] | null;
    allCourtHouses: CourtHouseDTO[] | null;
    allNotaries: NotaryDTO[] | null;
    allConsumerArbitrationCommittees: ConsumerArbitrationCommitteeDTO[] | null;
    loadAllCompaniesTaskStatus: AsyncTaskStatus | null;
    loadAllPaginatedCompaniesTaskStatus: AsyncTaskStatus | null;
    loadAllClientsTaskStatus: AsyncTaskStatus | null;
    createCompanyTaskStatus: AsyncTaskStatus | null;
    editCompanyTaskStatus: AsyncTaskStatus | null;
    deleteCompanyTaskStatus: AsyncTaskStatus | null;
    createClientTaskStatus: AsyncTaskStatus | null;
    editClientTaskStatus: AsyncTaskStatus | null;
    deleteClientTaskStatus: AsyncTaskStatus | null;
    createLegalNoticeTaskStatus: AsyncTaskStatus | null;
    loadUserDataTaskStatus: AsyncTaskStatus | null;
    createCourtTaskStatus: AsyncTaskStatus | null;
    createCourtHouseTaskStatus: AsyncTaskStatus | null;
    loadUsersTaskStatus: AsyncTaskStatus | null;
    loadCourtHousesTaskStatus: AsyncTaskStatus | null;
    loadCitiesTaskStatus: AsyncTaskStatus | null;
    allPaginatedCourtHouses: PaginatedEntityList<CourtHouseDTO> | null;
    allPaginatedCourts: PaginatedEntityList<CourtDTO> | null;
    loadAllCourtsTaskStatus: AsyncTaskStatus | null;
    loadAllCourtHousesTaskStatus: AsyncTaskStatus | null;
    deleteLegalCaseTaskStatus: AsyncTaskStatus | null;
    loadCourtsTaskStatus: AsyncTaskStatus | null;
    loadEmailSettingsTaskStatus: AsyncTaskStatus | null;
    updateEmailSettingsTaskStatus: AsyncTaskStatus | null;
    emailSettings: { [index: string]: string; } | null;
    editCourtHouseTaskStatus: AsyncTaskStatus | null;
    deleteCourtHouseTaskStatus: AsyncTaskStatus | null;
    editCourtTaskStatus: AsyncTaskStatus | null;
    deleteCourtTaskStatus: AsyncTaskStatus | null;
    allPaginatedCompanies: PaginatedEntityList<CompanyDTO> | null;
    allPaginatedCities: PaginatedEntityList<CityDTO> | null;
    getAllPaginatedCitiesStatus: AsyncTaskStatus | null;
    generate2FATaskStatus: AsyncTaskStatus | null;
    allPaginatedClients: PaginatedEntityList<ClientDTO> | null;
    loadAllPaginatedClientsTaskStatus: AsyncTaskStatus | null;
    setGetAllClientsTaskStatus: AsyncTaskStatus | null;
    createNotaryTaskStatus: AsyncTaskStatus | null;
    editNotaryTaskStatus: AsyncTaskStatus | null;
    deleteNotaryTaskStatus: AsyncTaskStatus | null;
    createCommitteeTaskStatus: AsyncTaskStatus | null;
    editCommitteeTaskStatus: AsyncTaskStatus | null;
    deleteCommitteeTaskStatus: AsyncTaskStatus | null;
    loadAllNotariesTaskStatus: AsyncTaskStatus | null;
    loadAllConsumerArbitrationCommitteesTaskStatus: AsyncTaskStatus | null;
    getAllPaginatedNotariesTaskStatus: AsyncTaskStatus | null;
    allPaginatedNotaries: PaginatedEntityList<NotaryDTO> | null;
    allCommittees: ConsumerArbitrationCommitteeDTO[] | null;
    allPaginatedCommittees: PaginatedEntityList<ConsumerArbitrationCommitteeDTO> | null;
    loadAllCommitteesTaskStatus: AsyncTaskStatus | null;
    getAllPaginatedCommitteesTaskStatus: AsyncTaskStatus | null;
    getAllPaginatedCommitteesStatus: AsyncTaskStatus | null;
}

const initialState: SettingsState = {
    loadUserDataTaskStatus: null,
    loadAllCourtsTaskStatus: null,
    allCitiesLookup: null,
    deleteCourtTaskStatus: null,
    allCompanies: null,
    allPaginatedCities: null,
    getAllPaginatedCitiesStatus: null,
    getAllPaginatedCommitteesStatus: null,
    editCourtTaskStatus: null,
    allCourtHouses: null,
    allClientsLookup: null,
    loadAllCompaniesTaskStatus: null,
    loadAllPaginatedCompaniesTaskStatus: null,
    loadAllClientsTaskStatus: null,
    loadCourtHousesTaskStatus: null,
    createCompanyTaskStatus: null,
    editCompanyTaskStatus: null,
    deleteCompanyTaskStatus: null,
    createClientTaskStatus: null,
    createLegalNoticeTaskStatus: null,
    editClientTaskStatus: null,
    deleteClientTaskStatus: null,
    createCourtTaskStatus: null,
    createCourtHouseTaskStatus: null,
    loadUsersTaskStatus: null,
    loadCitiesTaskStatus: null,
    allPaginatedCourtHouses: null,
    allCourts: null,
    loadAllCourtHousesTaskStatus: null,
    deleteLegalCaseTaskStatus: null,
    allPaginatedCourts: null,
    loadCourtsTaskStatus: null,
    loadEmailSettingsTaskStatus: null,
    updateEmailSettingsTaskStatus: null,
    emailSettings: null,
    editCourtHouseTaskStatus: null,
    deleteCourtHouseTaskStatus: null,
    allPaginatedCompanies: null,
    generate2FATaskStatus: null,
    allPaginatedClients: null,
    loadAllPaginatedClientsTaskStatus: null,
    setGetAllClientsTaskStatus: null,
    allNotaries: null,
    allConsumerArbitrationCommittees: null,
    createNotaryTaskStatus: null,
    editNotaryTaskStatus: null,
    deleteNotaryTaskStatus: null,
    createCommitteeTaskStatus: null,
    editCommitteeTaskStatus: null,
    deleteCommitteeTaskStatus: null,
    loadAllNotariesTaskStatus: null,
    loadAllConsumerArbitrationCommitteesTaskStatus: null,
    getAllPaginatedNotariesTaskStatus: null,
    allPaginatedNotaries: null,
    allCommittees: null,
    allPaginatedCommittees: null,
    loadAllCommitteesTaskStatus: null,
    getAllPaginatedCommitteesTaskStatus: null,
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setLoadUserDataTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadUserDataTaskStatus = action.payload;
        },
        setDeleteLegalCaseTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.deleteLegalCaseTaskStatus = action.payload;
        },
        setLoadAllCourtsTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadAllCourtsTaskStatus = action.payload;
        },
        setAllCompanies: (state, action: PayloadAction<CompanyDTO[] | null>) => {
            state.allCompanies = action.payload;
        },
        setAllClientsLookup: (state, action: PayloadAction<SettingsLookupDTO[] | null>) => {
            state.allClientsLookup = action.payload;
        },
        setGetAllCompaniesTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadAllCompaniesTaskStatus = action.payload;
        },
        setGetAllPaginatedCompaniesTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadAllPaginatedCompaniesTaskStatus = action.payload;
        },
        setGetAllPaginatedClientsTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadAllClientsTaskStatus = action.payload;
        },
        setCreateLegalNoticeTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.createLegalNoticeTaskStatus = action.payload;
        },
        setCreateCompanyTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.createCompanyTaskStatus = action.payload;
        },
        setEditCompanyTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.editCompanyTaskStatus = action.payload;
        },
        setDeleteCompanyTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.deleteCompanyTaskStatus = action.payload;
        },
        setCreateClientTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.createClientTaskStatus = action.payload;
        },
        setEditClientTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.editClientTaskStatus = action.payload;
        },
        setDeleteClientTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.deleteClientTaskStatus = action.payload;
        },
        setCreateCourtTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.createCourtTaskStatus = action.payload;
        },
        setCreateHouseTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.createCourtHouseTaskStatus = action.payload;
        },
        setLoadUsersTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadUsersTaskStatus = action.payload;
        },
        setLoadCitiesTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadCitiesTaskStatus = action.payload;
        },
        setAllCitiesLookup: (state, action: PayloadAction<SettingsLookupDTO[] | null>) => {
            state.allCitiesLookup = action.payload;
        },
        setLoadCourtHousesTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadCourtHousesTaskStatus = action.payload;
        },
        setAllPaginatedCourtHouses: (state, action: PayloadAction<PaginatedEntityList<CourtHouseDTO> | null>) => {
            state.allPaginatedCourtHouses = action.payload;
        },
        setAllPaginatedCourts: (state, action: PayloadAction<PaginatedEntityList<CourtDTO> | null>) => {
            state.allPaginatedCourts = action.payload;
        },
        setAllCourts: (state, action: PayloadAction<CourtDTO[] | null>) => {
            state.allCourts = action.payload;
        },
        setAllCourtHouses: (state, action: PayloadAction<CourtHouseDTO[] | null>) => {
            state.allCourtHouses = action.payload;
        },
        setLoadCourtsTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadCourtsTaskStatus = action.payload;
        },
        setLoadEmailSettingsTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadEmailSettingsTaskStatus = action.payload;
        },
        setUpdateEmailSettingsTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.updateEmailSettingsTaskStatus = action.payload;
        },
        setEmailSettings: (state, action: PayloadAction<{ [index: string]: string; } | null>) => {
            state.emailSettings = action.payload;
        },
        setEditCourtHouseTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.editCourtHouseTaskStatus = action.payload;
        },
        setEditCourtTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.editCourtTaskStatus = action.payload;
        },
        setDeleteCourtTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.deleteCourtTaskStatus = action.payload;
        },
        setDeleteCourtHouseTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.deleteCourtHouseTaskStatus = action.payload;
        },
        setAllPaginatedCompanies: (state, action: PayloadAction<PaginatedEntityList<CompanyDTO> | null>) => {
            state.allPaginatedCompanies = action.payload;
        },
        setGetAllPaginatedCitiesStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.getAllPaginatedCitiesStatus = action.payload;
        },
        setAllPaginatedCities: (state, action: PayloadAction<PaginatedEntityList<CityDTO> | null>) => {
            state.allPaginatedCities = action.payload;
        },
        setGenerate2FATaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.generate2FATaskStatus = action.payload;
        },
        setAllPaginatedClients: (state, action: PayloadAction<PaginatedEntityList<ClientDTO> | null>) => {
            state.allPaginatedClients = action.payload;
        },
        setGetAllClientsTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.setGetAllClientsTaskStatus = action.payload;
        },
        setAllNotaries: (state, action: PayloadAction<NotaryDTO[] | null>) => {
            state.allNotaries = action.payload;
        },
        setAllConsumerArbitrationCommittees: (state, action: PayloadAction<ConsumerArbitrationCommitteeDTO[] | null>) => {
            state.allConsumerArbitrationCommittees = action.payload;
        },
        setCreateNotaryTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.createNotaryTaskStatus = action.payload;
        },
        setEditNotaryTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.editNotaryTaskStatus = action.payload;
        },
        setDeleteNotaryTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.deleteNotaryTaskStatus = action.payload;
        },
        setCreateCommitteeTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.createCommitteeTaskStatus = action.payload;
        },
        setEditCommitteeTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.editCommitteeTaskStatus = action.payload;
        },
        setDeleteCommitteeTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.deleteCommitteeTaskStatus = action.payload;
        },
        setLoadAllNotariesTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadAllNotariesTaskStatus = action.payload;
        },
        setLoadAllConsumerArbitrationCommitteesTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadAllConsumerArbitrationCommitteesTaskStatus = action.payload;
        },
        setGetAllPaginatedNotariesTaskStatus(state, action: PayloadAction<AsyncTaskStatus>) {
            state.getAllPaginatedNotariesTaskStatus = action.payload;
        },
        setAllPaginatedNotaries(state, action: PayloadAction<PaginatedEntityList<NotaryDTO> | null>) {
            state.allPaginatedNotaries = action.payload;
        },
        setAllCommittees: (state, action: PayloadAction<ConsumerArbitrationCommitteeDTO[] | null>) => {
            state.allCommittees = action.payload;
        },
        setAllPaginatedCommittees: (state, action: PayloadAction<PaginatedEntityList<ConsumerArbitrationCommitteeDTO> | null>) => {
            state.allPaginatedCommittees = action.payload;
        },
        setLoadAllCommitteesTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadAllCommitteesTaskStatus = action.payload;
        },
        setGetAllPaginatedCommitteesTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.getAllPaginatedCommitteesTaskStatus = action.payload;
        },
    }
});

export const {
    setLoadCourtsTaskStatus,
    setAllCommittees,
    setAllPaginatedCourts,
    setAllPaginatedCourtHouses,
    setLoadCourtHousesTaskStatus,
    setAllPaginatedCommittees,
    setGetAllCompaniesTaskStatus,
    setGetAllPaginatedCompaniesTaskStatus,
    setLoadAllCommitteesTaskStatus,
    setGetAllPaginatedClientsTaskStatus,
    setDeleteCompanyTaskStatus,
    setGetAllPaginatedCommitteesTaskStatus,
    setEditCompanyTaskStatus,
    setCreateCompanyTaskStatus,
    setAllCompanies,
    setAllClientsLookup,
    setCreateClientTaskStatus,
    setEditClientTaskStatus,
    setDeleteClientTaskStatus,
    setLoadUserDataTaskStatus,
    setCreateCourtTaskStatus,
    setCreateHouseTaskStatus,
    setCreateLegalNoticeTaskStatus,
    setLoadUsersTaskStatus,
    setLoadCitiesTaskStatus,
    setAllCitiesLookup,
    setLoadAllCourtsTaskStatus,
    setAllCourts,
    setAllCourtHouses,
    setDeleteLegalCaseTaskStatus,
    setLoadEmailSettingsTaskStatus,
    setUpdateEmailSettingsTaskStatus,
    setEmailSettings,
    setEditCourtHouseTaskStatus,
    setEditCourtTaskStatus,
    setDeleteCourtTaskStatus,
    setDeleteCourtHouseTaskStatus,
    setAllPaginatedCompanies,
    setGetAllPaginatedCitiesStatus,
    setAllPaginatedCities,
    setGenerate2FATaskStatus,
    setAllPaginatedClients,
    setGetAllClientsTaskStatus,
    setAllNotaries,
    setCreateNotaryTaskStatus,
    setEditNotaryTaskStatus,
    setDeleteNotaryTaskStatus,
    setCreateCommitteeTaskStatus,
    setEditCommitteeTaskStatus,
    setDeleteCommitteeTaskStatus,
    setLoadAllNotariesTaskStatus,
    setGetAllPaginatedNotariesTaskStatus,
    setAllPaginatedNotaries
} = settingsSlice.actions;

export default settingsSlice.reducer;