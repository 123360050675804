import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AsyncTaskStatus} from "../../shared/dtos";
import {FolderFilesAndFoldersDTO, PaginatedEntityList, TaskDTO} from "../../api/dtos";

export interface TaskState {
    allPaginatedTasks: PaginatedEntityList<TaskDTO> | null;
    getAllPaginatedTasksStatus: AsyncTaskStatus | null;
    createTaskTaskStatus: AsyncTaskStatus | null;
    updateTaskStatusTaskStatus: AsyncTaskStatus | null;
    deleteTaskTaskStatus: AsyncTaskStatus | null;
    editTaskTaskStatus: AsyncTaskStatus | null;
    editTaskAssigneesTaskStatus: AsyncTaskStatus | null;
    addFileToTaskStatus: AsyncTaskStatus | null;
    deleteFileFromTaskStatus: AsyncTaskStatus | null;
    moveToFolderTaskStatus: AsyncTaskStatus | null;
    createFolderTaskStatus: AsyncTaskStatus | null;
    deleteFolderTaskStatus: AsyncTaskStatus | null;
    loadFolderFilesAndFoldersTaskStatus: AsyncTaskStatus | null;
    folderFilesAndFolders: FolderFilesAndFoldersDTO | null;
    task: TaskDTO | null;
}

const initialState: TaskState = {
    allPaginatedTasks: null,
    getAllPaginatedTasksStatus: null,
    createTaskTaskStatus: null,
    updateTaskStatusTaskStatus: null,
    deleteTaskTaskStatus: null,
    editTaskTaskStatus: null,
    editTaskAssigneesTaskStatus: null,
    addFileToTaskStatus: null,
    deleteFileFromTaskStatus: null,
    moveToFolderTaskStatus: null,
    createFolderTaskStatus: null,
    deleteFolderTaskStatus: null,
    loadFolderFilesAndFoldersTaskStatus: null,
    folderFilesAndFolders: null,
    task: null
};

const taskSlice = createSlice({
    name: 'task',
    initialState,
    reducers: {
        setUpdateTaskStatusTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.updateTaskStatusTaskStatus = action.payload;
        },
        setCreateTaskTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.createTaskTaskStatus = action.payload;
        },
        setGetAllPaginatedTasksTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.getAllPaginatedTasksStatus = action.payload;
        },
        setAllPaginatedTasks: (state, action: PayloadAction<PaginatedEntityList<TaskDTO> | null>) => {
            state.allPaginatedTasks = action.payload;
        },
        setDeleteTaskTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.deleteTaskTaskStatus = action.payload;
        },
        setEditTaskTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.editTaskTaskStatus = action.payload;
        },
        setEditTaskAssigneesTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.editTaskAssigneesTaskStatus = action.payload;
        },
        setAddFileToTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.addFileToTaskStatus = action.payload;
        },
        setDeleteFileFromTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.deleteFileFromTaskStatus = action.payload;
        },
        setMoveToFolderTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.moveToFolderTaskStatus = action.payload;
        },
        setCreateFolderTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.createFolderTaskStatus = action.payload;
        },
        setDeleteFolderTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.deleteFolderTaskStatus = action.payload;
        },
        setLoadFolderFilesAndFoldersTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadFolderFilesAndFoldersTaskStatus = action.payload;
        },
        setFolderFilesAndFolders: (state, action: PayloadAction<FolderFilesAndFoldersDTO | null>) => {
            state.folderFilesAndFolders = action.payload;
        },
        setTask: (state, action: PayloadAction<TaskDTO | null>) => {
            state.task = action.payload;
        },
        setLoadTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadFolderFilesAndFoldersTaskStatus = action.payload;
        },
    }
});

export const {
    setCreateTaskTaskStatus,
    setUpdateTaskStatusTaskStatus,
    setGetAllPaginatedTasksTaskStatus,
    setAllPaginatedTasks,
    setDeleteTaskTaskStatus,
    setEditTaskTaskStatus,
    setEditTaskAssigneesTaskStatus,
    setAddFileToTaskStatus,
    setDeleteFileFromTaskStatus,
    setMoveToFolderTaskStatus,
    setCreateFolderTaskStatus,
    setDeleteFolderTaskStatus,
    setLoadFolderFilesAndFoldersTaskStatus,
    setFolderFilesAndFolders,
    setTask,
    setLoadTaskStatus,
} = taskSlice.actions;

export default taskSlice.reducer;
